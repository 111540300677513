import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography, Tooltip } from '@material-ui/core'
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone'
import {
  cpfMask,
  cnpjMask,
  phoneMask,
  cepMask,
  dataMask
} from '../../../../../../components/Utils/masks'
import { DialogQRCodeProfile } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    cursor: 'pointer',
    width: 70,
    height: 70,
    marginBottom: theme.spacing(2)
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = (props) => {
  const { className, ...rest } = props
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <DialogQRCodeProfile
        membroPlanoId={'qrcode_profile'}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      {props.user.imagem !== '' &&
      props.user.imagem !== undefined &&
      props.user.imagem !== null ? (
        <Avatar
          alt="avatar_image"
          src={props.user.imagem}
          className={classes.avatar}
          onClick={() => setOpenDialog(true)}
        />
      ) : props.user.genero === 'M' ? (
        <Avatar
          alt="avatar_image"
          src={require('../../../../../../img/men-avatar.svg')}
          className={classes.avatar}
          component={RouterLink}
          to="/meus-dados"
        />
      ) : props.user.genero === 'F' ? (
        <Avatar
          alt="avatar_image"
          src={require('../../../../../../img/women-avatar.svg')}
          className={classes.avatar}
          component={RouterLink}
          to="/meus-dados"
        />
      ) : (
        <Tooltip arrow title="Sexo não foi informado. Complete seu cadastro!">
          <Avatar
            alt="avatar_image"
            src={require('../../../../../../img/undefined-avatar.png')}
            className={classes.avatar}
            component={RouterLink}
            to="/meus-dados"
          />
        </Tooltip>
      )}
      <Typography
        className={classes.nome}
        variant="h5"
        color="textPrimary"
        align="center"
      >
        <strong>{props.user.nome}</strong>
      </Typography>
      <Typography color="textPrimary" variant="body1">
        {props.user.email}
      </Typography>
      <Typography color="textPrimary" variant="caption">
        @{props.user.username}
      </Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
