import { crudService } from '../_services/'
import { history } from '../_helpers'

export const estabelecimentoAction = {
  getEstabelecimentoById,
  getEventosByEstabelecimento,
  getEventosByEstabelecimentoBySlug
}

function getEstabelecimentoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'estabelecimentos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editEstabelecimentosDetails(response.data))
    })
  }
}

function getEventosByEstabelecimento(estabelecimentoId) {
  return (dispatch) => {
    dispatch(request(estabelecimentoId))
    let apiEndpoint = 'eventos/estabelecimento/' + estabelecimentoId
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(
          success(
            response.data.estabelecimento,
            response.data.eventos,
            response.data.eventosPassados
          )
        )
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(estabelecimentoId) {
    return { type: 'EVENTOS_BY_ESTABELECIMENTO_REQUEST', estabelecimentoId }
  }

  function success(estabelecimento, eventos, eventosPassados) {
    return {
      type: 'EVENTOS_BY_ESTABELECIMENTO_SUCCESS',
      eventos: eventos,
      eventosPassados: eventosPassados,
      estabelecimento: estabelecimento
    }
  }

  function failure(error) {
    return { type: 'EVENTOS_BY_ESTABELECIMENTO_FAILURE', error }
  }
}

function getEventosByEstabelecimentoBySlug(slug) {
  return (dispatch) => {
    let apiEndpoint = 'eventos/estabelecimento/slug/' + slug
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(request(slug))
        dispatch(
          success(
            response.data.estabelecimento,
            response.data.eventos,
            response.data.eventosPassados
          )
        )
      })
      .catch((err) => {
        console.log('estabelecimentoResponseErr', err.response)
        if (err.response && err.response.status == 404) {
          history.push('/not-found')
        } else {
          dispatch(failure(err))
        }
      })
  }

  function request(slug) {
    return { type: 'EVENTOS_BY_ESTABELECIMENTO_REQUEST', slug }
  }

  function success(estabelecimento, eventos, eventosPassados) {
    return {
      type: 'EVENTOS_BY_ESTABELECIMENTO_SUCCESS',
      eventos: eventos,
      eventosPassados: eventosPassados,
      estabelecimento: estabelecimento
    }
  }

  function failure(error) {
    return { type: 'EVENTOS_BY_ESTABELECIMENTO_FAILURE', error }
  }
}

export function editEstabelecimentosDetails(estabelecimento) {
  return {
    type: 'ESTABELECIMENTO_DETAIL',
    id: estabelecimento.id,
    nome: estabelecimento.nome,
    detalhes: estabelecimento.detalhes,
    cidadeId: estabelecimento.cidadeId,
    endereco: estabelecimento.endereco,
    latLon: estabelecimento.latLon,
    capacidade: estabelecimento.capacidade,
    imagem: estabelecimento.imagem,
    excluido: estabelecimento.excluido,
    cidade: estabelecimento.cidade,
    bairro: estabelecimento.bairro,
    cep: estabelecimento.cep,
    categoriaId: estabelecimento.categoriaId
  }
}
export function clearEstabelecimento() {
  return {
    type: 'ESTABELECIMENTO_CLEAR'
  }
}
