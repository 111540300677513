import React, { useEffect } from 'react'
import './App.css'
import { Router } from 'react-router-dom'
import Mensagens from './_components/mensagens.component'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import Routes from './Routes'
import { history } from './_helpers'
import { ConfirmProvider } from 'material-ui-confirm'
import { authActions } from './_actions'
import { useDispatch } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DialogDownloadApp, DialogLogin } from 'components'
import 'react-credit-cards/lib/styles.scss'
//importação para datas e time
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import WhatsAppWidget from 'react-whatsapp-chat-widget'
import 'react-whatsapp-chat-widget/index.css'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-173583129-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const App = () => {
  const dispatch = useDispatch()
  const queryClient = new QueryClient()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      dispatch(authActions.refresh())
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <WhatsAppWidget
              phoneNo="555195482574"
              position="right"
              widgetWidth="300px"
              widgetWidthMobile="260px"
              autoOpen={false}
              autoOpenTimer={5000}
              iconSize={55}
              iconColor="#075e54"
              iconBgColor="white"
              headerIcon={
                'https://corujas.s3.us-east-2.amazonaws.com/images/production/2271212483437_suporte.svg'
              }
              headerBgColor={theme.palette.primary.main}
              headerTitle="Suporte - Corujas"
              headerCaption="Atendimento Humanizado"
              bodyBgColor={theme.palette.background.default}
              chatPersonName="Suporte - Corujas"
              chatMessage={
                <>
                  Oii! 👋 <br />
                  <br /> Estamos aqui para te ajudar! Qual sua dúvida?
                </>
              }
              footerBgColor={theme.palette.background.paper}
              btnBgColor={theme.palette.primary.main}
              btnTxtColor={theme.palette.primary.contrastText}
              btnTxt="Chamar no WhatsApp"
            />
            <div className="App">
              <Mensagens />
              <DialogLogin />
              <DialogDownloadApp />
              <Router history={history}>
                <Routes />
              </Router>
            </div>
          </ConfirmProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
export default App
