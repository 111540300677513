import { CarrinhoItemDetails } from '../types/api'

export function filterItemsWithCheckIn(items: CarrinhoItemDetails[]) {
  return items.filter((item) => item?.carrinhoItem?.lote?.checkIn)
}

export function filterItemsWithoutCheckIn(items: CarrinhoItemDetails[]) {
  return items.filter((item) => !item?.carrinhoItem?.lote?.checkIn)
}

export function hasItemsWithCheckIn(cartItems: CarrinhoItemDetails[]) {
  return cartItems?.some((item) => item?.carrinhoItem?.lote?.checkIn)
}

export function extractCartItems(carrinhoItensDetails: CarrinhoItemDetails[]) {
  return (
    carrinhoItensDetails?.map(
      (carrinhoItemDetails) => carrinhoItemDetails?.carrinhoItem
    ) ?? []
  )
}

export function filterValidCartItems(
  cartItems: CarrinhoItemDetails[]
): CarrinhoItemDetails[] {
  return cartItems?.filter((item) => {
    const cartItem = item?.carrinhoItem
    return cartItem && cartItem?.id > 0 && cartItem?.quantidade > 0
  })
}
