import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'
import { Row, Item } from '@mui-treasury/components/flex'
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info'
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor'
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic'
import { history } from '../../_helpers'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import { getInitials } from '../../utils/'

const useStyles = makeStyles(() => ({
  row: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.12)',
    '&:hover': {
      transform: 'scale(1.04)',
      color: '#000',
      boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.12)'
    }
  },
  text: {
    textAlign: 'initial'
  }
}))

const textSize = (text, size) => {
  const MAX_LENGTH = size
  if (text.length <= MAX_LENGTH) {
    return text
  } else {
    return `${text.substring(0, MAX_LENGTH)}...`
  }
}

export const EstabelecimentoCard = (props) => {
  const classes = useStyles()
  const avatarStyles = useDynamicAvatarStyles({ radius: 12, size: 48 })
  return (
    <Row
      onClick={() => {
        // props.estabelecimento.username
        //   ? history.push(`/${props.estabelecimento.username}`)
        //   :
        history.push(`/estabelecimento-profile/${props.estabelecimento.id}`)
      }}
      className={classes.row}
      p={0.7}
      gap={2}
      bgcolor={'#ffffff'}
      borderRadius={16}
    >
      <Item>
        {props.estabelecimento.imagem !== undefined ||
        props.estabelecimento.imagem !== null ? (
          <Avatar
            classes={avatarStyles}
            src={props.estabelecimento.imagem}
            alt={getInitials(props.estabelecimento.nome)}
          >
            {getInitials(props.estabelecimento.nome)}
          </Avatar>
        ) : (
          <HomeTwoToneIcon color="inherit" fontSize="large" />
        )}
      </Item>
      <Info
        className={classes.text}
        position={'middle'}
        useStyles={useTutorInfoStyles}
      >
        <InfoTitle noWrap>{textSize(props.estabelecimento.nome, 23)}</InfoTitle>
        <InfoSubtitle>
          {props.estabelecimento.cidade
            ? `${props.estabelecimento.cidade.nome}${
                props.estabelecimento.cidade &&
                props.estabelecimento.cidade.estado
                  ? ` - ${props.estabelecimento.cidade.estado.uf}`
                  : ''
              }`
            : ''}
        </InfoSubtitle>
      </Info>
    </Row>
  )
}
