const possuiDesconto = (carrinhoItemId, carrinhoItensDesconto) => {
  if (carrinhoItensDesconto) {
    return carrinhoItensDesconto.some(
      (ingressoComDesconto) => carrinhoItemId === ingressoComDesconto.id
    )
  } else {
    return false
  }
}

export const aplicaDescontos = (carrinhoItem, cupomDesconto) => {
  const valor = carrinhoItem.quantidade * carrinhoItem.lote.valor
  if (valor !== 0 && cupomDesconto) {
    let newValue = 0
    if (cupomDesconto.percentualDesconto !== null) {
      newValue =
        (valor * (1 - cupomDesconto.percentualDesconto / 100) * 100) / 100
    } else if (cupomDesconto.valorDesconto !== null) {
      newValue = valor - cupomDesconto.valorDesconto * carrinhoItem.quantidade
    }
    return newValue
  } else {
    return valor
  }
}

export const repassarTaxa = (carrinhoItem) => {
  if (!!carrinhoItem && !!carrinhoItem.lote) {
    if (carrinhoItem.lote.repassarTaxa === true) {
      return true
    } else {
      return false
    }
  }
}

export const calculaTaxa = (
  carrinhoItemDetails,
  carrinhoItensDesconto,
  cupomDesconto
) => {
  const { carrinhoItem, taxa, parametroTaxaPadrao } = carrinhoItemDetails
  if (repassarTaxa(carrinhoItem)) {
    let valorTaxa = 0
    if (taxa && taxa.id > 0 && taxa.percentual) {
      if (
        possuiDesconto(carrinhoItem.id, carrinhoItensDesconto) &&
        cupomDesconto
      ) {
        valorTaxa =
          aplicaDescontos(carrinhoItem, cupomDesconto) * (taxa.percentual / 100)
      } else {
        valorTaxa =
          carrinhoItem.quantidade *
          carrinhoItem.lote.valor *
          (taxa.percentual / 100)
      }
    } else if (parametroTaxaPadrao && parametroTaxaPadrao.length > 0) {
      if (
        possuiDesconto(carrinhoItem.id, carrinhoItensDesconto) &&
        cupomDesconto
      ) {
        valorTaxa =
          aplicaDescontos(carrinhoItem, cupomDesconto) *
          (parametroTaxaPadrao[0].valor / 100)
      } else {
        valorTaxa =
          carrinhoItem.quantidade *
          carrinhoItem.lote.valor *
          (parametroTaxaPadrao[0].valor / 100)
      }
    }
    return valorTaxa
  } else {
    return 0
  }
}

export const calculaValorTotal = (carrinhoItensDetails) => {
  let valorTotal = 0
  {
    carrinhoItensDetails.length > 0 &&
      carrinhoItensDetails.map((carinhoItemDetails) => {
        const subTotalIngresso =
          !!carinhoItemDetails.carrinhoItem &&
          carinhoItemDetails.carrinhoItem.lote.valor *
            carinhoItemDetails.carrinhoItem.quantidade
        valorTotal += subTotalIngresso
      })
  }
  return valorTotal
}

export const calculaValorTotalTaxa = (
  carrinhoItensDetails,
  carrinhoItensDesconto,
  cupomDesconto
) => {
  let valorTotalTaxa = 0
  {
    carrinhoItensDetails.length > 0 &&
      carrinhoItensDetails.map((carinhoItemDetails) => {
        valorTotalTaxa += calculaTaxa(
          carinhoItemDetails,
          carrinhoItensDesconto,
          cupomDesconto
        )
      })
  }
  return valorTotalTaxa
}

export const calculaValorDesconto = (carrinhoItensWithDiscountCoupon) => {
  let valorDesconto = 0
  if (
    carrinhoItensWithDiscountCoupon.cupomDesconto &&
    carrinhoItensWithDiscountCoupon.carrinhoItens &&
    carrinhoItensWithDiscountCoupon.carrinhoItens.length > 0
  ) {
    if (carrinhoItensWithDiscountCoupon.cupomDesconto.percentualDesconto > 0) {
      carrinhoItensWithDiscountCoupon.carrinhoItens.map(
        (item) =>
          (valorDesconto = item.lote
            ? valorDesconto + item.lote.valor * item.quantidade
            : valorDesconto)
      )
      return (
        (valorDesconto *
          (carrinhoItensWithDiscountCoupon.cupomDesconto.percentualDesconto /
            100) *
          100) /
        100
      )
    } else if (
      carrinhoItensWithDiscountCoupon.cupomDesconto.valorDesconto > 0
    ) {
      carrinhoItensWithDiscountCoupon.carrinhoItens.map(
        (item) =>
          item.lote &&
          item.lote.valor > 0 &&
          (valorDesconto = valorDesconto + item.quantidade)
      )
      return (
        valorDesconto *
        carrinhoItensWithDiscountCoupon.cupomDesconto.valorDesconto
      )
    } else {
      return valorDesconto
    }
  } else {
    return valorDesconto
  }
}

export const calculaValorAPagar = (
  carrinhoItensDetails,
  carrinhoItensDesconto,
  cupomDesconto
) => {
  let valorAPagar = 0
  valorAPagar =
    calculaValorTotal(carrinhoItensDetails) -
    calculaValorDesconto({
      carrinhoItens: carrinhoItensDesconto,
      cupomDesconto: cupomDesconto
    }) +
    calculaValorTotalTaxa(
      carrinhoItensDetails,
      carrinhoItensDesconto,
      cupomDesconto
    )
  return valorAPagar
}
