import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

import { Search } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
  filterButton: {
    marginLeft: 'auto'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  }
}))

const SearchBar = (props) => {
  const {
    isSearching,
    onSearch,
    inputValue,
    onKeyPress,
    className,
    onClear,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item xs={12}>
        <Search
          className={classes.search}
          onKeyPress={onKeyPress}
          inputValue={inputValue}
          onSearch={onSearch}
          isSearching={isSearching}
          onClear={onClear}
        />
      </Grid>
    </Grid>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func,
  inputValue: PropTypes.string,
  onKeyPress: PropTypes.func,
  onClear: PropTypes.func,
  isSearching: PropTypes.bool
}

export default SearchBar
