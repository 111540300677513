import React from 'react'
import {
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  Box,
  Theme,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Fade
} from '@material-ui/core'
import FileCopy from '@material-ui/icons/FileCopy'
import { toast } from 'react-toastify'
import QRCode from 'qrcode.react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

//? Own imports
import { Pagamento } from 'types/api'

const useStyles = makeStyles((theme) => ({
  containerQRCode: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  logoPix: {
    marginBottom: theme.spacing(2)
  }
}))

type PixProps = {
  payment: Pagamento
}
const Pix = ({ payment }: PixProps) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const getPixCode = () => {
    if (payment && payment?.pixBase64) {
      const base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
      if (base64regex.test(payment?.pixBase64)) {
        return Buffer.from(payment?.pixBase64, 'base64').toString('utf-8')
      } else {
        return payment?.pixBase64
      }
    } else {
      return ''
    }
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(getPixCode())
    toast.success('Feito! O código do PIX foi copiado.')
  }

  return (
    <div>
      <Typography variant={'h5'} gutterBottom>
        <strong>Guia de pagamento via PIX</strong>
      </Typography>
      <Typography gutterBottom={isMobile} variant={'body2'}>
        Pagar com PIX é prático e rápido. O pagamento é instantâneo e confirmado
        em poucos segundos. Funciona assim:
      </Typography>
      <>
        {isMobile ? (
          <>
            <Typography color="textSecondary" variant="body2" align="left">
              <strong> 1 -</strong> Copie o código de pagamento no botão abaixo:
            </Typography>
            {payment && payment?.pixBase64 && (
              <Box marginY={2}>
                <TextField
                  variant="outlined"
                  size="small"
                  contentEditable={false}
                  value={getPixCode()}
                  color="primary"
                  fullWidth
                  style={{ marginBottom: 5 }}
                  InputProps={{
                    readOnly: true,
                    style: {
                      backgroundColor: '#f5f5f5'
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleCopyToClipboard}
                          size="small"
                          edge="end"
                        >
                          <FileCopy fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  align="center"
                  gutterBottom
                >
                  Código valido até{' '}
                  {format(
                    new Date(payment.dataHoraValidade),
                    `dd'/'MM'/'yyyy 'às' kk':'mm`,
                    {
                      locale: brLocale
                    }
                  )}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCopyToClipboard}
                  size="small"
                  fullWidth
                >
                  Copiar código
                </Button>
              </Box>
            )}
            <Typography
              color="textSecondary"
              variant="body2"
              gutterBottom
              align="left"
            >
              <strong>2 -</strong> Abra o app ou banco de sua preferência.
              Escolha a opção pagar com código PIX
              <strong> copia e cola</strong>
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              gutterBottom
              align="left"
            >
              <strong>3 -</strong> Insira o código com a função colar, confira
              todas as informações e autorize o pagamento
            </Typography>
          </>
        ) : (
          <Grid
            className={classes.containerQRCode}
            container
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item xs={12}>
                  <Fade in={true}>
                    <QRCode
                      value={getPixCode()}
                      //@ts-ignore
                      size={'75%'}
                      bgColor={'#ffffff'}
                      fgColor={'#000000'}
                      level={'H'}
                      includeMargin={false}
                      renderAs={'svg'}
                    />
                  </Fade>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontSize: '10px' }}
                    variant="caption"
                    color="textPrimary"
                  >
                    O código é valido até{' '}
                    {format(
                      new Date(payment.dataHoraValidade),
                      `dd'/'MM'/'yyyy 'às' kk':'mm`,
                      {
                        locale: brLocale
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                align="left"
                color="textSecondary"
                variant="body2"
                gutterBottom
              >
                <strong> 1 -</strong> Abra o app ou banco de sua preferência e
                entre no ambiente PIX
              </Typography>
              <Typography
                align="left"
                color="textSecondary"
                variant="body2"
                gutterBottom
              >
                <strong> 2 -</strong> Escolha a opção
                <strong> pagar com qr code</strong> e escaneie o código ao lado
              </Typography>
              <Typography
                align="left"
                color="textSecondary"
                variant="body2"
                gutterBottom
              >
                <strong> 3 -</strong> Confira todas as informações e autorize o
                pagamento
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom
                align="left"
              >
                <strong> 4 -</strong> Logo em seguida, você vai receber a
                confirmação do pagamento no seu e-mail e através de nossos
                canais.
              </Typography>
              <Typography gutterBottom align="left">
                E pronto! 😎
              </Typography>
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <>
            <Typography
              color="textSecondary"
              variant="body2"
              gutterBottom
              align="left"
            >
              <strong> 4 -</strong> Logo em seguida, você vai receber a
              confirmação do pagamento no seu e-mail e através de nossos canais.
            </Typography>
            <Typography gutterBottom align="left">
              E pronto! 😎
            </Typography>
          </>
        )}
        {/* <Typography variant="body2" align="left">
          Os dados do recebedor são:
        </Typography>
        <Typography variant="body2" align="left">
          Q5S TECNOLOGIA LTDA
        </Typography>
        <Typography variant="body2" align="left" gutterBottom>
          CNPJ: 29.228.835/0001-85
        </Typography>
        <Typography align="left" variant="caption" color="textPrimary">
          Ahh, se o pagamento não for realizado dentro do prazo, não se
          preocupe. O pedido será cancelado automaticamente.
        </Typography> */}
      </>
    </div>
  )
}

export default Pix
