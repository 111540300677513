import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.paper
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2)
  },
  info: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5)
  },
  avatar: {
    height: 80,
    width: 115,
    borderRadius: 8
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '24px'
  }
}))
