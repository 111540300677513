import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    borderRadius: 8,
    padding: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    transition: 'background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  boldText: {
    fontWeight: 'bold'
  },
  loteDescriptionContainer: {},
  formResponses: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1)
  },
  formResponsesItemText: {},
  ticketNumber: {
    fontFamily: 'Montserrat_500Medium',
    fontSize: 11
  }
}))
