import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services/'
import {
  EstablishmentAssociationPlansListParams,
  EstablishmentAssociationPlansListReturn,
  UseQueryOptions
} from './member'

const getEstablishmentAssociationPlansList = async (
  params?: EstablishmentAssociationPlansListParams
): Promise<EstablishmentAssociationPlansListReturn> => {
  try {
    const { data } = await crudService.get(
      `planos/estabelecimento/${params?.establishmentId}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useEstablishmentAssociationPlansList(
  params?: EstablishmentAssociationPlansListParams,
  options?: UseQueryOptions<EstablishmentAssociationPlansListReturn>
) {
  return useQuery<EstablishmentAssociationPlansListReturn>(
    ['establishmentAssociationPlansList', params],
    () => getEstablishmentAssociationPlansList(params),
    {
      enabled: !!params?.establishmentId,
      staleTime: 600000, // 10 minutes
      ...options?.config
    }
  )
}
