import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PlaceTwoToneIcon from '@material-ui/icons/PlaceTwoTone'
import { Row, Item } from '@mui-treasury/components/flex'
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info'
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor'
import { history } from '../../_helpers'

const useStyles = makeStyles(() => ({
  row: {
    backgroundColor: '#fff',
    cursor: 'pointer',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.12)',
    '&:hover': {
      transform: 'scale(1.04)',
      color: '#000',
      boxShadow: '0px 0px 20px 8px rgba(0,0,0,0.12)'
    }
  },
  text: {
    textAlign: 'initial'
  }
}))

export const CidadeCard = (props) => {
  const classes = useStyles()
  return (
    <Row
      onClick={() => history.push(`/cidade/${props.estabelecimento.id}`)}
      className={classes.row}
      p={0.5}
      gap={2}
      bgcolor={'#ffffff'}
      borderRadius={16}
    >
      <Item>
        <PlaceTwoToneIcon color="inherit" fontSize="large"></PlaceTwoToneIcon>
      </Item>
      <Info
        className={classes.text}
        position={'middle'}
        useStyles={useTutorInfoStyles}
      >
        <InfoTitle>{props.cidade.nome}</InfoTitle>
        <InfoSubtitle>RS</InfoSubtitle>
      </Info>
    </Row>
  )
}
