import React from 'react'
import DescriptionTitle from './descriptionTitle'
import EventoCard from './eventoCard'
import { Grid, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles.css'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3.5,
  slidesToScroll: 3,
  lazyLoad: 'ondemand',
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1330,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1215,
      settings: {
        slidesToShow: 2.4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
        slidesToShow: 2.2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 570,
      settings: {
        arrows: false,
        slidesToShow: 1.08,
        slidesToScroll: 1,
        speed: 100
      }
    },
    {
      breakpoint: 375,
      settings: {
        arrows: false,
        slidesToShow: 1.08,
        slidesToScroll: 1,
        speed: 100
      }
    }
  ],
  nextArrow: (
    <ArrowForwardIosRoundedIcon fontSize="large" aria-label="proximo evento" />
  ),
  prevArrow: (
    <ArrowBackIosRoundedIcon fontSize="large" aria-label="evento anterior" />
  )
}
const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(15),
      paddingRight: theme.spacing(15)
    },
    marginBottom: theme.spacing(7)
  },
  noResultText: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  grid: {
    paddingBottom: theme.spacing(5),
    marginLeft: theme.spacing(-3)
  }
})

export default function EventosList(props) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <div className={!props.grid && classes.root}>
      {props.title !== undefined && (
        <DescriptionTitle>{props.title}</DescriptionTitle>
      )}
      {props.grid ? (
        <Grid className={classes.grid} container spacing={4}>
          {!isEmpty(props.eventos) &&
            props.eventos.map((evento) => {
              return (
                <Grid item key={evento.id} xs={12} sm={6} md={4}>
                  <EventoCard evento={evento} />
                </Grid>
              )
            })}
        </Grid>
      ) : (
        <Slider {...settings}>
          {!isEmpty(props.eventos) &&
            props.eventos.map((evento) => {
              return <EventoCard key={evento.id} evento={evento} />
            })}
        </Slider>
      )}
    </div>
  )
}
EventosList.propTypes = {
  eventos: PropTypes.array.isRequired,
  title: PropTypes.string
}
