import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Paper,
  InputBase,
  Divider,
  Tooltip,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    width: 350,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    flexGrow: 1
  },
  iconButton: {
    marginRight: theme.spacing(-1),
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const Search = (props) => {
  const {
    onSearch,
    isSearching,
    inputValue,
    onKeyPress,
    className,
    onClear,
    ...rest
  } = props

  const onKeyPressEnter = (event) => {
    if (event.which == 13) {
      event.preventDefault()
      if (inputValue.length !== 0) {
        onSearch()
      }
    }
  }
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper className={classes.search} elevation={1}>
        <InputBase
          value={inputValue}
          className={classes.searchInput}
          onChange={onKeyPress}
          onKeyPress={onKeyPressEnter}
          placeholder="Buscar pelo nome, nº de ingresso"
          inputProps={{ 'aria-label': 'Buscar pelo nome, nº de ingresso' }}
        />
        {isSearching ? (
          <CircularProgress color="primary" size={24} />
        ) : (
          inputValue.length >= 1 && (
            <IconButton onClick={onClear} color="primary" size="small">
              <CloseIcon opacity="0.8" />
            </IconButton>
          )
        )}
        <Divider className={classes.divider} orientation="vertical" />
        <Tooltip placement="left" title="Buscar">
          <IconButton
            color="primary"
            onClick={onSearch}
            className={classes.iconButton}
            aria-label="menu"
            size="small"
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
      </Paper>
    </div>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  inputValue: PropTypes.string,
  onSearch: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClear: PropTypes.func,
  isSearching: PropTypes.bool
}

export default Search
