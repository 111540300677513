function capitalizeFirstLetter(inputString: string): string {
  try {
    if (typeof inputString !== 'string') {
      throw new Error('Input deve ser uma string.')
    }

    if (inputString.length === 0) {
      throw new Error('String vazia, não há nada para capitalizar.')
    }

    const firstLetter = inputString.charAt(0).toUpperCase()
    const restOfString = inputString.slice(1)

    return firstLetter + restOfString
  } catch (error) {
    return inputString // Retorna a string original em caso de erro
  }
}
export default capitalizeFirstLetter
