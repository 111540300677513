import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { colors, useTheme, useMediaQuery, Divider } from '@material-ui/core'

import { Page, SearchBar } from 'components'
import { Header, MyOrdersDetails } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: colors.grey[300]
  },
  content: {
    textAlign: 'initial',
    marginTop: theme.spacing(2)
  }
}))

const CustomerManagementDetails = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Page className={classes.root} title="Pedidos">
      <Header />
      {/* <SearchBar></SearchBar> */}
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <MyOrdersDetails />
      </div>
    </Page>
  )
}

export default CustomerManagementDetails
