import { crudService } from '../_services/'
import { history } from '../_helpers/'

export const searchActions = {
  search,
  searchEventos,
  onChangeProps,
  onChangeCategoria,
  clearText,
  clearAllParams,
  searchHome,
  clearAllCategorias
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}
function onChangeCategoria(categories, text) {
  let payload = {
    Texto: text,
    Categorias: categories
  }
  return (dispatch) => {
    dispatch(handleOnChangeCategoria(categories))
    if (categories.length === 0 && text === '') {
      dispatch(clearAllParams())
    } else {
      dispatch(searchEventos(payload))
    }
  }
}

function clearText(props) {
  return (dispatch) => {
    dispatch(clearProp(props))
  }
}
function clearAllCategorias(text) {
  let payload = {
    Texto: text,
    Categorias: []
  }
  return (dispatch) => {
    dispatch(clearCategorias())
    if (text === '') {
      dispatch(clearAllParams())
    } else {
      dispatch(searchEventos(payload))
    }
  }
}

function search(params) {
  return (dispatch) => {
    dispatch(request(params))
    let apiEndpoint = 'search'
    crudService
      .post(apiEndpoint, params)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(params) {
    return { type: 'SEARCH_TOPBAR_REQUEST', params }
  }

  function success(result) {
    return {
      type: 'SEARCH_TOPBAR_SUCCESS',
      result: result
    }
  }

  function failure(error) {
    return { type: 'SEARCH_TOPBAR_FAILURE', error }
  }
}

function searchEventos(params) {
  return (dispatch) => {
    dispatch(request(params))
    let apiEndpoint = 'search'
    crudService
      .post(apiEndpoint, params)
      .then((response) => {
        dispatch(success(response.data))
        history.push('/search')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(params) {
    return { type: 'SEARCH_REQUEST', params }
  }

  function success(result) {
    return {
      type: 'SEARCH_SUCCESS',
      result: result
    }
  }

  function failure(error) {
    return { type: 'SEARCH_FAILURE', error }
  }
}

function searchHome(params) {
  return (dispatch) => {
    dispatch(request(params))
    let apiEndpoint = 'search/home'
    crudService
      .post(apiEndpoint, params)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(params) {
    return { type: 'SEARCH_HOME_REQUEST', params }
  }

  function success(result) {
    return {
      type: 'SEARCH_HOME_SUCCESS',
      result: result
    }
  }

  function failure(error) {
    return { type: 'SEARCH_HOME_FAILURE', error }
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'SEARCH_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function handleOnChangeCategoria(value) {
  return {
    type: 'SEARCH_HANDLE_ON_CHANGE_CATEGORIA',
    value: value
  }
}
export function clearProp(props) {
  return {
    type: 'SEARCH_CLEAR_PROP',
    props: props
  }
}

export function clearCategorias() {
  return {
    type: 'SEARCH_CLEAR_CATEGORIAS'
  }
}

export function clearAllParams() {
  return {
    type: 'SEARCH_CLEAR_ALL_PARAMS'
  }
}
