import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Chip,
  withStyles,
  CardContent,
  CardMedia,
  Card,
  CardActionArea,
  Typography,
  Zoom,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  useTheme
} from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn'
import EventIcon from '@material-ui/icons/Event'
import HomeIcon from '@material-ui/icons/Home'
import Moment from 'react-moment'
import 'moment-timezone'
import 'moment/locale/pt-br'
import { history } from '../../_helpers'
import { Row, Item } from '@mui-treasury/components/flex'
import { Share } from 'components'
import { isPast } from 'date-fns'
import EventBusyRoundedIcon from '@material-ui/icons/EventBusyRounded'

const styles = (theme) => ({
  card: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5)
    },
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    border: '2px solid transparent',
    '&:hover': {
      boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.18)',
      border: `2px solid ${theme.palette.primary.main}`
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    marginBottom: -35
  },
  cardColumn: {
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    border: '2px solid transparent',
    '&:hover': {
      boxShadow: '0px 0px 10px 3px rgba(0,0,0,0.18)',
      border: `2px solid ${theme.palette.primary.main}`
    },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    marginBottom: -35
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardMediaPastEvent: {
    paddingTop: '56.25%', // 16:9,
    opacity: 0.5,
    filter: 'opacity(50%)'
  },
  shared: {
    position: 'absolute',
    marginTop: '-65px',
    marginLeft: 10
  },
  textNome: {
    marginTop: '-8px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80vw'
    }
  },
  iconWithText: {
    marginLeft: '-15px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '60vw'
    }
  },
  footer: {
    position: 'absolute',
    height: 30,
    bottom: 146,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    padding: 6,
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iconIsPastEvent: {
    color: theme.palette.white,
    marginRight: theme.spacing(1)
  }
})

const EventoCard = (props) => {
  const { classes } = props
  const evento = props.evento
  const isPastEvent = isPast(new Date(new Date(evento.dataHoraFim)))
  return (
    <Zoom in={true}>
      <Card
        Paper
        elevation={3}
        className={
          props.direction === 'column' ? classes.cardColumn : classes.card
        }
      >
        <CardActionArea
          onClick={() => {
            evento.slug
              ? history.push(`/event-details/${evento.slug}`)
              : history.push(`/event-details/${evento.id}`)
          }}
        >
          <CardMedia
            className={
              isPastEvent ? classes.cardMediaPastEvent : classes.cardMedia
            }
            image={evento.imagem}
            title={evento.nome}
          />
          {isPastEvent && (
            <div className={classes.footer}>
              <EventBusyRoundedIcon
                fontSize="small"
                className={classes.iconIsPastEvent}
              />
              <Typography variant="h6" style={{ color: 'white' }}>
                Evento encerrado
              </Typography>
            </div>
          )}
          <CardContent>
            <Typography
              className={classes.textNome}
              noWrap
              gutterBottom
              variant="h5"
            >
              <strong>{evento.nome}</strong>
            </Typography>
            <Divider variant="middle" />
            <List dense="true">
              <Item>
                <LocationIcon
                  color="primary"
                  style={{ fontSize: '0.85rem', marginBottom: '-2px' }}
                />
                <Typography
                  style={{ fontSize: '0.85rem', paddingLeft: '5px' }}
                  noWrap
                  variant="caption"
                  color="primary"
                >
                  {evento?.tipoLocalizacao === 'O'
                    ? 'Evento online'
                    : evento.cidade
                    ? `${evento.cidade.nome} - ${evento.cidade.estado.uf}`
                    : 'Localização não informada'}
                </Typography>
              </Item>
              <ListItem>
                <ListItemIcon>
                  <HomeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  className={classes.iconWithText}
                  primary={
                    <Typography noWrap variant="h6" color="textPrimary">
                      {evento.nomeLocal ||
                        (evento.estabelecimento && evento.estabelecimento.nome)}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem style={{ marginTop: '-12px' }}>
                <ListItemIcon>
                  <EventIcon fontSize="small" />
                </ListItemIcon>
                {evento.dataHoraInicio !== null &&
                evento.dataHoraInicio !== undefined ? (
                  <ListItemText
                    className={classes.iconWithText}
                    primary={
                      <Typography noWrap variant="body1" color="textPrimary">
                        {
                          <Moment locale="pt-BR" format="L">
                            {evento.dataHoraInicio}
                          </Moment>
                        }{' '}
                        às{' '}
                        {
                          <Moment locale="pt-BR" format="LT">
                            {evento.dataHoraInicio}
                          </Moment>
                        }
                      </Typography>
                    }
                  />
                ) : (
                  <ListItemText
                    className={classes.iconWithText}
                    primary={
                      <Typography noWrap variant="body1" color="textPrimary">
                        Sem data definida
                      </Typography>
                    }
                  />
                )}
              </ListItem>
              {/* <Row >
                                    {(evento.dataHoraInicio !== null && evento.dataHoraInicio !== undefined) ?
                                        <Item position={'center'}>
                                            <Chip color="primary" size="small" label={<strong><Moment locale="pt-BR'" format="LLLL">{evento.dataHoraInicio}</Moment></strong>} />
                                        </Item>
                                        :
                                        <Item position={'center'}>
                                            <Chip color="primary" size="small" label={<strong> A data não foi definida</strong>} />
                                        </Item>
                                    }
                                </Row> */}
            </List>
          </CardContent>
        </CardActionArea>
        <Row mt={4} alignItems={'center'}>
          <Item className={classes.shared}>
            <Share evento={evento} />
          </Item>
        </Row>
      </Card>
    </Zoom>
  )
}
EventoCard.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(EventoCard)
