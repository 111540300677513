// purchaseApplicationFormUtils.ts

import { CarrinhoItemDetails, RespostaCampoInscricao } from 'types/api'

export type PurchaseApplicationFormItem = {
  id: string
  carrinhoItemDetails: CarrinhoItemDetails
  respostasCamposInscricao: RespostaCampoInscricao[] | undefined
  sequencial: number
}

export const findItemByIndex = (
  items: PurchaseApplicationFormItem[] | undefined,
  index: number
): PurchaseApplicationFormItem | undefined => {
  return items?.[index]
}

export const respostaCampoInscricaoExists = (
  campoInscricaoId: number,
  respostasCamposInscricao: RespostaCampoInscricao[] | undefined
): boolean => {
  return !!respostasCamposInscricao?.some(
    (resposta) =>
      resposta.campoInscricaoId === campoInscricaoId && resposta.texto
  )
}

export const hasAnyAnswerForItem = (
  item: PurchaseApplicationFormItem | undefined
): boolean => {
  return (
    item?.carrinhoItemDetails.camposInscricao.some((campo) =>
      respostaCampoInscricaoExists(campo.id, item.respostasCamposInscricao)
    ) || false
  )
}

export const hasAnyAnswerInItems = (
  items: PurchaseApplicationFormItem[] | undefined
): boolean => {
  return (
    items?.some((item) =>
      item.carrinhoItemDetails.camposInscricao.some((campo) =>
        respostaCampoInscricaoExists(campo.id, item.respostasCamposInscricao)
      )
    ) || false
  )
}

export const areAllRequiredItemsFilled = (
  items: PurchaseApplicationFormItem[] | undefined
): boolean => {
  return (
    items?.every((item) =>
      item.carrinhoItemDetails.camposInscricao.every(
        (campo) =>
          campo.obrigatorio !== true ||
          respostaCampoInscricaoExists(
            campo.id,
            item.respostasCamposInscricao || []
          )
      )
    ) || false
  )
}

export const isRequiredFieldsFilledForItem = (
  item: PurchaseApplicationFormItem | undefined
): boolean => {
  return (
    item?.carrinhoItemDetails.camposInscricao.every(
      (campo) =>
        campo.obrigatorio !== true ||
        respostaCampoInscricaoExists(
          campo.id,
          item.respostasCamposInscricao || []
        )
    ) || false
  )
}

export const hasIncompleteFields = (
  item: PurchaseApplicationFormItem | undefined
): boolean => {
  return (
    item?.carrinhoItemDetails.camposInscricao.some(
      (campo) =>
        campo.obrigatorio &&
        !respostaCampoInscricaoExists(
          campo.id,
          item.respostasCamposInscricao || []
        )
    ) || false
  )
}

export const getFirstIncompleteItem = (
  items: PurchaseApplicationFormItem[] | undefined
): PurchaseApplicationFormItem | undefined => {
  return items?.find((item) => hasIncompleteFields(item))
}

export const extractRespostasCamposInscricao = (
  purchaseApplicationFormItens: PurchaseApplicationFormItem[] | undefined
): RespostaCampoInscricao[] | null => {
  if (!purchaseApplicationFormItens) {
    return null
  }

  let allRespostas: RespostaCampoInscricao[] = []

  purchaseApplicationFormItens.forEach((item) => {
    // Extract the sequencial value from the current item
    const campoSequencial = item?.sequencial || 0
    // Extract existing responses or initialize an empty array
    const respostas = item?.respostasCamposInscricao || []

    // Map over existing responses and add campoSequencial to each object
    const respostasComCampoSequencial = respostas.map((resposta) => ({
      ...resposta,
      sequencial: campoSequencial
    }))

    // Concatenate the modified responses to the overall array
    allRespostas = [...allRespostas, ...respostasComCampoSequencial]
  })

  // Return the resulting array, or null if it's empty
  return allRespostas.length > 0 ? allRespostas : null
}

export const getCampoInscricaoAnswer = (
  campoId: number,
  respostasCamposInscricao: RespostaCampoInscricao[] | undefined
): string | null => {
  const resposta = respostasCamposInscricao?.find(
    (resposta) => resposta.campoInscricaoId === campoId
  )

  return resposta?.texto || null
}
