import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'

//? Own imports
import { useStyles } from './styles'
import { Estabelecimento } from 'types/api'
import { history } from '_helpers'

type CardEstablishmentDetailsProps = {
  establishmentDetails: Estabelecimento
}

const CardEstablishmentDetails = ({
  establishmentDetails
}: CardEstablishmentDetailsProps) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title={
          <Typography className={classes.textHeader} variant="h5" align="left">
            Sobre o organizador
          </Typography>
        }
      />
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: 'fit-content'
        }}
      >
        {establishmentDetails?.imagem &&
          establishmentDetails?.imagem !== '' && (
            <Avatar
              className={classes.avatar}
              alt={establishmentDetails?.nome}
              src={establishmentDetails?.imagem}
            />
          )}
        <Typography variant="subtitle1">
          {establishmentDetails?.nome}
        </Typography>
        {establishmentDetails?.cidade && (
          <div className={classes.iconWithText}>
            <PlaceOutlinedIcon style={{ fontSize: 16, marginRight: '7px' }} />
            <Typography color="textPrimary" variant="caption">
              {`${establishmentDetails?.cidade.nome}, ${establishmentDetails?.cidade.estado.uf}`}
            </Typography>
          </div>
        )}
        {establishmentDetails?.detalhes && (
          <Typography align="justify" gutterBottom variant="body1">
            {establishmentDetails?.detalhes}
          </Typography>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            // establishmentDetails?.username
            //   ? history.push(`/${establishmentDetails?.username}`)
            // :
            history.push(`/estabelecimento-profile/${establishmentDetails?.id}`)
          }}
          color="primary"
          className={classes.button}
        >
          Ver mais eventos
        </Button>
      </CardContent>
    </Card>
  )
}

export default CardEstablishmentDetails
