import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Divider, Drawer, withStyles } from '@material-ui/core'
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone'
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone'
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone'
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone'
import ConfirmationNumberTwoToneIcon from '@material-ui/icons/ConfirmationNumberTwoTone'
import ShopTwoToneIcon from '@material-ui/icons/ShopTwoTone'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import { authActions } from '../../../../_actions'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Profile, SidebarNav } from './components'

const styles = (theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
})

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props
  const dispatch = useDispatch()
  const { user } = props
  const logout = () => {
    dispatch(authActions.logout())
  }

  const { classes } = props

  const pages = [
    {
      title: 'Ingressos',
      href: '/approot/perfil/tickets',
      icon: <ConfirmationNumberTwoToneIcon />
    },
    {
      title: 'Pedidos',
      href: '/meus-pedidos',
      icon: <ShopTwoToneIcon />
    },
    {
      title: 'Área de Membros',
      href: '/member-list',
      icon: <PeopleOutlineTwoToneIcon />
    },
    {
      title: 'Meus Dados',
      href: '/meus-dados',
      icon: <AccountBoxTwoToneIcon />,
      children: [
        {
          title: 'General',
          href: '/meus-dados/geral'
        },
        {
          title: 'Security',
          href: '/meus-dados/seguranca'
        }
      ]
    },
    // {
    //   title: 'Coruja Coins',
    //   href: '/coruja-coins',
    //   icon: < MonetizationOnTwoToneIcon />
    // },
    // {
    //   title: 'Configurações',
    //   href: '/settings',
    //   icon: < SettingsTwoToneIcon />
    // },
    {
      title: 'Sair',
      href: '/login',
      icon: <ExitToAppTwoToneIcon />,
      onClick: () => {
        logout()
      }
    }
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        {undefined !== user ? <Profile user={user} /> : null}
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user
  }
}
const connectedSidebarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Sidebar))
)
export { connectedSidebarPage as Sidebar }
