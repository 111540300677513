import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { usuarioActions } from '_actions'
import UsuariosForm from './UsuarioForm'
import { toISODateFormat, useRouter } from 'utils'
import { parse } from 'date-fns'

const useStyles = makeStyles(() => ({
  root: {}
}))

const GeneralSettings = (props) => {
  const { className, ...rest } = props
  const dispatch = useDispatch()
  const classes = useStyles()
  const usuario = useSelector((state) => state.usuario)
  const { loading, isEditable } = useSelector((state) => state.usuario)
  const router = useRouter()
  const isUserNecessaryDataToPurchasePatch =
    router &&
    router.location &&
    router.location.pathname &&
    router.location.pathname.includes('/ingressos/checkout/')
  useEffect(() => {
    ValidatorForm.addValidationRule('isValidPhone', (value) => {
      if (value !== null && value) {
        if (value.length > 0 && value.length < 14) {
          return false
        }
        return true
      }
      return true
    })
  }, [])

  useEffect(() => {
    // antes de desmontar o componente
    return () => {
      ValidatorForm.removeValidationRule('isValidPhone')
    }
  }, [])

  const handleClickEdit = (event) => {
    event.preventDefault()
    dispatch(usuarioActions.changeIsEditable())
  }

  const handleClickCancel = (event) => {
    event.preventDefault()
    const usuario = JSON.parse(localStorage.getItem('user'))
    dispatch(usuarioActions.cancelEditUsuario(usuario))
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      id: usuario.id,
      aceiteEmails: usuario.aceiteEmails,
      aceiteTermosUso: usuario.aceiteTermosUso,
      telefoneVerificado: usuario.telefoneVerificado,
      emailVerificado: usuario.emailVerificado,
      excluido: usuario.excluido,
      bairro: usuario.bairro,
      cep: usuario.cep.replace(/-/g, ''),
      imagem: usuario.imagem,
      nome: usuario.nome,
      senha: usuario.senha,
      email: usuario.email,
      numero: usuario.numero,
      complemento: usuario.complemento,
      genero: usuario.genero,
      cidadeId: usuario.cidadeId,
      dataNascimento:
        usuario.dataNascimento !== null
          ? parse(
              usuario.dataNascimento,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
      apelido: usuario.apelido,
      username: usuario.username,
      endereco: usuario.endereco,
      cpfCnpj: usuario.cpfCnpj.split('.').join('').replace(/[-/.]/g, ''),
      telefone: usuario.telefone
        .split(' ')
        .join('')
        .replace(/[().-]/g, '')
    }
    dispatch(
      usuarioActions.editUsuarioInfo(
        usuario.id,
        payload,
        isUserNecessaryDataToPurchasePatch
      )
    )
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <ValidatorForm onSubmit={handleSubmit} debounceTime={400}>
        <CardHeader title="Meus dados" />
        <Divider />
        <CardContent>
          <UsuariosForm />
        </CardContent>
        <Divider />
        <CardActions>
          {isEditable ? (
            <>
              <Button
                disabled={loading}
                type="submit"
                color="primary"
                variant="outlined"
                endIcon={loading && <CircularProgress size={24} />}
              >
                {loading ? 'Enviando informações...' : 'Salvar Alterações'}
              </Button>
              <Button
                onClick={handleClickCancel}
                variant="outlined"
                color="secondary"
                disabled={loading}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              onClick={handleClickEdit}
              color="primary"
              variant="outlined"
              disabled={loading}
              endIcon={loading && <CircularProgress size={24} />}
            >
              {loading ? 'Enviando informações... ' : 'Editar Informações'}
            </Button>
          )}
        </CardActions>
      </ValidatorForm>
    </Card>
  )
}

GeneralSettings.propTypes = {
  className: PropTypes.string
}

export default GeneralSettings
