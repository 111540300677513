let token = localStorage.getItem('token')
let expires = localStorage.getItem('expires')

const initialState = token ? { loggedIn: true, token, expires, user: {} } : { openModalLogin: false, isRegister: false }
export function authentication(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggingIn: true,
        user: action.user
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.user,
        token: action.token,
        expires: action.expires,
        openModalLogin: false
      }
    case 'LOGIN_FAILURE':
      return { ...state, loggingIn: false, error: action.error }
    case 'AUTHENTICATION_REFRESH_REQUEST':
      return {
        ...state,
        loggingIn: true
      }
    case 'AUTHENTICATION_REFRESH_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        loggingIn: false,
        user: action.user.usuario
      }
    case 'AUTHENTICATION_REFRESH_FAILURE':
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
        expires: null
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        loggedIn: false,
        user: null,
        token: null,
        expires: null
      }
    case 'UPDATE_USER_DETAILS':
      return {
        ...state,
        user: action.user
      }
    case 'OPEN_MODAL_LOGIN':
      return {
        ...state,
        openModalLogin: !state.openModalLogin
      }
    case 'SET_IS_REGISTER':
      return {
        ...state,
        isRegister: !state.isRegister
      }
    default:
      return state
  }
}
