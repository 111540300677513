import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Grid, Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'moment-timezone'
import { history } from '../../_helpers'
import { isEmpty } from 'lodash'
import Skeleton from '@material-ui/lab/Skeleton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { EstabelecimentoCard } from './estabelecimentoCard'
import { CidadeCard } from './cidadeCard'
import EventosListColumn from 'components/EventosList/EventosListColumn'

const styles = (theme) => ({
  noResultText: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  backButton: {
    marginTop: theme.spacing(5)
  },
  resultText: {
    paddingBottom: theme.spacing(2)
  },
  bottomContent: {
    paddingBottom: theme.spacing(4)
  },
  slider: {}
})

const SearchResult = (props) => {
  const { classes } = props
  const eventos = props.result.evento
  const estabelecimentos = props.result.estabelecimento
  // const cidades = props.result.cidade
  return (
    <div>
      {props.searching ? (
        <Grid container spacing={4}>
          <Grid item key="0" xs={12} sm={6} md={4}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height={130}
            />
            <Skeleton animation="wave" width="100%" />
            <Skeleton animation="wave" width="100%" />
          </Grid>
          <Grid item key="0" xs={12} sm={6} md={4}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height={130}
            />
            <Skeleton animation="wave" width="100%" />
            <Skeleton animation="wave" width="100%" />
          </Grid>
          <Grid item key="0" xs={12} sm={6} md={4}>
            <Skeleton
              animation="wave"
              variant="rect"
              width="100%"
              height={130}
            />
            <Skeleton animation="wave" width="100%" />
            <Skeleton animation="wave" width="100%" />
          </Grid>
        </Grid>
      ) : isEmpty(eventos) && isEmpty(estabelecimentos) ? (
        // && isEmpty(cidades)
        <div>
          <Typography
            className={classes.noResultText}
            gutterBottom
            variant="h2"
            color="primary"
          >
            <strong>Opssss! 😥</strong>{' '}
          </Typography>
          <Typography gutterBottom variant="h4" color="textPrimary">
            Sua busca não gerou nenhum resultado...
          </Typography>
          <Button
            className={classes.backButton}
            onClick={() => history.push('/')}
            variant="outlined"
            color="primary"
            startIcon={<KeyboardBackspaceIcon></KeyboardBackspaceIcon>}
          >
            Voltar para home
          </Button>
        </div>
      ) : (
        <div>
          {/* {!isEmpty(cidades) && (
            <div>
              {cidades.length === 1 ? (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foi encontrado <strong>{cidades.length}</strong> local
                </Typography>
              ) : (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foram encontrados <strong>{cidades.length}</strong> locais
                </Typography>
              )}
              <Grid className={classes.bottomContent} container spacing={1}>
                {cidades.map((n) => {
                  return (
                    <Grid key={n.id} item xs={12} sm={6} md={4} lg={3}>
                      <CidadeCard cidade={n} />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          )} */}
          {!isEmpty(estabelecimentos) && (
            <div>
              {estabelecimentos.length === 1 ? (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foi encontrado <strong>{estabelecimentos.length}</strong>{' '}
                  estabelecimento
                </Typography>
              ) : (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foram encontrados <strong>{estabelecimentos.length}</strong>{' '}
                  estabelecimentos
                </Typography>
              )}
              <Grid className={classes.bottomContent} container spacing={1}>
                {estabelecimentos.map((n) => {
                  return (
                    <Grid key={n.id} item xs={12} sm={6} md={4} lg={3}>
                      <EstabelecimentoCard estabelecimento={n} />
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          )}
          {!isEmpty(eventos) && (
            <div className={classes.slider}>
              {eventos.length === 1 ? (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foi encontrado <strong>{eventos.length}</strong> evento
                </Typography>
              ) : (
                <Typography
                  className={classes.resultText}
                  gutterBottom
                  align="left"
                  variant="h4"
                  color="textPrimary"
                >
                  Foram encontrados <strong>{eventos.length}</strong> eventos
                </Typography>
              )}
              <EventosListColumn grid eventos={eventos} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
SearchResult.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    searching: state.search.searching,
    result: state.search.result
  }
}
const connectedSearchResultPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(SearchResult))
)
export { connectedSearchResultPage as SearchResult }
