import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  textHeader: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold'
  },
  buttonCheckout: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2)
  }
}))
