import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { CarrinhoItem } from 'types/api'

type Payload = {
  textoCupom: string | null
  carrinhoItens: CarrinhoItem[] | undefined
}
type CreditCardInstallmentsSimulationReturn = {
  parcelas: number
  valorParcela: number
  valorTotal: number
}

const getCreditCardInstallmentsSimulation = async (payload: Payload) => {
  const apiEndpoint = `pagarMeCheckout/parcelamento/simulacao`
  const { data } = await crudService.post(apiEndpoint, payload)
  return data
}

export default function useCreditCardInstallmentsSimulation(payload: Payload) {
  return useQuery<CreditCardInstallmentsSimulationReturn[]>(
    ['creditCardInstallmentsSimulation', payload],
    () => getCreditCardInstallmentsSimulation(payload),
    {
      enabled: !!payload.carrinhoItens && payload.carrinhoItens.length > 0,
      retry: 1
    }
  )
}
