import React from 'react'
import DescriptionTitle from './descriptionTitle'
import EventoCard from './eventoCard'
import { Grid, makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  noResultText: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  backPadding: {
    paddingBottom: theme.spacing(5)
  }
})

export default function EventosListColumn(props) {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  return (
    <div>
      {props.title !== undefined && (
        <DescriptionTitle>{props.title}</DescriptionTitle>
      )}
      <Grid className={classes.backPadding} container spacing={4}>
        {!isEmpty(props.eventos) &&
          props.eventos.map((evento) => {
            return (
              <Grid item key={evento.id} xs={12} sm={6} md={4}>
                <EventoCard
                  direction="column"
                  key={evento.id}
                  evento={evento}
                />{' '}
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}
EventosListColumn.propTypes = {
  eventos: PropTypes.array.isRequired,
  title: PropTypes.string
}
