import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Button,
  Divider,
  TextField,
  colors
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const Security = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const [values, setValues] = useState({
    password: '',
    confirm: ''
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    })
  }

  const valid = values.password && values.password === values.confirm

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Trocar senha" />
      <Divider />
      <CardContent>
        <form>
          <Grid container spacing={3}>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                fullWidth
                label="Senha"
                name="password"
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                fullWidth
                label="Confirmar senha"
                name="confirm"
                onChange={handleChange}
                type="password"
                value={values.confirm}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          disabled={!valid}
          variant="contained"
          color="primary"
          type="submit"
        >
          Salvar mudanças
        </Button>
      </CardActions>
    </Card>
  )
}

Security.propTypes = {
  className: PropTypes.string
}

export default Security
