import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import {
  Avatar,
  Box,
  Container,
  MobileStepper,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import QRCode from 'qrcode.react'

//? Own imports
import { useMemberList } from 'hooks-querys'
import { useSelector } from 'react-redux'
import { getImageUrls, getInitials } from 'utils'
import { useStyles } from './styles'
import { MembroPlano } from 'types/api'

type QRCodeItem = {
  id: string
  title: string
  textInfo: string
  value: string
  logo?: string
}

const populateItems = (
  memberList: MembroPlano[] | undefined,
  userId: number | undefined
): QRCodeItem[] => {
  const items: QRCodeItem[] = []
  if (memberList && memberList?.length > 0) {
    memberList?.forEach((member) => {
      const newItem: QRCodeItem = {
        id: member?.id?.toString(),
        title: `${member?.plano?.nome}`,
        textInfo: `Este QR Code é válido para acessar eventos relacionados a ${member?.plano?.estabelecimento?.nome}.`,
        value: member?.qrCode,
        logo:
          member?.plano?.imagem || member?.plano?.estabelecimento?.imagem
            ? getImageUrls(member?.plano?.imagem)?.thumbnailUrl ||
              getImageUrls(member?.plano?.estabelecimento?.imagem)?.thumbnailUrl
            : undefined
      }

      items.push(newItem)
    })
  }

  const lastItem: QRCodeItem = {
    id: 'qrcode_profile',
    title: `Compartilhar Perfil`,
    textInfo: `Utilize este QR Code para compartilhar o seu perfil no Corujas com outros usuários.`,
    value: `https://corujasapp.com/people-profile/${userId}`,
    logo: require('../../assets/images/500x500.png')
  }
  if (userId) {
    items?.push(lastItem)
  }

  return items
}

//@ts-ignore
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main
  },
  avatar: {
    height: 80,
    width: 80,
    cursor: 'pointer'
  }
})

//@ts-ignore
const DialogTitle = withStyles(styles)((props) => {
  //@ts-ignore
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          onClick={onClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2)
  }
}))(MuiDialogContent)

type DialogQRCodeProfileProps = {
  membroPlanoId: string
  openDialog: boolean
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>
}
export default function DialogQRCodeProfile({
  membroPlanoId,
  openDialog,
  setOpenDialog
}: DialogQRCodeProfileProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  //@ts-ignore
  const userData = useSelector((state) => state.authentication.user)
  const [activeStep, setActiveStep] = React.useState(0)
  const { data: memberList, isLoading } = useMemberList({
    userId: userData?.id
  })

  const qrCodeItems = populateItems(memberList, userData?.id)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleClose = () => {
    setOpenDialog(false)
  }

  const getCurrentMemberListItem = () => {
    if (qrCodeItems && qrCodeItems?.length > 0) {
      return qrCodeItems[activeStep]
    }
    return null
  }

  const currentMemberListItem = getCurrentMemberListItem()

  const getIndexById = (id: string): number => {
    if (qrCodeItems && qrCodeItems.length > 0) {
      const index = qrCodeItems.findIndex((item) => item?.id === id)
      return index !== -1 ? index : -1
    }
    return -1
  }
  useEffect(() => {
    if (membroPlanoId) {
      const itemIndex = getIndexById(membroPlanoId)
      if (itemIndex !== -1) {
        setActiveStep(itemIndex)
      }
    }
  }, [membroPlanoId, openDialog])

  if (!qrCodeItems) {
    return null
  }

  return (
    <Dialog onClose={handleClose} open={openDialog} fullScreen={isMobile}>
      <DialogTitle
        //@ts-ignore
        style={{ backgroundColor: theme.palette.background.default }}
        onClose={handleClose}
      />
      <DialogContent>
        <Container
          maxWidth="xs"
          style={{
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: theme.spacing(3),
            padding: theme?.spacing(2),
            marginTop: theme?.spacing(5),
            paddingTop: theme?.spacing(0),
            borderRadius: 8
          }}
        >
          <Avatar
            className={classes.avatar}
            src={getImageUrls(userData?.imagem)?.thumbnailUrl}
            alt={getInitials(userData?.nome)}
          >
            {getInitials(userData?.nome)}
          </Avatar>
          <Box>
            <Typography align="center" variant="subtitle1">
              {userData?.nome}
            </Typography>
            {userData?.username ? (
              <Typography align="center" variant="body2">
                @{userData?.username}
              </Typography>
            ) : null}
          </Box>
          {currentMemberListItem?.value && (
            <QRCode
              imageSettings={
                currentMemberListItem?.logo
                  ? {
                      src: currentMemberListItem?.logo,
                      width: 40,
                      height: 40,
                      excavate: true
                    }
                  : undefined
              }
              value={currentMemberListItem?.value || ''}
              size={260}
              bgColor={'#ffffff'}
              fgColor={'#000000'}
              level={'M'}
              renderAs={'svg'}
            />
          )}
          <Box>
            <Typography align="center" variant="subtitle1">
              {currentMemberListItem?.title?.toUpperCase()}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {currentMemberListItem?.textInfo}
            </Typography>
          </Box>
        </Container>
        <MobileStepper
          style={{ width: '100%' }}
          variant="dots"
          steps={qrCodeItems?.length || 0}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === (qrCodeItems?.length || 0) - 1}
            >
              Próximo
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Anterior
            </Button>
          }
        />
      </DialogContent>
    </Dialog>
  )
}
