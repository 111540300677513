import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

//? Own imports
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useEvento
} from 'hooks-querys'
import { DialogUserNecessaryDataToPurchase } from 'components'
import { useRouter, validateUserNecessaryData } from 'utils'
import { OrderSummary, PaymentMethods } from 'views/OrderPayment/components'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import {
  filterItemsWithCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4, 0, 10, 0) }
  },
  subtitle: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
  },
  paper: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2)
      // minHeight: '60vh'
    },
    [theme.breakpoints.down('sm')]: {}
  }
}))

const CheckInDetail = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { eventId } = useParams<{ eventId: string }>()

  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const { data: eventDetails, isLoading: isLoadingEvent } = useEvento(eventId)
  const { data: carrinhoItens, isLoading: isLoadingCarrinhoItens } =
    useCarrinhoItensEvento(Number(eventDetails?.id) || 0)
  const dispatch = useDispatch()
  //@ts-ignore
  const { user, loggingIn } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (!loggingIn && user && user.id) {
      validateUserNecessaryData(dispatch, user)
    }
  }, [user, loggingIn])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  const cartItemsWithCheckIn = filterItemsWithCheckIn(carrinhoItens || [])
  const filteredCartItems = filterValidCartItems(cartItemsWithCheckIn)

  const totalValue = calculaValorAPagar(
    filteredCartItems || [],
    (discountCoupon && discountCoupon.carrinhoItens) || [],
    discountCoupon && discountCoupon.cupomDesconto
  )

  return (
    <Container maxWidth="lg" className={classes.root}>
      <DialogUserNecessaryDataToPurchase />
      {isLoadingEvent || isLoadingCarrinhoItens ? (
        <Box marginY={4}>
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={isMobile ? 0 : 2}
          direction={isMobile ? 'column-reverse' : 'row'}
        >
          {filteredCartItems?.length > 0 ? (
            <Grid item sm={12} md={8}>
              <Typography
                align="left"
                gutterBottom
                variant="h5"
                className={classes.subtitle}
              >
                <b>{'Detalhes de pagamento'}</b>
              </Typography>
              <Paper className={classes.paper}>
                <PaymentMethods
                  evento={eventDetails}
                  isOrderGenerated={false}
                  totalValue={totalValue}
                  isCheckIn={true}
                />
              </Paper>
            </Grid>
          ) : null}
          <Grid item sm={12} md={4}>
            <Typography
              align="left"
              className={classes.subtitle}
              gutterBottom
              variant="h5"
            >
              <b>Resumo do check-in</b>
            </Typography>
            {eventDetails ? (
              <Paper className={classes.paper}>
                <OrderSummary
                  evento={eventDetails}
                  isCheckIn={true}
                  isOrderGenerated={false}
                />
              </Paper>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default CheckInDetail
