export const getGenderFull = (abbreviation?: 'M' | 'F' | 'U') => {
  switch (abbreviation) {
    case 'M':
      return 'Masculino'
    case 'F':
      return 'Feminino'
    case 'U':
      return 'Unissex'
    default:
      return 'Não informado'
  }
}
