import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/Place'
import PropTypes from 'prop-types'
import { cepMask } from 'utils'

const useStyles = makeStyles((theme) => ({
  button: { marginTop: theme.spacing(2) }
}))

export default function EventoLocationContent(props) {
  const classes = useStyles()
  const { evento } = props
  return (
    <div>
      {evento.tipoLocalizacao === 'O' ? (
        <Typography color="textPrimary" align="justify">
          Evento online
        </Typography>
      ) : (
        <>
          {evento.tipoLocalizacao !== 'A' && (
            <>
              <Typography color="textPrimary" align="justify">
                {evento.nomeLocal !== null ? evento.nomeLocal : ''}
              </Typography>
              <Typography variant="body1" color="textPrimary" align="justify">
                {`${evento.endereco}${
                  evento.numero !== null ? `, ${evento.numero}` : ''
                }${
                  evento.bairro !== '' &&
                  evento.complemento &&
                  evento.complemento !== null
                    ? ` - ${evento.complemento}`
                    : ''
                }`}
              </Typography>
              <Typography variant="body1" color="textPrimary" align="justify">
                {`${
                  evento.bairro && evento.bairro !== null ? evento.bairro : ''
                }${
                  evento.cep && evento.cep !== null
                    ? ` - ${cepMask(evento.cep)}`
                    : ''
                }`}
              </Typography>
            </>
          )}
          <Typography variant="body1" color="textPrimary" align="justify">
            {evento.tipoLocalizacao === 'A' && 'Local a definir'}
          </Typography>
          <Typography variant="body1" color="textPrimary" align="justify">
            {evento.cidade &&
            evento.cidade !== null &&
            evento.cidade !== undefined
              ? `${evento.cidade.nome} - ${evento.cidade.estado.uf}`
              : ''}
          </Typography>
          {!!evento.latLon &&
            evento.latLon !== undefined &&
            evento.latLon !== null && (
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  href={`https://maps.google.com/?q=${evento.latLon.x},${evento.latLon.y}`}
                  target="_blank"
                  className={classes.button}
                  startIcon={<PlaceIcon color="inherit" fontSize="inherit" />}
                >
                  Abrir localização no mapa
                </Button>
              </div>
            )}
        </>
      )}
    </div>
  )
}

EventoLocationContent.propTypes = {
  evento: PropTypes.object.isRequired
}
