import { useQuery } from 'react-query'
import { crudService } from '_services'
import { Cidade } from '../types/api'

const getCityByIbge = async (ibge: number | undefined) => {
  const { data } = await crudService.get(`cidades/ibge/${ibge}`)
  return data
}

export default function useCityByIbge(
  ibge: number | undefined,
  enabled: boolean
) {
  return useQuery<Cidade>(['cityByIbge', ibge], () => getCityByIbge(ibge), {
    enabled: enabled,
    cacheTime: Infinity
  })
}
