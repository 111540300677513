import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Typography,
  Backdrop,
  CircularProgress,
  Avatar
} from '@material-ui/core'
import { estabelecimentoAction } from '../../_actions'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from './tabs'
import BackgroundImage from './backgroundImage'
import { useEstabelecimento } from '../../hooks-querys'
import { ModalMember } from './components'
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  divCardImagem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardImagem: {
    border: `2px solid  ${theme.palette.white}`,
    position: 'relative',
    pointerEvents: 'none',
    userSelect: 'none',
    boxShadow: '0px 0px 7px 6px rgba(0,0,0,0.12)',
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: 270,
      width: '180px',
      height: '180px',
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 150,
      width: '120px',
      height: '120px',
      marginBottom: theme.spacing(1)
    }
  }
}))

export default function EventosEstabelecimento(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    match: { params }
  } = props

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isNaN(params.id)) {
      dispatch(
        estabelecimentoAction.getEventosByEstabelecimentoBySlug(params.id)
      )
    } else {
      dispatch(estabelecimentoAction.getEventosByEstabelecimento(params.id))
    }
  }, [params.id])

  const { data: estabelecimentoDetails, isLoading } = useEstabelecimento(
    params.id,
    undefined
  )
  return (
    <div className={classes.root}>
      <ModalMember establishment={estabelecimentoDetails} />
      {isLoading ? (
        <Backdrop
          className={classes.backdrop}
          open={isLoading}
          // onClick={handleClose}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ) : (
        <div>
          <BackgroundImage src={estabelecimentoDetails.banner} />
          <div className={classes.divCardImagem}>
            <Avatar
              className={classes.cardImagem}
              src={estabelecimentoDetails.imagem}
            />
          </div>
          <Typography
            gutterBottom
            variant="h2"
            color="textPrimary"
            align="center"
          >
            <strong>{estabelecimentoDetails.nome}</strong>
          </Typography>
          <Tabs establishmentId={params.id} />
        </div>
      )}
    </div>
  )
}

EventosEstabelecimento.propTypes = {
  children: PropTypes.node
}
