import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Ticket from './components/Ticket/Ticket'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { NoTicketsFound } from '..'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2, 2, 2)
    },
    [theme.breakpoints.down('xs')]: {}
  }
}))

const TicketsList = (props) => {
  const { tickets } = props
  const classes = useStyles()

  return (
    <React.Fragment>
      {!isEmpty(tickets) ? (
        <Grid className={classes.root} container spacing={4}>
          {tickets.map((n) => {
            return <Ticket key={n} ticket={n} />
          })}
        </Grid>
      ) : (
        <NoTicketsFound />
      )}
    </React.Fragment>
  )
}

export default TicketsList
