import { toLocalDateFormat } from 'utils'
import { cidade } from './cidade.reducer'

const initialState = {
  loading: false,
  aceiteEmails: true,
  aceiteTermosUso: null,
  bairro: '',
  carrinhoItem: [],
  cartaoCredito: [],
  cep: '',
  cidade: {},
  cidadeId: null,
  corujaCoin: [],
  cpfCnpj: '',
  dataNascimento: null,
  email: '',
  emailVerificado: null,
  endereco: '',
  numero: '',
  complemento: '',
  excluido: null,
  genero: '',
  id: 0,
  imagem: null,
  ingresso: [],
  nome: '',
  senha: '',
  telefone: '',
  apelido: '',
  username: '',
  telefoneVerificado: null,
  isEditable: false,
  openModalUserNecessaryData: false,
}
export function usuario(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_USUARIO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CREATE_USUARIO_SUCCESS':
      return {
        ...state,
        loading: false
      }
    case 'CREATE_USUARIO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'EDIT_USUARIO_INFO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'EDIT_USUARIO_INFO_SUCCESS':
      return {
        ...state,
        loading: false,
        isEditable: false
      }
    case 'EDIT_USUARIO_INFO_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case 'CHANGE_IS_EDITABLE':
      return {
        ...state,
        isEditable: !state.isEditable
      }
    case 'USUARIO_DETAIL':
      return {
        ...state,
        id: action.id,
        aceiteEmails: action.aceiteEmails,
        aceiteTermosUso: action.aceiteTermosUso,
        bairro: action.bairro,
        carrinhoItem: action.carrinhoItem,
        cartaoCredito: action.cartaoCredito,
        cep: action.cep,
        cidade: action.cidade,
        cidadeId: action.cidadeId,
        corujaCoin: action.corujaCoin,
        cpfCnpj: action.cpfCnpj,
        dataNascimento: toLocalDateFormat(action.dataNascimento),
        email: action.email,
        emailVerificado: action.emailVerificado,
        endereco: action.endereco,
        numero: action.numero,
        complemento: action.complemento,
        excluido: action.excluido,
        genero: action.genero,
        imagem: action.imagem,
        ingresso: action.ingresso,
        nome: action.nome,
        senha: action.senha,
        telefone: action.telefone,
        apelido: action.apelido,
        username: action.username,
        telefoneVerificado: action.telefoneVerificado
      }
    case 'USUARIO_HANDLE_ON_CHANGE_ESTADO':
      return {
        ...state,
        cidade: { ...state.cidade, estadoId: action.estadoId },
        cidadeId: null
      }
    case 'USUARIO_CLEAR':
      return initialState
    case 'USUARIO_UPDATED':
      return state
    case 'USUARIO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OPEN_MODAL_USER_NECESSARY_DATA':
      return {
        ...state,
        openModalUserNecessaryData: true,
        isEditable: true
      }
    case 'CLOSE_MODAL_USER_NECESSARY_DATA':
      return {
        ...state,
        openModalUserNecessaryData: false,
        isEditable: false
      }
    case 'LOGOUT_SUCCESS':
      return initialState
    default:
      return state
  }
}
