import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  PurchaseApplicationFormItem,
  areAllRequiredItemsFilled,
  findItemByIndex,
  getFirstIncompleteItem
} from '../../purchaseApplicationFormUtils'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { CampoInscricao, RespostaCampoInscricao } from 'types/api'
import { setActiveStep, setPurchaseApplicationFormStore } from '_actions'
import { useDidMountEffect } from 'components/Utils'
import { getCampoInfo } from './campoMapping'

const PurchaseApplicationFormInputs = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm
        ?.purchaseApplicationFormItens as PurchaseApplicationFormItem[]
  )
  const activeStep = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.activeStep as number
  )

  const currentItem = findItemByIndex(
    purchaseApplicationFormItens,
    activeStep - 1
  )

  const carrinhoItemDetails = currentItem?.carrinhoItemDetails
  const carrinhoItem = carrinhoItemDetails?.carrinhoItem
  const camposInscricao = carrinhoItemDetails?.camposInscricao || []

  // Create refs for each field
  const refs: React.RefObject<TextValidator>[] = (camposInscricao || []).map(
    () => React.createRef<TextValidator>()
  )

  // Create state for each field
  const [campoValues, setCampoValues] = useState(camposInscricao?.map(() => ''))

  const updateStore = (updatedResponses: RespostaCampoInscricao[]) => {
    if (purchaseApplicationFormItens) {
      dispatch(
        setPurchaseApplicationFormStore([
          ...purchaseApplicationFormItens.map((item) =>
            item?.id === currentItem?.id
              ? {
                  ...item,
                  respostasCamposInscricao: updatedResponses
                }
              : item
          )
        ])
      )
    }
  }

  // Initialize state with existing values
  useEffect(() => {
    if (currentItem) {
      const initialValues = camposInscricao?.map((campo) => {
        const existingResposta = findExistingResposta(
          campo.id,
          currentItem?.respostasCamposInscricao
        )
        return existingResposta?.texto || ''
      })
      setCampoValues(initialValues || [])
    }
  }, [currentItem, camposInscricao])

  const handleSubmit = () => {
    // Update the store with the new response data
    const updatedResponses: RespostaCampoInscricao[] = camposInscricao.map(
      (campo: CampoInscricao, index: number) => {
        const respostaTexto = campoValues[index] || null

        // Check if a response already exists for this campo in the store
        const existingResposta = findExistingResposta(
          campo.id,
          currentItem?.respostasCamposInscricao
        )

        // If a response exists, update it; otherwise, create a new one
        return existingResposta
          ? { ...existingResposta, texto: respostaTexto }
          : {
              id: 0,
              carrinhoItemId: carrinhoItemDetails?.carrinhoItem?.id || 0,
              campoInscricaoId: campo.id,
              texto: respostaTexto
              // Add other properties as needed based on RespostaCampoInscricao type
            }
      }
    )

    // Dispatch action to update the store
    updateStore(updatedResponses)
  }

  // Function to find an existing response for a campo
  const findExistingResposta = (
    campoId: number,
    respostasCamposInscricao: RespostaCampoInscricao[] | undefined
  ): RespostaCampoInscricao | undefined => {
    return respostasCamposInscricao?.find(
      (resposta) => resposta.campoInscricaoId === campoId
    )
  }

  const handleCampoChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCampoValues = [...campoValues]
      const respostaTexto = event.target.value

      const { mask } = getCampoInfo(
        carrinhoItemDetails?.camposInscricao[index]?.nome || ''
      )

      // Aplicar a máscara ao valor
      const respostaComMascara = mask ? mask(respostaTexto) : respostaTexto

      newCampoValues[index] = respostaComMascara
      setCampoValues(newCampoValues)
    }

  useDidMountEffect(() => {
    // Check if all required items are filled
    const allRequiredItemsFilled = areAllRequiredItemsFilled(
      purchaseApplicationFormItens
    )
    // Function to get the first item that doesn't have all fields filled
    const firstIncompleteItem = getFirstIncompleteItem(
      purchaseApplicationFormItens
    )
    const firstIncompleteItemIndex = purchaseApplicationFormItens?.findIndex(
      (item) => item?.id === firstIncompleteItem?.id
    )
    if (firstIncompleteItem) {
      dispatch(setActiveStep(firstIncompleteItemIndex + 1))
    } else {
      if (allRequiredItemsFilled) {
        dispatch(setActiveStep(purchaseApplicationFormItens?.length + 1))
      }
    }
  }, [purchaseApplicationFormItens])

  return (
    <Box
      style={{
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%'
      }}
    >
      {carrinhoItem ? (
        <Box>
          <Typography color="primary" align="left" variant="h3" gutterBottom>
            <strong>Ingresso {activeStep}</strong>
          </Typography>
          <Typography color="primary" align="left" variant="h4">
            <strong>
              {carrinhoItem.lote?.tipoIngresso &&
                carrinhoItem.lote?.tipoIngresso.descricao}{' '}
              {''}({carrinhoItem.lote?.descricao})
            </strong>
          </Typography>
        </Box>
      ) : null}
      <Box marginTop={4}>
        <ValidatorForm
          id={`form=${currentItem?.id}`}
          onSubmit={handleSubmit}
          debounceTime={1100}
        >
          {carrinhoItemDetails?.camposInscricao?.map((campo, index) => {
            const campoInfoMapped = getCampoInfo(campo?.nome)
            return (
              <Box marginBottom={2}>
                <TextValidator
                  ref={refs[index]}
                  type={campoInfoMapped?.inputType}
                  fullWidth
                  id={campo?.nome}
                  name={campo?.nome}
                  label={`${campo?.nome.toUpperCase()}${
                    campo?.obrigatorio ? '*' : ''
                  }`}
                  validators={campo?.obrigatorio ? ['required'] : undefined}
                  errorMessages={['Campo obrigatório!']}
                  value={campoValues[index]}
                  onChange={handleCampoChange(index)}
                  placeholder={campoInfoMapped?.placeholder || campo?.nome}
                />
              </Box>
            )
          })}
          <Box marginTop={6}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Avançar
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </Box>
  )
}

export default PurchaseApplicationFormInputs
