import React from 'react'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'

//? Own imports
import { useStyles } from './styles'
import { Evento } from 'types/api'

type CardEventDetailsProps = {
  eventDetails: Evento
}

const CardEventDetails = ({ eventDetails }: CardEventDetailsProps) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title={
          <Typography className={classes.textHeader} variant="h5" align="left">
            Detalhes do evento
          </Typography>
        }
      />
      <CardContent>
        <Typography variant="body1" align="justify">
          <div dangerouslySetInnerHTML={{ __html: eventDetails?.detalhes }} />
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardEventDetails
