import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  imageEvent: {
    width: '100%',
    [theme.breakpoints.down('sm')]: { width: '50%' },
    borderRadius: '5px',
    webkitBorderRadius: '5px',
    mozBorderRadius: '5px'
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  }
}))
