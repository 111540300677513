import React, { ChangeEvent } from 'react'

import {
  LinearProgress,
  Tab,
  Tabs,
  colors,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Header, Page } from 'components'
import { useMemberSubscription, useMembership } from 'hooks-querys'
import { useParams } from 'react-router'
import { history } from '_helpers'
import { MembershipFinancial, MembershipGeneral } from './components'
import { useSearchQuery } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    textAlign: 'initial'
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  }
}))

const MembershipSettings = () => {
  const classes = useStyles()
  const theme = useTheme()
  const querySearch = useSearchQuery()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { membershipId, tab } =
    useParams<{ tab: 'general' | 'financial'; membershipId: string }>()

  const { isFetching: isFetchingMembership, refetch: refetchMembership } =
    useMembership(
      {
        membershipId: Number(membershipId)
      },
      {
        config: {
          enabled: tab === 'general'
        }
      }
    )

  const {
    isFetching: isFetchingMemberSubscription,
    refetch: refetchMemberSubscription
  } = useMemberSubscription({
    membroPlanoId: Number(membershipId),
    pago: querySearch.get('Pago'),
    page: 1,
    limit: 9999
  })

  const isFetching = isFetchingMemberSubscription || isFetchingMembership

  const handleChange = (event: ChangeEvent<{}>, value: string) => {
    history.push(`/membership-settings/${value}/${membershipId}`)
  }

  const tabs = [
    { value: 'general', label: 'Configurações' },
    { value: 'financial', label: 'Financeiro' }
  ]

  return (
    <>
      {isFetching && (
        <LinearProgress
          style={{
            width: '100%',
            position: 'absolute'
          }}
        />
      )}
      {/*@ts-ignore */}
      <Page className={classes.root} title="Área de Membros">
        <Header
          subtitle="Minhas associações"
          title="Gerenciar associação"
          onRefresh={
            tab === 'financial' ? refetchMemberSubscription : refetchMembership
          }
          isRefreshing={
            tab === 'financial'
              ? isFetchingMemberSubscription
              : isFetchingMembership
          }
        />
        <Tabs
          variant={!isMobile ? 'standard' : 'fullWidth'}
          value={tab}
          onChange={handleChange}
          aria-label="membership settings tab"
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          {tabs?.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        {tab === 'general' && <MembershipGeneral />}
        {tab === 'financial' && <MembershipFinancial />}
      </Page>
    </>
  )
}
export default MembershipSettings
