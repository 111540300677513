import { useMemo } from 'react'
import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services/'
import { MembershipParams, MembershipReturn, UseQueryOptions } from './member'

const getMembership = async (
  params?: MembershipParams
): Promise<MembershipReturn> => {
  try {
    const { data } = await crudService.get(
      `membrosplanos/${params?.membershipId}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMembership(
  params?: MembershipParams,
  options?: UseQueryOptions<MembershipReturn>
) {
  const initialData = useMemo(
    () => params?.membershipDetails,
    [params?.membershipDetails]
  )
  return useQuery<MembershipReturn>(
    ['membership', params?.membershipId],
    () => getMembership(params),
    {
      initialData,
      enabled: !!params?.membershipId,
      cacheTime: Infinity,
      staleTime: 30000, // 30seg
      ...options?.config
    }
  )
}
