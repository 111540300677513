export const globalAction = {
  applyCouponCodeByLink
}

interface CouponCodeByLink {
  couponCode: string
  eventId: string
}

function applyCouponCodeByLink(couponCodeByLink: CouponCodeByLink | null) {
  return {
    type: 'APPLY_COUPON_CODE_BY_LINK',
    couponCodeByLink
  }
}
