import { useQuery } from 'react-query'
import { crudService } from '_services/'

const getUserTickets = async (userId) => {
  const { data } = await crudService.get(`ingressos/usuario/${userId}`)
  return data
}

export default function useUserTickets(userId) {
  return useQuery(['userTickets', userId], () => getUserTickets(userId), {
    enabled: userId !== undefined,
  })
}
