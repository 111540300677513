import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { makeStyles, Hidden, Typography, fade, Button } from '@material-ui/core'
import { Carousel } from 'react-responsive-carousel'
import { history } from '../../_helpers'
import Chip from '@material-ui/core/Chip'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import Moment from 'react-moment'
import 'moment-timezone'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import ImageCarrossel from './imageCarrossel'
import './styles.css'
export const Carrossel = (props) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const useStyles = makeStyles((theme) => ({
    carousel: {
      [theme.breakpoints.up('md')]: {
        height: 'auto',
        maxHeight: '450px',
        backgroundColor: theme.palette.background.default
      },
      [theme.breakpoints.down('sm')]: {
        height: '150px',
        backgroundColor: theme.palette.background.default
      }
    },
    imagemContainer: {
      cursor: 'grab',
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '435px',
        maxHeight: '450px',
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(2)
      },
      [theme.breakpoints.down('sm')]: {
        height: '320px',
        backgroundColor: theme.palette.background.default
      },
      [theme.breakpoints.down('xs')]: {
        height: '220px',
        backgroundColor: theme.palette.background.default
      }
    },
    chip: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(-1)
    },
    legend: {
      backgroundColor: fade(theme.palette.common.black, 0.2),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.4)
      },
      position: 'absolute',
      borderRadius: '0px 0px 10px 10px',
      bottom: '9px',
      left: '50%',
      marginLeft: '-49.25%',
      width: '98.55%',
      padding: '30px',
      textAlign: 'center',
      fontWeight: '900',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        bottom: '0px',
        borderRadius: 0,
        width: '100%'
      }
    },
    textWhite: {
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(-2)
      },
      color: 'white'
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(-1),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 9px 40px -14px ${theme.palette.primary.main}`,
        webkitTransition: ' all 300ms ease',
        transition: 'all 300ms ease',
        letterSpacing: '1.2px'
      }
    }
  }))

  const classes = useStyles()
  return (
    <Carousel
      className={classes.carousel}
      emulateTouch={true}
      autoPlay={true}
      stopOnHover={true}
      infiniteLoop={true}
      showStatus={false}
      showThumbs={false}
      transitionTime={300}
      // swipeScrollTolerance={1}
      centerMode={isDesktop}
      centerSlidePercentage={60}
      interval={4000}
      showArrows={isDesktop ? true : false}
      showIndicators={false}
    >
      <div
        onClick={() => {
          window.open('http://organizador.corujasapp.com/cadastro', '_blank')
        }}
        className={classes.imagemContainer}
        style={{ cursor: 'pointer' }}
      >
        <ImageCarrossel src={require('../../img/banner.gif')} />
      </div>
      {props.evento.map((n) => {
        if (n.imagem != undefined) {
          return (
            <div
              key={n.id}
              onClick={
                isMobile
                  ? () => {
                      n.slug
                        ? history.push(`/event-details/${n.slug}`)
                        : history.push(`/event-details/${n.id}`)
                    }
                  : null
              }
              className={classes.imagemContainer}
            >
              <ImageCarrossel src={n.imagem} />

              <div
                className={classes.legend}
                onClick={() => {
                  n.slug
                    ? history.push(`/event-details/${n.slug}`)
                    : history.push(`/event-details/${n.id}`)
                }}
              >
                <Hidden smDown>
                  {n.dataHoraInicio !== undefined &&
                    n.dataHoraInicio !== null && (
                      <Chip
                        className={classes.chip}
                        size="small"
                        icon={<EventOutlinedIcon fontSize="inherit" />}
                        label={
                          <Moment format="DD/MM/YYYY">
                            {n.dataHoraInicio}
                          </Moment>
                        }
                        color="inherit"
                        variant="default"
                      />
                    )}
                </Hidden>
                <Typography
                  className={classes.textWhite}
                  variant={isMobile ? 'h3' : 'h1'}
                >
                  {n.nome}
                </Typography>
                <Button
                  size={isMobile ? 'small' : 'medium'}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                >
                  Ver detalhes
                </Button>
              </div>
            </div>
          )
        }
      })}
    </Carousel>
  )
}
