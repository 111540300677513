import React from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
  useMediaQuery,
  useTheme,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  ButtonGroup,
  Button,
  Grid
} from '@material-ui/core'

const options = [
  {
    title: 'Quero saber mais',
    onClick: () => {
      window.open('https://sejaparceiro.corujasapp.com', '_self')
    }
  },
  {
    title: 'Já sou parceiro',
    onClick: () => {
      window.open('https://organizador.corujasapp.com', '_self')
    }
  }
]

type ButtonGroupPortalOrganizadorProps = {
  className?: string
}

export default function ButtonGroupPortalOrganizador({
  className
}: ButtonGroupPortalOrganizadorProps) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  return (
    <Grid
      className={className}
      container
      direction="column"
      alignItems="flex-end"
    >
      <Grid item xs={12}>
        <ButtonGroup
          size={isMobile ? 'small' : 'medium'}
          variant="outlined"
          color="inherit"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button onClick={handleToggle}>Organizar meu evento</Button>
          <Button
            color="inherit"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Paper>
                  <MenuList style={{ marginTop: 10 }} id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.title}
                        // @ts-ignore
                        onClick={option.onClick}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}
