import { useQuery } from 'react-query'
import { crudService } from '_services/'

const getEventosEstabelecimento = async (estabelecimentoId, pendentes) => {
  const stringPendentes = pendentes ? 'pendentes' : 'realizados'
  try {
    const { data } = await crudService.get(
      `eventos/${stringPendentes}/estabelecimento/${estabelecimentoId}`
    )
    return data
  } catch (e) {
    return e
  }
}

export default function useEventosEstabelecimento(estabelecimentoId, pendentes) {
  return useQuery(['eventosEstabelecimento', estabelecimentoId, pendentes], () =>
    getEventosEstabelecimento(estabelecimentoId, pendentes)
  )
}
