import React from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  makeStyles,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

//? Own imports
import { Pix as PixIcon, Boleto as BoletoIcon } from 'icons'
import { Pix, Boleto } from './components'
import { Pagamento } from 'types/api'

//@ts-ignore
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    }
  },
  tabs: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .Mui-selected': {
      fontWeight: 'bold'
    }
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}))

export default function PaymentMethods({ payment }: { payment: Pagamento }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const paymentMethods = [
    {
      id: 3,
      label: 'Pix',
      icon: <PixIcon className={classes.icon} />,
      component: Pix
    },

    {
      id: 2,
      label: 'Boleto',
      icon: <BoletoIcon className={classes.icon} />,
      component: Boleto
    }
  ]

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Tabs
            orientation={!isMobile ? 'vertical' : 'horizontal'}
            variant={!isMobile ? 'scrollable' : 'standard'}
            centered={isMobile}
            value={payment?.formaPagamentoId}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="secondary"
          >
            {paymentMethods.map((method) =>
              method?.id === payment?.formaPagamentoId ? (
                <Tab
                  value={method?.id}
                  key={method?.id}
                  classes={
                    !isMobile
                      ? {
                          wrapper: classes.iconLabelWrapper,
                          //@ts-ignore
                          labelContainer: classes.labelContainer
                        }
                      : {}
                  }
                  icon={method.icon}
                  label={method.label}
                  {...a11yProps(method.id)}
                />
              ) : null
            )}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box marginTop={2}>
            <Box justifyContent={'center'} display="flex" alignItems={'center'}>
              <Box>
                <Typography variant="h6">
                  Fatura Nº {payment && payment.id ? payment.id : ''} -{' '}
                  {format(
                    new Date(payment.dataHora),
                    `dd'/'MM'/'yyyy 'às' kk':'mm`,
                    {
                      locale: brLocale
                    }
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <TabPanel value={payment?.formaPagamentoId} index={3}>
            <Pix payment={payment} />
          </TabPanel>
          <TabPanel value={payment?.formaPagamentoId} index={2}>
            <Boleto payment={payment} />
          </TabPanel>
          {/* //!TODO: Waiting backend */}
          {/*@ts-ignore */}
          {/* <TabPanel style={{ width: '100%' }} value={paymentMethodId} index={1}>
            <CreditCard payment={payment} />
          </TabPanel> */}
        </Grid>
      </Grid>
    </div>
  )
}
