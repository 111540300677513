import { crudService } from '_services'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { CreditCard, Pedido } from 'types/api'

type Payload = {
  hashCartaoCreditoUnico?: string
  cartaoCredito?: CreditCard
  pedido: Pedido | undefined
  endereco?: string
  numero?: string
  complemento?: string
  bairro?: string
  cep?: string
  cidadeId?: number
}

const postCreditCardPayment = async (payload: Payload) => {
  if (payload.hashCartaoCreditoUnico || payload.cartaoCredito) {
    const { data } = await crudService.post(
      'PagarMeCheckout/creditCardPayment',
      payload
    )
    return data
  }
  return null
}

export default function useMutationCreditCardPayment() {
  const queryClient = useQueryClient()
  const theme = useTheme()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  return useMutation(
    'mutationCreditCardPayment',
    (payload: Payload) => postCreditCardPayment(payload),
    {
      onError: (error: any) => {
        toast.error(error.message || 'Não foi possível realizar o pagamento')
        !isMobile && window.scrollTo(0, 0)
      },
      onSuccess: (data, variables, context) => {
        if (variables && variables.pedido && variables.pedido.id) {
          queryClient.setQueryData<Pedido | undefined>(
            ['orderDetails', variables.pedido?.id],
            (oldDate) => {
              if (oldDate && oldDate.statusPedido) {
                return {
                  ...oldDate,
                  statusPedidoId: 3,
                  statusPedido: {
                    ...oldDate.statusPedido,
                    pendente: false,
                    finalizado: true
                  }
                }
              }
              return oldDate
            }
          )
          queryClient.invalidateQueries(['orderDetails', variables.pedido?.id])
        }
      }
    }
  )
}
