import { authActions } from '../_actions'
import { history } from '../_helpers'

export const errorHandlerAction = {
  errors
}

function errors(error) {
  return (dispatch) => {
    if (error && error.response && error.response.status === 401) {
      dispatch(authActions.logout())
      history.goBack()
    }
  }
}

export function error401() {
  return {
    type: 'INGRESSO_CREATED_SUCCESSFULLY'
  }
}
