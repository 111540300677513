import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDebouncedEffect } from '../../../../components/Utils'
import { isEmpty } from 'lodash'
import { history } from '../../../../_helpers'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  Input,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  fade,
  ListSubheader,
  Typography,
  withStyles,
  CircularProgress,
  InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone'
import PlaceTwoToneIcon from '@material-ui/icons/PlaceTwoTone'
import HomeTwoToneIcon from '@material-ui/icons/HomeTwoTone'
import CategoryTwoToneIcon from '@material-ui/icons/CategoryTwoTone'
import { searchActions, eventoAction } from '../../../../_actions'

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },

  search: {
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    borderRadius: 4,
    flexBasis: 320,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    width: 190,
    color: 'inherit',
    '&input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
    width: 380
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  listIcon: {
    marginRight: '-20px'
  },
  text: {
    '&:hover': {
      fontWeight: 'bold'
    }
  },
  skeleton: {
    padding: theme.spacing(2)
  },
  noResultText: {
    padding: theme.spacing(2),
    textAlign: 'center'
  }
})

const InputSearchTopBar = (props) => {
  const { classes } = props
  const { result } = props.search
  const searchRef = useRef(null)
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [openSearchPopover, setOpenSearchPopover] = useState(false)

  useDebouncedEffect(() => search(), 600, [searchValue])
  const search = () => {
    if (searchValue.length > 2) {
      const params = { Texto: `${searchValue}`, Categorias: null }
      dispatch(searchActions.search(params))
      if (searchValue) {
        if (!openSearchPopover) {
          setOpenSearchPopover(true)
        }
      } else {
        setOpenSearchPopover(false)
      }
    } else if (searchValue.length === 0) {
      setOpenSearchPopover(false)
    }
  }
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value)
  }

  const textSize = (text) => {
    const MAX_LENGTH = 50
    if (text.length <= MAX_LENGTH) {
      return text
    } else {
      return `${text.substring(0, MAX_LENGTH)}...`
    }
  }

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false)
    setSearchValue('')
  }

  const onClickEvento = (eventoId, slug) => {
    setOpenSearchPopover(false)
    setSearchValue('')
    if (slug) {
      history.push(`/event-details/${slug}`)
    } else {
      history.push(`/event-details/${eventoId}`)
    }
  }

  const onClickEstabelecimento = (estabelecimentoId, slug) => {
    setOpenSearchPopover(false)
    setSearchValue('')
    if (slug) {
      history.push(`/${slug}`)
    } else {
      history.push(`/estabelecimento-profile/${estabelecimentoId}`)
    }
  }
  const onClickCategoria = (categoria) => {
    setOpenSearchPopover(false)
    setSearchValue('')
    history.push(
      `/search?cat=${categoria.descricao.toLowerCase()}&catId=${categoria.id}`
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.search} ref={searchRef}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          onChange={handleSearchChange}
          placeholder="Busque eventos e locais"
          value={searchValue}
          endAdornment={
            <InputAdornment position="end">
              {props.search.searching && (
                <CircularProgress
                  color="inherit"
                  size={20}
                  className={classes.buttonProgress}
                />
              )}
            </InputAdornment>
          }
        />
      </div>
      <Popper
        anchorEl={searchRef.current}
        className={classes.searchPopper}
        open={openSearchPopover}
        transition
      >
        <ClickAwayListener onClickAway={handleSearchPopverClose}>
          <Paper className={classes.searchPopperContent} elevation={3}>
            {props.search.searching ? (
              <div className={classes.skeleton}>
                {/* <Typography variant="h6" color="initial">Procurando...</Typography> */}
                <Skeleton width={100} animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </div>
            ) : (
              <React.Fragment>
                {isEmpty(result.evento) &&
                isEmpty(result.estabelecimento) &&
                isEmpty(result.cidade) &&
                isEmpty(result.categoria) ? (
                  <Typography
                    className={classes.noResultText}
                    gutterBottom
                    variant="h6"
                    color="textPrimary"
                  >
                    Nenhum resultado encontrado 😥
                  </Typography>
                ) : (
                  <List dense subheader={<li />}>
                    {/* ESTABELECIMENTOS */}
                    {!isEmpty(result.estabelecimento) && (
                      <li
                        key="estabelecimentosListText"
                        className={classes.listSection}
                      >
                        <ul className={classes.ul}>
                          <ListSubheader disableSticky>
                            <Typography variant="caption" color="primary">
                              Estabelecimentos
                            </Typography>
                          </ListSubheader>
                          {result.estabelecimento.map((item) => (
                            <ListItem
                              button
                              key={`item-${item}-${item}`}
                              onClick={() =>
                                onClickEstabelecimento(item.id, item.slug)
                              }
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <HomeTwoToneIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                dense="true"
                                primary={
                                  <Typography
                                    className={classes.text}
                                    display="block"
                                    component="p"
                                    variant="caption"
                                    color="textPrimary"
                                  >
                                    {textSize(item.nome)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </ul>
                      </li>
                    )}
                    {/* CIDADES */}
                    {/* {!isEmpty(result.cidade) && (
                      <li key={'cidades'} className={classes.listSection}>
                        <ul className={classes.ul}>
                          <ListSubheader disableSticky>
                            <Typography variant="caption" color="primary">
                              Cidades
                            </Typography>
                          </ListSubheader>
                          {result.cidade.map((item) => (
                            <ListItem
                              button
                              key={`item-${item}-${item}`}
                              onClick={handleSearchPopverClose}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <PlaceTwoToneIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                dense="true"
                                primary={
                                  <Typography
                                    className={classes.text}
                                    display="block"
                                    component="p"
                                    variant="caption"
                                    color="textPrimary"
                                  >
                                    {textSize(item.nome)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </ul>
                      </li>
                    )} */}
                    {/* EVENTOS */}
                    {!isEmpty(result.evento) && (
                      <li key="eventos" className={classes.listSection}>
                        <ul className={classes.ul}>
                          <ListSubheader disableSticky>
                            <Typography variant="caption" color="primary">
                              Eventos
                            </Typography>
                          </ListSubheader>
                          {result.evento.map((item) => (
                            <ListItem
                              button
                              key={`item-${item}-${item}`}
                              onClick={() => onClickEvento(item.id, item.slug)}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <EventNoteTwoToneIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                dense
                                primary={
                                  <Typography
                                    className={classes.text}
                                    display="block"
                                    component="p"
                                    variant="caption"
                                    color="textPrimary"
                                  >
                                    {textSize(item.nome)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </ul>
                      </li>
                    )}
                    {/* CATEGORIAS */}
                    {!isEmpty(result.categoria) && (
                      <li key="categorias" className={classes.listSection}>
                        <ul className={classes.ul}>
                          <ListSubheader disableSticky>
                            <Typography variant="caption" color="primary">
                              Categorias
                            </Typography>
                          </ListSubheader>
                          {result.categoria.map((item) => (
                            <ListItem
                              button
                              key={`item-${item}-${item}`}
                              onClick={() => onClickCategoria(item)}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                <CategoryTwoToneIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText
                                dense
                                primary={
                                  <Typography
                                    className={classes.text}
                                    display="block"
                                    component="p"
                                    variant="caption"
                                    color="textPrimary"
                                  >
                                    {textSize(item.descricao)}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          ))}
                        </ul>
                      </li>
                    )}
                  </List>
                )}
              </React.Fragment>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  )
}
InputSearchTopBar.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    search: state.searchTopBar
  }
}
const connectedInputSearchTopBarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(InputSearchTopBar))
)
export { connectedInputSearchTopBarPage as InputSearchTopBar }
