import React from 'react'
import { formatBoleto } from '@brazilian-utils/format-boleto'
import {
  Typography,
  useMediaQuery,
  Button,
  Box,
  CircularProgress,
  Divider
} from '@material-ui/core'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useMutationCreateOrder,
  useOrder
} from 'hooks-querys'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import { useRouter, validateUserNecessaryData } from 'utils'
import { toast } from 'react-toastify'
import { Boleto as BoletoIcon } from 'icons'
import { useDispatch, useSelector } from 'react-redux'
import { extractRespostasCamposInscricao } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'
import {
  extractCartItems,
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const Boleto = ({ eventoId, isCheckIn }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.authentication)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const router = useRouter()
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.purchaseApplicationFormItens
  )

  const pedidoId = router.match.params.pedidoId
  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(eventoId)

  const filteredCartItems = filterValidCartItems(data)
  const carrinhoItensWithoutCheckIn = filterItemsWithoutCheckIn(
    filteredCartItems || []
  )
  const carrinhoItensWithCheckIn = filterItemsWithCheckIn(
    filteredCartItems || []
  )
  const carrinhoItens = isCheckIn
    ? carrinhoItensWithCheckIn
    : carrinhoItensWithoutCheckIn

  const totalValue = calculaValorAPagar(
    carrinhoItens || [],
    (discountCoupon && discountCoupon.carrinhoItens) || [],
    discountCoupon && discountCoupon.cupomDesconto
  )
  const mutationCreateOrder = useMutationCreateOrder(
    {
      textoCupom:
        discountCoupon && discountCoupon?.cupomDesconto
          ? discountCoupon?.cupomDesconto.codigo
          : '',
      carrinhoItens: extractCartItems(carrinhoItens),
      formaPagamentoId: 2,
      respostasCamposInscricao: extractRespostasCamposInscricao(
        purchaseApplicationFormItens
      )
    },
    totalValue
  )
  const {
    data: order,
    isLoading,
    isFetching,
    refetch
  } = useOrder(pedidoId && Number(pedidoId), undefined)

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(order?.linhaDigitavelBoleto)
    toast.success('Feito! O código do boleto foi copiado.')
  }
  const handleClickCreateOrder = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      mutationCreateOrder.mutate()
    }
  }
  return (
    <div>
      {!pedidoId && <BoletoIcon style={{ fontSize: 65 }} />}
      <Typography variant={'h5'} gutterBottom>
        <strong>
          {pedidoId && order
            ? 'Agora é só pagar o boleto '
            : 'Guia de pagamento por boleto'}
        </strong>
      </Typography>
      {pedidoId ? (
        <Box>
          <Typography color="textSecondary" variant="body2" align="left">
            Abra o aplicativo ou banco de sua preferência e utilize o número
            copiado para pagar seu boleto:
          </Typography>
          {order && order?.linkBoleto && (
            <Box mt={2}>
              <Typography
                size="small"
                color="secondary"
                fullWidth
                style={{ marginBottom: 10 }}
              >
                <strong>
                  {formatBoleto(
                    order && order?.linhaDigitavelBoleto
                      ? order?.linhaDigitavelBoleto
                      : ''
                  )}
                </strong>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyToClipboard}
                size="small"
                fullWidth
              >
                Copiar código
              </Button>
            </Box>
          )}
          <Box marginY={2}>
            <Divider />
          </Box>
          <Typography color="textSecondary" variant="body2" align="left">
            {isMobile
              ? 'Salve o boleto em seu celular e utilize-o para pagar em casas lotérias e agências bancárias. Não precisa imprimir 😀'
              : 'Salve o boleto em seu computador para imprimir ou pague pela internet utilizando o código de barras do boleto.'}
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              href={order?.linkBoleto}
              target="_blank"
              size="small"
              fullWidth
            >
              Salvar boleto
            </Button>
          </Box>
          <Box marginY={2}>
            <Typography color="textSecondary" variant="body2" align="center">
              Prazo para pagamento até{' '}
              {format(
                new Date(order.dataHoraValidade),
                `dd'/'MM'/'yyyy 'às' kk':'mm`,
                {
                  locale: brLocale
                }
              )}
            </Typography>
          </Box>
          <Typography align="left" variant="caption" color="textPrimary">
            Ahh, se o pagamento não for realizado dentro do prazo, não se
            preocupe. O pedido será cancelado automaticamente.
          </Typography>
        </Box>
      ) : (
        <Box paddingTop={4}>
          <Typography variant="body2" align="left" gutterBottom>
            Clique no botão abaixo para finalizar o pedido e gerar o boleto de
            cobrança.
          </Typography>
          <Box paddingTop={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickCreateOrder}
              disabled={mutationCreateOrder.isLoading}
            >
              {mutationCreateOrder.isLoading
                ? 'Finalizando pedido...'
                : 'Pagar com boleto'}
              {mutationCreateOrder.isLoading && (
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ marginLeft: 5 }}
                />
              )}
            </Button>
          </Box>
        </Box>
      )}
    </div>
  )
}

export default Boleto
