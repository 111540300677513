import React, { useState } from 'react'
import {
  Grid,
  CardContent,
  CardMedia,
  Card,
  Typography,
  CardActionArea,
  Divider,
  List,
  ListItem,
  Grow,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Button,
  makeStyles,
  Zoom
} from '@material-ui/core'
import LocationIcon from '@material-ui/icons/LocationOn'
import EventIcon from '@material-ui/icons/Event'
import Moment from 'react-moment'
import 'moment-timezone'
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import QRCode from 'qrcode.react'
import { history } from '../../../../../../../../_helpers'
import { formatCurrency, getGenderFull } from 'utils'

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2)
  },
  cardContentText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
    height: 135
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    marginBottom: theme.spacing(-2),
    borderRadius: 5
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  qrCode: { height: 140, padding: theme.spacing(1) },
  contentTicket: {
    fontSize: 12,
    textAlign: 'center',
    userSelect: 'none',
    padding: theme.spacing(1)
  }
}))

const Ticket = (props) => {
  const classes = useStyles()
  const ticket = props.ticket
  const [showQRCode, setShowQRCode] = useState(true)

  const calculaValorIngresso = (ingresso) => {
    if (ingresso.valor === 0) {
      return 'Gratuito'
    } else {
      return formatCurrency(ingresso.valor - ingresso.valorDesconto)
    }
  }

  return (
    <Grow in={true}>
      <Grid item key={ticket.id} xs={12} sm={6} md={4}>
        <Card className={classes.card} Paper elevation={6}>
          <Tooltip title="Ver evento" placement="top">
            <CardActionArea>
              <CardMedia
                className={classes.cardMedia}
                image={ticket.lote.evento.imagem}
                title={ticket.lote.evento.nome}
                onClick={() =>
                  history.push(`/event-details/${ticket.lote.evento.id}`)
                }
              />
            </CardActionArea>
          </Tooltip>
          <CardContent className={classes.cardContent}>
            <Typography
              style={{ marginTop: -8, paddingBottom: 7 }}
              variant="h5"
            >
              <strong>{ticket.lote.evento.nome}</strong>
            </Typography>
            <Divider variant="middle" />
            {ticket.qrcode !== undefined &&
            ticket.qrcode !== null &&
            !ticket.statusIngresso.validado &&
            !ticket.statusIngresso.cancelado &&
            !ticket.statusIngresso.transferido &&
            !ticket.statusIngresso.liberadoCarteira &&
            showQRCode ? (
              <Zoom in={showQRCode}>
                <QRCode
                  className={classes.qrCode}
                  value={ticket.qrcode}
                  size={148}
                  bgColor={'#ffffff'}
                  fgColor={'#000000'}
                  level={'L'}
                  includeMargin={false}
                  renderAs={'svg'}
                />
              </Zoom>
            ) : (
              <Typography
                className={classes.cardContentText}
                gutterBottom
                variant="h7"
                component="h3"
              >
                {ticket.statusIngresso.liberadoCarteira ||
                ticket.statusIngresso.transferido
                  ? 'FINALIZADO'
                  : ticket.statusIngresso.descricao}
              </Typography>
            )}
            {!ticket.statusIngresso.validado &&
              !ticket.statusIngresso.cancelado &&
              !ticket.statusIngresso.transferido &&
              !ticket.statusIngresso.liberadoCarteira && (
                <Button
                  fullWidth
                  variant="text"
                  color={showQRCode ? 'primary' : 'secondary'}
                  startIcon={
                    showQRCode ? (
                      <VisibilityTwoToneIcon />
                    ) : (
                      <VisibilityOffTwoToneIcon color="disabled" />
                    )
                  }
                  onClick={() => setShowQRCode(!showQRCode)}
                >
                  <Typography
                    variant="subtitle2"
                    color={!showQRCode ? 'secondary' : 'primary'}
                  >
                    {!showQRCode ? 'Mostrar QRCode' : 'Esconder QRCode'}
                  </Typography>
                </Button>
              )}
            <Typography
              color="textPrimary"
              variant="body2"
              className={classes.contentTicket}
            >
              {ticket.lote.tipoIngresso.descricao} - {ticket.lote.descricao} (
              {getGenderFull(ticket.lote.genero)})<br></br>
              {ticket.valor !== null &&
                `Valor do ingresso: ${calculaValorIngresso(ticket)}`}
            </Typography>
            <Divider variant="middle" />
            <List style={{ marginBottom: -18 }} dense>
              <ListItem dense>
                <ListItemIcon>
                  <LocationIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="textPrimary">
                      {ticket.lote.evento.nomeLocal !== '' &&
                      ticket.lote.evento.nomeLocal !== null
                        ? ticket.lote.evento.nomeLocal
                        : ticket.lote.evento.estabelecimento.nome}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem dense>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                {ticket.lote.evento.dataHoraInicio !== null ? (
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="textPrimary">
                        {
                          <Moment format="DD/MM/YYYY">
                            {ticket.lote.evento.dataHoraInicio}
                          </Moment>
                        }
                      </Typography>
                    }
                  />
                ) : (
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="textPrimary">
                        Sem data definida
                      </Typography>
                    }
                  />
                )}
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grow>
  )
}

export default Ticket
