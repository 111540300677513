import { useQuery } from 'react-query'

//? Own imports
import { CreditCard } from 'types/api'
import { crudService } from '_services'

const getCreditCards = async (usuarioId: number) => {
  if (usuarioId) {
    const { data } = await crudService.get(
      `cartoesCredito/usuario/${usuarioId}`
    )
    return data
  }
}

export default function useUserCreditCards(usuarioId: number) {
  return useQuery<CreditCard[]>(
    ['creditCards', usuarioId],
    () => getCreditCards(usuarioId),
    {}
  )
}
