export default function getCreditCardImage(flag) {
  switch (flag) {
    case 'VISA':
      return require('../assets/payment-logos/centered/visa.png')

    case 'MASTERCARD':
      return require('../assets/payment-logos/centered/mastercard.png')

    case 'DINERS':
      return require('../assets/payment-logos/centered/diners.png')

    case 'AMEX':
      return require('../assets/payment-logos/centered/amex.png')

    case 'DISCOVER':
      return require('../assets/payment-logos/centered/discover.png')

    case 'HIPERCARD':
      return require('../assets/payment-logos/centered/hipercard.png')

    case 'ELO':
      return require('../assets/payment-logos/centered/elo.png')
    case 'JCB':
      return require('../assets/payment-logos/centered/jcb.png')

    default:
      break
  }

  return undefined
}
