import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'
import { usuarioActions } from '../../_actions'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff'
  },
  backButton: {
    marginTop: theme.spacing(1)
  }
})

class EmailVerification extends Component {
  state = {
    submitted: false,
    email: '',
    token: '',
    password: '',
    repeatPassword: ''
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    var email = query.toString().substr(6, query.toString().indexOf('&', 0) - 6)
    this.setState({ email: decodeURIComponent(email) })

    var token = query
      .toString()
      .substr(
        query.toString().indexOf('token=', 0) + 6,
        query.toString().length - query.toString().indexOf('token=', 0) - 6
      )
    this.setState({ token: token })

    let payload = {
      username: decodeURIComponent(email),
      password: 'senha'
    }

    const { dispatch } = this.props
    dispatch(usuarioActions.emailVerification(payload, token))
  }

  render() {
    return (
      <Container component={Paper} maxWidth="sm">
        <CssBaseline />
      </Container>
    )
  }
}
EmailVerification.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return state
}
const connectedEmailVerificationPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmailVerification))
)
export { connectedEmailVerificationPage as EmailVerification }
