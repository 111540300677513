import axios from 'axios'

const GOOGLE_DYNAMIC_LINKS_API_KEY = 'AIzaSyAhOv-A1oSKYiezpqnlEqZxB9HkoSO3ufY'
const options = {
  headers: {
    Accept: 'application/json'
  }
}

export const createDynamicLink = async (params) => {
  const response = await axios
    .post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${GOOGLE_DYNAMIC_LINKS_API_KEY}`,
      {
        ...params
      },
      options
    )
    .then((res) => res.data)
    .catch(() => {
      return ''
    })
  return response.shortLink
}
