import { Periodicidade } from '../types/api'

const mapPeriodicityChar = (periodicity: Periodicidade | null): string => {
  const periodicityMap: Record<Periodicidade, string> = {
    M: 'Mensal',
    A: 'Anual'
  }

  if (periodicity === null || periodicity === undefined) {
    return 'Indefinido'
  }

  if (periodicity in periodicityMap) {
    return periodicityMap[periodicity]
  } else {
    throw new Error('Tipo de periodicidade não reconhecido')
  }
}

export default mapPeriodicityChar
