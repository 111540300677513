interface GlobalState {
  couponCodeByLink: { couponCode: string; eventId: string } | null
}

interface ApplyCouponCodeByLinkAction {
  type: 'APPLY_COUPON_CODE_BY_LINK'
  couponCodeByLink: { couponCode: string; eventId: string } | null
}

type GlobalAction = ApplyCouponCodeByLinkAction

const initialState: GlobalState = {
  couponCodeByLink: null
}

export function global(
  state: GlobalState = initialState,
  action: GlobalAction
): GlobalState {
  switch (action.type) {
    case 'APPLY_COUPON_CODE_BY_LINK':
      return {
        ...state,
        couponCodeByLink: action.couponCodeByLink
      }

    default:
      return state
  }
}
