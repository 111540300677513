import { format } from 'date-fns'

export default function toLocalDateHourFormat(isoDateTime) {
  const date = new Date(isoDateTime)
  if (isoDateTime !== null && isoDateTime !== undefined) {
    return format(date, 'dd/MM/yyyy HH:mm')
  } else {
    return null
  }
}
