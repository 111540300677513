import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { history } from '_helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing(6),
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0)
    }
  },
  container: {
    margin: theme.spacing(6, 4, 0, 4),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 1, 0, 1)
    }
  },
  card: {
    cursor: 'pointer',
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:hover': {
      transform: 'scale(1.24)'
    }
  },
  imagem: {
    width: '80px',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '65px',
      height: '65px'
    }
  },
  description: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  }
}))

const CategoriasList = () => {
  const classes = useStyles()
  const categorias = useSelector((state) => state.categoria.categoria)

  const itemClick = (href) => {
    if (href.includes('https')) {
      var win = window.open(href, '_blank')
      win.focus()
    } else if (href.includes('mailto')) {
      window.open(href)
    } else {
      window.scrollTo(0, 0)
      history.push(href)
    }
  }
  return (
    <div className={classes.root}>
      <Typography align="center" variant="h3" style={{ color: 'white' }}>
        Que experiência vai viver hoje?
      </Typography>
      <Grid container justify="space-evenly">
        {!!categorias &&
          categorias !== null &&
          categorias.map((categoria) => (
            <Grid key={categoria.id} item className={classes.container}>
              <Grid
                className={classes.card}
                container
                direction="column"
                spacing={2}
                onClick={() =>
                  itemClick(
                    `/search?cat=${categoria.descricao.toLowerCase()}&catId=${
                      categoria.id
                    }`
                  )
                }
              >
                <Grid item>
                  <img src={categoria.imagem} className={classes.imagem} />
                </Grid>
                <Grid item>
                  <Typography
                    align="center"
                    variant="subtitle1"
                    className={classes.description}
                  >
                    {categoria.descricao.charAt(0).toUpperCase() +
                      categoria.descricao.slice(1).toLowerCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default CategoriasList
