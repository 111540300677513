import { useQuery } from 'react-query'

//? Own Imports
import { crudService } from '_services'
import {
  MemberSubscriptionParams,
  MemberSubscriptionReturn,
  UseQueryOptions
} from './member'

const getMemberSubscription = async (
  params: MemberSubscriptionParams
): Promise<MemberSubscriptionReturn> => {
  const { limit, membroPlanoId, page, pago, direction, order, term } = params

  try {
    const { data } = await crudService.get(
      `subscricoes?MembroPlanoId=${membroPlanoId}&Pago=${
        pago || ''
      }&Page=${page}&Limit=${limit}&Term=${term || ''}&Order=${
        order || ''
      }&Direction=${direction || ''}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMemberSubscription(
  params: MemberSubscriptionParams,
  options?: UseQueryOptions<MemberSubscriptionReturn>
) {
  return useQuery<MemberSubscriptionReturn>(
    [
      'memberSubscription',
      params.membroPlanoId,
      params.pago,
      params.page,
      params.limit,
      params.term,
      params.order,
      params.direction
    ],
    () => getMemberSubscription(params),
    {
      enabled: !!params?.membroPlanoId,
      staleTime: 600000, // 10 minutes
      ...options?.config,
      onError: (error) => {
        // @ts-ignore
        toast.error(error?.message || 'Erro ao buscar subscrições', {
          toastId: 'memberSubscription'
        })
      }
    }
  )
}
