import React from 'react'

//? Own imports
import { useMembershipPayment } from 'hooks-querys'
import { Subscricao } from 'types/api'
import { Box, Button, Chip, Typography, useTheme } from '@material-ui/core'
import { useStyles } from './styles'
import {
  formatCurrency,
  mapPeriodicityChar,
  toLocalDateFormat,
  toLocalDateHourFormat
} from 'utils'
import { history } from '_helpers'

type SubscriptionItemProps = {
  item: Subscricao
}

const SubscriptionItem = ({ item }: SubscriptionItemProps) => {
  const { dataHoraFim, dataHoraInicio, id, membroPlano, pago } = item
  const classes = useStyles()
  const theme = useTheme()

  const {
    data: subscriptionPayments,
    isSuccess: isSuccessSubscriptionPayments
  } = useMembershipPayment(
    {
      subscriptionId: item?.id
    },
    { config: { enabled: !item?.pago } }
  )
  const lastPayment =
    subscriptionPayments &&
    subscriptionPayments[subscriptionPayments?.length - 1]

  const handlePressCreatePaymentButton = () => {
    if (lastPayment && lastPayment?.statusPagamento?.pendente) {
      switch (lastPayment?.formaPagamentoId) {
        case 2:
          history.push(`/plan-purchase-confirmation/${item?.id}`)
          break
        case 3:
          history.push(`/plan-purchase-confirmation/${item?.id}`)
          break
        case 1:
          break
        default:
          break
      }
    } else {
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.leftContainer}>
          <Box className={classes.leftContentItem}>
            <Typography variant="body1">
              <strong>N.º {id}</strong>
            </Typography>
            <Typography variant="body1">
              Plano {membroPlano?.plano?.nome?.toString()}
            </Typography>
            {membroPlano?.planoValor?.periodicidade && (
              <Typography variant="body1">
                Recorrência{' '}
                {mapPeriodicityChar(
                  membroPlano?.planoValor?.periodicidade
                )?.toUpperCase()}
              </Typography>
            )}
          </Box>
          <Box className={classes.leftContentItem}>
            <Typography variant="body1">
              Criado em {toLocalDateFormat(dataHoraInicio)}
            </Typography>
            {!pago && (
              <Typography variant="body1">
                Válido até {toLocalDateHourFormat(dataHoraFim)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.rightContainer}>
          <Typography variant="h4">
            <strong>{formatCurrency(membroPlano?.planoValor?.valor)}</strong>
          </Typography>
          <Chip
            style={{
              width: '100%',
              backgroundColor: !pago ? 'red' : undefined
            }}
            color={pago ? 'primary' : 'default'}
            label={
              <Typography variant="body1" style={{ color: 'white' }}>
                <strong>{pago ? 'Pago' : 'Pendente'}</strong>
              </Typography>
            }
          />
        </Box>
      </Box>
      {!pago && isSuccessSubscriptionPayments && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePressCreatePaymentButton}
            fullWidth
          >
            PAGAR
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default SubscriptionItem
