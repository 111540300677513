import { useQuery } from 'react-query'
import { Estado } from 'types/api'
import { crudService } from '_services'

const getAllStates = async () => {
  const endPoint = 'estados/'
  const { data } = await crudService.get(endPoint)
  return data
}

export default function useAllStates() {
  return useQuery<Estado[]>(['states'], () => getAllStates(), {
    cacheTime: Infinity
  })
}
