import React from 'react'
import {
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import {
  useMutationCreateCarrinhoItem,
  useMutationDeleteCarrinhoItem,
  useMutationEditCarrinhoItem
} from 'hooks-querys'
import { toast } from 'react-toastify'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '_actions'

const RowCarrinhoItem = (props) => {
  const { carrinhoItem } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const { openModalLogin, token } = useSelector((state) => state.authentication)
  const mobileDevice = useMediaQuery(theme.breakpoints.down('xs'))
  const mutationCreateCarrinhoItem = useMutationCreateCarrinhoItem(carrinhoItem)
  const mutationEditCarrinhoItem = useMutationEditCarrinhoItem(carrinhoItem)
  const mutationDeleteCarrinhoItem = useMutationDeleteCarrinhoItem(carrinhoItem)
  const isLoading =
    mutationCreateCarrinhoItem.isLoading ||
    mutationEditCarrinhoItem.isLoading ||
    mutationDeleteCarrinhoItem.isLoading

  const handlePressAddQuantity = () => {
    if (token === null || token === undefined || token === '') {
      dispatch(authActions.openModalLogin())
      return
    }
    if (
      carrinhoItem &&
      carrinhoItem.lote &&
      carrinhoItem.quantidade >= carrinhoItem.lote.quantidadeDisponivel
    ) {
      toast.error(`Opss! Quantidade indisponível Restam apenas
      ${pluralize('unidades', carrinhoItem.lote.quantidadeDisponivel, true)}`)
      return
    }
    if ((carrinhoItem && carrinhoItem.lote.limiteQuantidadePedido &&
      carrinhoItem.quantidade ===
        carrinhoItem.lote.limiteQuantidadePedido) ||
    (!carrinhoItem.lote.limiteQuantidadePedido &&
      carrinhoItem.quantidade === 10)){
      toast.error(`Opss! Ingresso limitado a ${pluralize(
        'quantidade',
        carrinhoItem.lote.limiteQuantidadePedido || 10,
        true
      )} por usuário!`)
      return
    }
    mutationCreateCarrinhoItem.mutate({
      id: carrinhoItem.id,
      usuarioId: carrinhoItem.usuarioId,
      loteId: carrinhoItem.loteId,
      pedidoId: null,
      excluido: null,
      quantidade: 1
    })
  }
  const handlePressDecreaseQuantity = () => {
    if (carrinhoItem.quantidade <= 1) {
      mutationDeleteCarrinhoItem.mutate()
      return
    }
    mutationEditCarrinhoItem.mutate({
      ...carrinhoItem,
      quantidade: carrinhoItem.quantidade - 1
    })
  }
  return (
    <>
      {carrinhoItem.lote.quantidadeDisponivel > 0 ||
      carrinhoItem.quantidade > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: mobileDevice ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            onClick={handlePressDecreaseQuantity}
            color="primary"
            aria-label="diminui quantidade"
            disabled={carrinhoItem.quantidade <= 0 || isLoading}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
          {isLoading ? (
            <div
              style={{
                width: '45px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <TextField
              id="quantidade"
              variant="outlined"
              disabled={true}
              type="number"
              inputProps={{
                min: '1',
                max: '10'
              }}
              size="small"
              style={{ width: '45px' }}
              value={carrinhoItem.quantidade}
            />
          )}
          <IconButton
            onClick={handlePressAddQuantity}
            color="primary"
            aria-label="aumenta quantidade"
            disabled={isLoading || carrinhoItem.lote.quantidadeDisponivel <= 0}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      ) : (
        <Typography align="center" variant="body2" color="disabled">
          Esgotado
        </Typography>
      )}
    </>
  )
}

export default RowCarrinhoItem
