import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { useIsMutating } from 'react-query'
import { isFuture, isValid } from 'date-fns'
import Cards from 'react-credit-cards'
import {
  TextValidator,
  ValidatorForm,
  SelectValidator
} from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

//? Own imports
import {
  useAddressByZipCode,
  useAllStates,
  useCitiesByState,
  useCityByIbge,
  useMutationCreateCreditCard,
  useMutationGenerateCreditCardToken
} from 'hooks-querys'
import {
  cepMask,
  creditCardExpiresMask,
  userHasCompleteAddress,
  validateUserNecessaryData
} from 'utils'
import getCreditCardFlag from 'utils/getCreditCardFlag'
import { orderPaymentActions } from '_actions'
import SelectInputInstallments from '../SelectInputInstallments'

const NewCreditCard = () => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  // @ts-ignore
  const { user } = useSelector((state) => state.authentication)
  const {
    installmentsCreditCard
    //@ts-ignore
  } = useSelector((state) => state.orderPayment)
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<
    null | 'new' | 'user'
  >(null)
  const [billingAddressIsError, setBillingAddressIsError] = useState(false)
  const [formBillingAddress, setFormBillingAddress] = useState({
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidadeId: 0,
    estadoId: 0
  })

  const isMutatingCreateOrder = useIsMutating({
    mutationKey: 'mutationCreateOrder',
    exact: true
  })
  const { mutateAsync, isLoading: isLoadingCreateCreditCard } =
    useMutationCreateCreditCard()
  const [creditCardData, setCreditCardData] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: ''
  })
  const [isValidCreditCard, setIsValidCreditCard] = useState(false)
  const [creditCardType, setCreditCardType] = useState({
    issuer: '',
    maxLength: 16
  })
  const addressByZipCode = useAddressByZipCode(
    formBillingAddress.cep,
    formBillingAddress &&
      formBillingAddress.cep &&
      formBillingAddress.cep.length === 9
      ? true
      : false
  )

  useEffect(() => {
    if (addressByZipCode && addressByZipCode.data) {
      setFormBillingAddress({
        ...formBillingAddress,
        endereco: addressByZipCode.data.logradouro,
        bairro: addressByZipCode.data.bairro,
        complemento: addressByZipCode.data.complemento
      })
    }
  }, [addressByZipCode.data])

  const cityByIbge = useCityByIbge(
    Number(addressByZipCode.data?.ibge),
    addressByZipCode.data?.ibge ? true : false
  )
  useEffect(() => {
    if (cityByIbge.data && cityByIbge.data.id > 0) {
      setFormBillingAddress({
        ...formBillingAddress,
        cidadeId: cityByIbge.data.id,
        estadoId: cityByIbge.data.estadoId
      })
    }
  }, [cityByIbge.data])

  const queryAllStates = useAllStates()
  const queryCitiesByState = useCitiesByState(formBillingAddress.estadoId)
  const mutationGenerateCreditCardToken = useMutationGenerateCreditCardToken()

  const handleInputFocus = (e: any) => {
    setCreditCardData({ ...creditCardData, focus: e.target.name })
  }

  const handleInputChangeBillingAddress = (e: any) => {
    setFormBillingAddress({
      ...formBillingAddress,
      [e.target.name]: e.target.value
    })
  }

  const isValidExpiresDate = (month: number, year: number) => {
    const date = new Date(year, month)
    const isValidDate = isValid(date)
    if (isValidDate) {
      return isFuture(date)
    }
    return false
  }
  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    if (name === 'expiry') {
      setCreditCardData({
        ...creditCardData,
        [name]: creditCardExpiresMask(value)
      })
      return
    }
    if (name === 'cvc') {
      if (creditCardType.issuer === 'amex') {
        if (value.length <= 4) {
          setCreditCardData({ ...creditCardData, [name]: value })
        }
      }
      if (value.length <= 3) {
        setCreditCardData({ ...creditCardData, [name]: value })
      }
      return
    }
    setCreditCardData({ ...creditCardData, [name]: value.toUpperCase() })
  }
  const handleCardNumberChange = (e: any) => {
    const { name, value } = e.target
    if (value.length <= creditCardType.maxLength) {
      setCreditCardData({ ...creditCardData, [name]: value })
    }
  }

  const getCityById = (id: number) => {
    const city = queryCitiesByState.data?.find((city) => city.id === id)
    return city ? city : null
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (selectedBillingAddress === null) {
      setBillingAddressIsError(true)
      return
    }
    if (validateUserNecessaryData(dispatch, user)) {
      mutationGenerateCreditCardToken
        .mutateAsync({
          expiration: creditCardData.expiry,
          holderName: creditCardData.name,
          number: creditCardData.number,
          securityCode: creditCardData.cvc,
          holderDocument: user.cpf,
          billingAddress:
            selectedBillingAddress === 'new'
              ? {
                  cep: formBillingAddress.cep.replace(/-/g, ''),
                  endereco: formBillingAddress.endereco,
                  numero: formBillingAddress.numero,
                  complemento: formBillingAddress.complemento,
                  bairro: formBillingAddress.bairro,
                  cidade: getCityById(formBillingAddress.cidadeId)
                }
              : {
                  cep: user.cep.replace(/-/g, ''),
                  endereco: user.endereco,
                  numero: user.numero,
                  complemento: user.complemento,
                  bairro: user.bairro,
                  cidade: user.cidade
                }
        })
        .then((creditCardToken) => {
          confirm({
            title: 'Deseja salvar esse cartão para compras futuras?',
            description:
              'É seguro e facilitará as suas próximas compras. Você pode excluir o cartão a qualquer momento.',
            confirmationText: 'Salvar! 😎',
            cancellationText: 'Não'
          })
            .then(() => {
              mutateAsync({
                creditCardHash: creditCardToken.data.id,
                bandeiraCartaoCreditoNome: getCreditCardFlag(
                  creditCardData.number
                ),
                ...(selectedBillingAddress === 'new'
                  ? {
                      cep: formBillingAddress.cep.replace(/-/g, ''),
                      endereco: formBillingAddress.endereco,
                      numero: formBillingAddress.numero,
                      complemento: formBillingAddress.complemento,
                      bairro: formBillingAddress.bairro,
                      cidadeId: formBillingAddress.cidadeId
                    }
                  : {
                      cep: user.cep.replace(/-/g, ''),
                      endereco: user.endereco,
                      numero: user.numero,
                      complemento: user.complemento,
                      bairro: user.bairro,
                      cidadeId: user.cidadeId
                    })
              }).then((response) => {
                dispatch(orderPaymentActions.setSelectedCreditCard(response))
              })
            })
            .catch(() => {
              dispatch(
                orderPaymentActions.setCreditCardHash({
                  creditCardHash: creditCardToken.data.id,
                  billingAddress: {
                    ...(selectedBillingAddress === 'new'
                      ? {
                          cep: formBillingAddress.cep.replace(/-/g, ''),
                          endereco: formBillingAddress.endereco,
                          numero: formBillingAddress.numero,
                          complemento: formBillingAddress.complemento,
                          bairro: formBillingAddress.bairro,
                          cidadeId: formBillingAddress.cidadeId
                        }
                      : {
                          cep: user.cep.replace(/-/g, ''),
                          endereco: user.endereco,
                          numero: user.numero,
                          complemento: user.complemento,
                          bairro: user.bairro,
                          cidadeId: user.cidade.id
                        })
                  }
                })
              )
            })
        })
        .catch((error) => {
          toast.error(
            'Erro ao processar cartão de crédito. Verifique os dados e tente novamente.'
          )
        })
    }
  }

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidExpireDate', (value) => {
      if (
        value === '' ||
        value === null ||
        value === undefined ||
        value.length < 5
      ) {
        return false
      } else {
        const values = value.split('/')
        return isValidExpiresDate(values[0], Number(`20${values[1]}`))
      }
    })
    ValidatorForm.addValidationRule('isValidSecurityCode', (value) => {
      if (
        value === '' ||
        value === null ||
        value === undefined ||
        value.length < 3
      ) {
        return false
      }
      return true
    })
    ValidatorForm.addValidationRule('isValidCep', (value) => {
      if (value.length === 9) {
        return true
      } else {
        return false
      }
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidExpireDate')
      ValidatorForm.removeValidationRule('isValidSecurityCode')
      ValidatorForm.removeValidationRule('isValidCep')
      dispatch(orderPaymentActions.setCreditCardHash(''))
    }
  }, [])

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidCardNumber', (value) => {
      return isValidCreditCard
    })
    return () => {
      ValidatorForm.removeValidationRule('isValidCardNumber')
    }
  }, [isValidCreditCard])

  const formattedUserAddress = `${user?.endereco}, ${user?.numero} - ${
    user?.bairro
  } - ${user?.cidade?.nome}/${user?.cidade?.estado.uf} - ${cepMask(
    user?.cep || ''
  )}`

  return (
    <div id="CreditCard">
      <Box mt={2}>
        <Cards
          locale={{
            valid: 'Validade'
          }}
          placeholders={{
            name: 'Nome do titular'
          }}
          cvc={creditCardData.cvc}
          expiry={creditCardData.expiry}
          name={creditCardData.name}
          number={creditCardData.number}
          callback={(type, isValid) => {
            setCreditCardType(type)
            setIsValidCreditCard(isValid)
          }}
        />
      </Box>
      <ValidatorForm
        id="formCreditCard"
        onSubmit={handleSubmit}
        debounceTime={400}
      >
        <Box mt={3} mb={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextValidator
                id="cardNumber"
                name="number"
                type="number"
                label="Número do cartão"
                placeholder="Número do cartão"
                value={creditCardData.number}
                onChange={handleCardNumberChange}
                onFocus={handleInputFocus}
                validators={['required', 'isValidCardNumber']}
                errorMessages={['Campo obrigatório', 'Número inválido']}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                id="cardName"
                name="name"
                type="text"
                label="Nome do titular"
                placeholder="Nome do titular"
                value={creditCardData.name}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                errorMessages={['Campo obrigatório!']}
                validators={['required']}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                id="cardExpiry"
                type="tel"
                name="expiry"
                label="Validade"
                placeholder="MM/AA"
                value={creditCardData.expiry}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                validators={['required', 'isValidExpireDate']}
                errorMessages={['Campo obrigatório!', 'Data inválida']}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                id="cardCVC"
                type="number"
                name="cvc"
                label="CVC"
                placeholder="CVC"
                value={creditCardData.cvc}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                validators={['required', 'isValidSecurityCode']}
                errorMessages={[
                  'Campo obrigatório!',
                  'Codigo de segurança inválido'
                ]}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <Box mt={3}>
                <SelectInputInstallments />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <FormControl
          fullWidth
          component="fieldset"
          error={billingAddressIsError && selectedBillingAddress === null}
        >
          <FormLabel
            style={{ marginBottom: 20, textAlign: 'left' }}
            component="legend"
          >
            Selecione o endereço de cobrança
          </FormLabel>
          <RadioGroup
            aria-label="billing-address"
            name="billing-address"
            value={selectedBillingAddress}
          >
            {userHasCompleteAddress(user) && (
              <FormControlLabel
                onChange={() => setSelectedBillingAddress('user')}
                value="user"
                control={<Radio />}
                label={
                  <Typography align="left">{formattedUserAddress}</Typography>
                }
              />
            )}
            <FormControlLabel
              style={{ marginTop: 10 }}
              onChange={() => setSelectedBillingAddress('new')}
              value="new"
              control={<Radio />}
              label={
                <Typography align="left">
                  Inserir um novo endereco de cobrança
                </Typography>
              }
            />
          </RadioGroup>
          {billingAddressIsError && selectedBillingAddress === null && (
            <FormHelperText>
              O endereco de cobrança é obrigatório
            </FormHelperText>
          )}
        </FormControl>
        {selectedBillingAddress === 'new' && (
          <Box mt={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  fullWidth
                  id="cep"
                  label="CEP *"
                  name="cep"
                  type="text"
                  placeholder="CEP"
                  inputProps={{
                    maxlength: '9',
                    minlength: '9'
                  }}
                  value={formBillingAddress.cep}
                  onChange={(e: any) =>
                    setFormBillingAddress({
                      ...formBillingAddress,
                      cep: cepMask(e.target.value)
                    })
                  }
                  autoComplete="cep"
                  validators={['required', 'isValidCep']}
                  errorMessages={['Campo obrigatório!', 'O CEP não é válido']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectValidator
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  disabled={queryAllStates.isLoading}
                  id="estadoId"
                  label="Estado *"
                  name="estadoId"
                  value={formBillingAddress.estadoId}
                  onChange={(e: any) => {
                    setFormBillingAddress({
                      ...formBillingAddress,
                      estadoId: e.target.value
                    })
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="estadoId"
                >
                  <MenuItem disabled value="">
                    <em>Estado *</em>
                  </MenuItem>
                  {queryAllStates &&
                    queryAllStates.data &&
                    queryAllStates.data.length > 0 &&
                    queryAllStates.data?.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.nome}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectValidator
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                  id="cidadeId"
                  name="cidadeId"
                  label="Cidade *"
                  value={formBillingAddress.cidadeId}
                  onChange={(e: any) => {
                    setFormBillingAddress({
                      ...formBillingAddress,
                      cidadeId: e.target.value
                    })
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  displayEmpty
                  labelId="cidadeId"
                >
                  <MenuItem disabled value="">
                    <em>Cidade *</em>
                  </MenuItem>
                  {queryCitiesByState &&
                    queryCitiesByState.data &&
                    queryCitiesByState.data.length > 0 &&
                    queryCitiesByState.data?.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.nome}
                      </MenuItem>
                    ))}
                </SelectValidator>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  fullWidth
                  id="endereco"
                  label="Endereço *"
                  name="endereco"
                  type="text"
                  placeholder="Rua, Avenida, Alameda, etc."
                  value={formBillingAddress.endereco}
                  onChange={handleInputChangeBillingAddress}
                  autoComplete="endereco"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  fullWidth
                  id="numero"
                  label="Numero *"
                  name="numero"
                  inputProps={{
                    maxlength: '10',
                    minlength: '1'
                  }}
                  value={formBillingAddress.numero}
                  onChange={handleInputChangeBillingAddress}
                  autoComplete="numero"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  fullWidth
                  id="bairro"
                  label="Bairro *"
                  name="bairro"
                  value={formBillingAddress.bairro}
                  onChange={handleInputChangeBillingAddress}
                  autoComplete="bairro"
                  validators={['required']}
                  errorMessages={['Campo obrigatório!']}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextValidator
                  fullWidth
                  id="complemento"
                  label="Complemento"
                  name="complemento"
                  placeholder="Apto, bloco, sala, etc."
                  value={formBillingAddress.complemento}
                  onChange={handleInputChangeBillingAddress}
                  autoComplete="complemento"
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={2}>
          <Grid item xs={12}>
            <Button
              disabled={
                mutationGenerateCreditCardToken.isLoading ||
                isLoadingCreateCreditCard ||
                isMutatingCreateOrder > 0
              }
              color="primary"
              type="submit"
              variant="contained"
              fullWidth
            >
              {mutationGenerateCreditCardToken.isLoading &&
                'Criptografando cartão...'}
              {isLoadingCreateCreditCard && 'Salvando cartão...'}
              {isMutatingCreateOrder > 0 && 'Gerando pedido...'}
              {!mutationGenerateCreditCardToken.isLoading &&
              !isLoadingCreateCreditCard &&
              isMutatingCreateOrder == 0 &&
              installmentsCreditCard &&
              installmentsCreditCard?.parcelas &&
              installmentsCreditCard?.parcelas > 1
                ? `Pagar com cartão em ${installmentsCreditCard?.parcelas}x`
                : !mutationGenerateCreditCardToken.isLoading &&
                  !isLoadingCreateCreditCard &&
                  isMutatingCreateOrder == 0 &&
                  'Pagar com cartão'}
              {(isLoadingCreateCreditCard ||
                mutationGenerateCreditCardToken.isLoading ||
                isMutatingCreateOrder > 0) && (
                <CircularProgress size={20} style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Grid>
        </Box>
      </ValidatorForm>
    </div>
  )
}

export default NewCreditCard
