import { isValid } from 'date-fns'

export default function toISODateFormat(dateTimeString) {
  if (dateTimeString !== null && dateTimeString !== undefined) {
    if (isValid(new Date(dateTimeString))) {
      return dateTimeString
    } else {
      const [date, time] = dateTimeString.split(' ')
      const [DD, MM, YYYY] = date.split('/')
      const [HH, mm] = time.split(':')
      return `${YYYY}-${MM}-${DD}T${HH}:${mm}`
    }
  }
}
