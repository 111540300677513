import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = (props) =>
  makeStyles((theme) => ({
    div: {
      backgroundImage: `url("${props.src}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      [theme.breakpoints.up('sm')]: {
        height: '55%',
        maxHeight: 400
      },
      [theme.breakpoints.down('xs')]: {
        height: '30%'
      },
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      pointerEvents: 'none',
      userSelect: 'none'
    }
  }))

export default function BackgroundImage({ children, ...props }) {
  const { div } = useStyles(props)()
  return <div className={div}>{children}</div>
}
