export default function getCreditCardFlag(cardNumber: string) {
  const cardNumberFormatted = cardNumber.replace(/[^0-9]+/g, '')

  const cards: RegExp[] = {
    // @ts-ignore
    VISA: /^4[0-9]{15}$/,
    MASTERCARD:
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    DINERS: /'(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
    AMEX: /^3[47][0-9]{13}$/,
    DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{12}/,
    HIPERCARD: /^606282|^3841(?:[0|4|6]{1})0/,
    ELO: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}/,
    AURA: /^((?!504175))^((?!5067))(^50[0-9])/
  }

  for (const flag in cards) {
    if (cards[flag].test(cardNumberFormatted)) {
      return flag
    }
  }

  return ''
}
