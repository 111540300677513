import { useMemo } from 'react'
import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services/'
import { PlanValueParams, PlanValueReturn, UseQueryOptions } from './member'

const getPlanValue = async (
  params?: PlanValueParams
): Promise<PlanValueReturn> => {
  try {
    const { data } = await crudService.get(`planosValor/${params?.planValueId}`)
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function usePlanValue(
  params?: PlanValueParams,
  options?: UseQueryOptions<PlanValueReturn>
) {
  const initialData = useMemo(
    () => params?.planValueDetails,
    [params?.planValueDetails]
  )
  return useQuery<PlanValueReturn>(
    ['planValue', params?.planValueId],
    () => getPlanValue(params),
    {
      initialData,
      cacheTime: Infinity,
      ...options?.config
    }
  )
}
