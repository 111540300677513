import { connect, useDispatch } from 'react-redux'
import { ingressoAction, authActions, checkoutActions } from '../_actions'
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { withRouter } from 'react-router-dom'
import { history } from '../_helpers'
import clsx from 'clsx'
import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Container } from '@material-ui/core'
import Footer from './footer2'

const drawerWidth = 0

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  imagem: {
    width: '100%',
    display: 'block',
    maxWidth: '500px'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  'appBar-left': {
    marginLeft: drawerWidth
  },
  'appBar-right': {
    marginRight: drawerWidth
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  icon: {
    padding: theme.spacing(3)
  },
  paddingDown: {
    padding: theme.spacing(0, 0, 3, 0)
  },
  paddingUp: {
    padding: theme.spacing(4, 0, 0, 0)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  card: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardImage: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  text: {
    textAlign: 'justify'
  }
})

const PrivacyPolicy = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="md">
            <Grid container spacing={24}>
              <Grid item xs={6} container justify="flex-start">
                <Typography className={classes.paddingUp} variant="h6">
                  <strong>POLÍTICA DE PRIVACIDADE</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <br />
            <br />
            <Grid container paper spacing={24}>
              <Typography className={classes.text} variant="body1">
              <h1>POLÍTICA DE PRIVACIDADE</h1>
                <br />
                <br/>
    <h2>1 - INFORMAÇÕES GERAIS</h2>
    <br/>
    <p>No Corujas, privacidade e segurança são prioridades e nos comprometemos com a
        transparência do tratamento de dados pessoais dos nossos usuários/clientes. Dessa forma,
        esta presente Política de Privacidade estabelece como é feita a coleta, uso e transferência
        de informações de clientes ou outras pessoas que acessam ou usam nosso site ou aplicativo.</p>
<br/>
    <h2>2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE?</h2>
    <br/>
    <p>Os dados pessoais do usuário e visitante são recolhidos pelo Corujas da seguinte forma:</p>
    <ul>
        <li>Quando o usuário cria uma conta/perfil no Corujas: esses dados são os dados de
            identificação básicos, como nome completo, e-mail e telefone. A partir deles,
            podemos identificar o usuário, além de garantir uma maior segurança e bem-estar às
            suas necessidades. Ficam cientes os usuários e visitantes de que seu perfil no
            Corujas estará acessível a todos demais usuários e visitantes, a menos que ele
            torne seu perfil privado.</li>
        <li>Quando um usuário navega pelo site ou pelo aplicativo: as informações sobre
            interação e acesso são coletadas pela empresa para garantir uma melhor
            experiência ao usuário e visitante. Estes dados podem tratar sobre as
            palavras-chaves utilizadas em uma busca, comentários, visualizações de páginas,
            perfis, a URL de onde o usuário e visitante provêm, o navegador que utilizam e seus
            IPs de acesso, dentre outras que poderão ser armazenadas e retidas.</li>
        <li>Por intermédio de terceiro: o Corujas recebe dados de terceiros, como, por exemplo,
            Facebook e Google, quando um usuário faz login com o seu perfil de um desses
            sites. A utilização desses dados é autorizada previamente pelos usuários junto ao
            terceiro em questão.</li>
    </ul>
    <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de
        Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os
        direitos e obrigações aqui previstos permanecem aplicáveis.</p>
<br/>
    <h2>3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE?</h2>
    <br/>
    <p>Os dados pessoais do usuário e visitante recolhidos são os seguintes:</p>
    <ul>
        <li>Dados para a criação da conta/perfil no Corujas: e-mail, nome completo, endereço,
            data de nascimento e telefone.</li>
        <li>Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas
            na busca, recomendações, comentários, interação com outros perfis e usuários,
            perfis seguidos.</li>
        <li>Dados para concretizar transações: dados referentes ao pagamento e transações,
            tais como, CPF, número do cartão de crédito e outras informações sobre o cartão,
            além dos pagamentos efetuados. Dados de cartão de débito e crédito são
            armazenados apenas com o consentimento do usuário, se o mesmo optar por ter
            esta forma de pagamento cadastrada para futuras compras.</li>
        <li>Newsletter: o e-mail cadastrado pelo visitante que optar por se inscrever na
            Newsletter será coletado e armazenado até que o usuário solicite o descadastro.</li>
    </ul>
    <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de
        Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os
        direitos e obrigações aqui previstos permanecem aplicáveis.</p>
<br/>
    <h2>4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS?</h2>
    <br/>
    <p>Os dados pessoais do usuário e visitante são armazenados pelo Corujas durante o período
        necessário para a prestação do serviço ou o cumprimento das finalidades previstas no
        presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18.</p>
    <p>Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos
        em que a lei oferecer outro tratamento.</p>
    <p>Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de
        seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:</p>
    <ul>
        <li>I - cumprimento de obrigação legal ou regulatória pelo controlador;</li>
        <li>II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos
            dados pessoais;</li>
        <li>III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados
            dispostos nesta Lei;</li>
        <li>IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
            anonimizados os dados.</li>
    </ul>
<br/>
    <h2>5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h2>
    <br/>
    <p>O Corujas se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os
        dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração,
        comunicação ou difusão de tais dados.</p>
    <p>Qualquer dado sensível, como dados referente a cartão de crédito, é transmitido de forma
        encriptada, garantindo sua transmissão de forma segura e confidencial.</p>
    <p>O Corujas se compromete a comunicar o usuário em caso de alguma violação de
        segurança dos seus dados pessoais.</p>
    <p>Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites
        legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados
        pela lei para fazê-lo ou se você violar nossos Termos de Serviço.</p>
<br/>
    <h2>6 - COMPARTILHAMENTO DOS DADOS</h2>
    <br/>
    <p>O compartilhamento de dados do usuário ocorre apenas com os dados referentes a
        publicações realizadas pelo próprio usuário, tais ações são compartilhadas publicamente
        com os outros usuários.</p>
    <p>Os dados do perfil do usuário são compartilhados publicamente em sistemas de busca e
        dentro do Corujas, sendo permitido ao usuário modificar tal configuração para que seu perfil
        não apareça nos resultados de busca de tais ferramentas, tornando-o privado.</p>
<br/>
    <h2>7 - COOKIES OU DADOS DE NAVEGAÇÃO</h2>
    <br/>
    <p>Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do
        usuário e visitante e que nele ficam armazenados, com informações relacionadas à
        navegação no site. Tais informações são relacionadas aos dados de acesso como local e
        horário de acesso e são armazenadas pelo navegador do usuário e visitante para que o
        servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da
        plataforma.</p>
    <p>O usuário e o visitante do Corujas manifesta conhecer e aceitar que pode ser utilizado um
        sistema de coleta de dados de navegação mediante à utilização de cookies.</p>
    <p>O cookie persistente permanece no disco rígido do usuário e visitante depois que o
        navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os
        cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o
        cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível
        redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da
        plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver
        desabilitada.</p>
<br/>
    <h2>8 - CONSENTIMENTO</h2>
    <br/>
    <p>Ao utilizar os serviços e fornecer as informações pessoais no site ou aplicativo Corujas, o
        usuário está consentindo com a presente Política de Privacidade.</p>
    <p>O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar
        seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das
        informações por ele disponibilizadas.</p>
    <p>O usuário tem direito de retirar o seu consentimento a qualquer tempo, bem como, solicitar
        que seus dados sejam permanentemente excluídos. Para isso, deve entrar em contato
        através do e-mail suporte@corujasapp.com.</p>
<br/>
    <h2>9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h2>
    <br/>
    <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então,
        é recomendável que o usuário e visitante revise-a com frequência.</p>
    <p>As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na
        plataforma. Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço
        ou fornecer informações pessoais após eventuais modificações, o usuário e visitante
        demonstra sua concordância com as novas normas.</p>
    <p>Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser
        transferidos para os novos proprietários para a permanência dos serviços oferecidos.</p>
<br/>
    <h2>10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</h2>
                <br />

    <p>Para a solução de controvérsias decorrentes do presente instrumento será aplicado
        integralmente o Direito brasileiro.</p>
    <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a
        sede da empresa.</p>
<br/>
                <h2>11 - COLETA DE IMAGEM DO USUÁRIO</h2>
                  <br />
                <p>Em alguns casos, coletamos imagens dos usuários com o objetivo de melhorar a experiência do usuário e personalizar o perfil. A única imagem coletada é a foto de perfil, e isso ocorre somente quando o usuário realiza o upload da foto.</p>
<p>A coleta de imagens é realizada em conformidade com os termos e condições desta Política de Privacidade. Os usuários podem, a qualquer momento, solicitar a exclusão ou a modificação das imagens coletadas entrando em contato conosco através do e-mail suporte@corujasapp.com.</p>
                <br />
                Para qualquer sugestão ou colaboração, entre em contanto conosco
                através do email contato@corujatickets.com.
              </Typography>
            </Grid>
            <br />
            <br />
            <Grid className={classes.paddingDown} container spacing={24}></Grid>
          </Container>
        </main>
      </div>
    </div>
  )
}
PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    state
  }
}
const connectedPrivacyPolicyPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PrivacyPolicy))
)
export { connectedPrivacyPolicyPage as PrivacyPolicy }
