import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core'

//? Own imports
import { useStyles } from './styles'
import EventoLocationContent from 'views/eventos/EventoLocationContent'
import { Evento } from 'types/api'

type CardEventLocationProps = {
  eventDetails: Evento
}

const CardEventLocation = ({ eventDetails }: CardEventLocationProps) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h5" align="left" className={classes.textHeader}>
            Localização
          </Typography>
        }
      />
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <EventoLocationContent evento={eventDetails} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CardEventLocation
