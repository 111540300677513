export default function toLocalDateFormat(isoDateTime) {
  if (isoDateTime !== null && isoDateTime !== undefined) {
    var datePart = isoDateTime.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2]

    return day + '/' + month + '/' + year
  } else {
    return null
  }
}
