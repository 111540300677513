import { PurchaseApplicationFormItem } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'

export type SetPurchaseApplicationFormAction = {
  type: 'SET_PURCHASE_APPLICATION_FORM_STORE'
  payload: {
    purchaseApplicationFormItens?: PurchaseApplicationFormItem[] | undefined
  }
}

export const setPurchaseApplicationFormStore = (
  purchaseApplicationFormItens: PurchaseApplicationFormItem[] | undefined
): SetPurchaseApplicationFormAction => {
  return {
    type: 'SET_PURCHASE_APPLICATION_FORM_STORE',
    payload: {
      purchaseApplicationFormItens
    }
  }
}

export type SetActiveStepAction = {
  type: 'SET_ACTIVE_STEP'
  payload: {
    activeStep: number
  }
}

export const setActiveStep = (activeStep: number): SetActiveStepAction => {
  return {
    type: 'SET_ACTIVE_STEP',
    payload: {
      activeStep
    }
  }
}
