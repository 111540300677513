import { crudService } from '_services'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const putCarrinhoItem = async (carrinhoItem) => {
  const { data } = await crudService.put(
    `carrinhoItens/${carrinhoItem.id}`,
    carrinhoItem
  )
  return data
}

export default function useMutationEditCarrinhoItem(
  carrinhoItem
) {
  const queryClient = useQueryClient()
  return useMutation(
    'mutationEditCarrinhoItem',
    (carrinhoItemPayload) => putCarrinhoItem(carrinhoItemPayload),
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          'carrinhoItens',
          carrinhoItem.lote.eventoId
        ])
      },
      onSuccess: () => {
        queryClient.setQueryData(
          ['carrinhoItens', carrinhoItem.lote.eventoId],
          (oldDate) => {
            const newCarrinhoItens = []
            oldDate.map((item) => {
              item.carrinhoItem.loteId === carrinhoItem.loteId
                ? newCarrinhoItens.push({
                  ...item,
                  carrinhoItem: {
                    ...item.carrinhoItem,
                    quantidade: item.carrinhoItem.quantidade - 1
                  }
                })
                : newCarrinhoItens.push(item)
            })

            return newCarrinhoItens
          }
        )
        queryClient.invalidateQueries([
          'carrinhoItens',
          carrinhoItem.lote.eventoId
        ])
      },
      onError: () => {
        toast.error('Opss! Não conseguimos mudar a quantidade do ingresso, tente novamente.')
      }
    }
  )
}
