import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Container } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import 'moment-timezone'
import { SearchInput } from '../../components'
import { SearchResult } from './searchResult.component'
import { searchActions } from '../../_actions/search.actions'

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  rootSearch: {
    margin: theme.spacing(0, 0, 5, 0),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold'
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
    // padding: theme.spacing(3),
  },
  cardGrid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8)
  }
})

const Search = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
    var url_string = window.location.href
    var url = new URL(url_string)
    var categoriaId = url.searchParams.get('catId')
    let payload = {
      Texto: '',
      Categorias: [categoriaId]
    }
    categoriaId && dispatch(searchActions.searchEventos(payload))
  }, [])

  useEffect(() => {
    // antes de desmotar o componente
    return () => {
      dispatch(searchActions.clearAllParams())
    }
  }, [])

  const { classes } = props
  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.content}>
          <Container className={classes.cardGrid}>
            <div className={classes.rootSearch}>
              <SearchInput></SearchInput>
            </div>
            <SearchResult></SearchResult>
          </Container>
        </main>
      </div>
    </div>
  )
}
Search.propTypes = {
  classes: PropTypes.object.isRequired
}

const connectedSearchPage = withRouter(
  connect(null, null, null, {
    pure: false
  })(withStyles(styles)(Search))
)
export { connectedSearchPage as Search }
