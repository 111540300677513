import React, { useEffect } from 'react'
import {
  makeStyles,
  Container,
  Chip,
  Typography,
  Link
} from '@material-ui/core'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import { connect, useDispatch } from 'react-redux'
import { searchActions } from '../../_actions/search.actions'
import { isEmpty } from 'lodash'
import { categoriaAction } from '../../_actions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  limpar: {
    padding: theme.spacing(1, 0, 0, 0),
    alignItems: 'end'
  }
}))

const CategoriasChips = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedItems = props.search.categorias
  const { categoria } = props.categorias

  useEffect(() => {
    dispatch(categoriaAction.getCategoria())
  }, [])

  const handleSelectItem = (id) => {
    const alreadySelected = selectedItems.findIndex((item) => item === id)
    if (alreadySelected >= 0) {
      const filteredItems = selectedItems.filter((item) => item !== id)
      dispatch(
        searchActions.onChangeCategoria(filteredItems, props.search.texto)
      )
    } else {
      dispatch(
        searchActions.onChangeCategoria(
          [...selectedItems, id],
          props.search.texto
        )
      )
    }
  }

  const clearCategories = () => {
    dispatch(searchActions.clearAllCategorias(props.search.texto))
  }

  return (
    <Container className={classes.root}>
      {!isEmpty(categoria) &&
        categoria.map((item) => {
          let icon
          if (selectedItems.includes(item.id)) {
            icon = <SentimentVerySatisfiedIcon fontSize="small" />
          } else {
            icon = <SentimentDissatisfiedIcon fontSize="small" />
          }

          return (
            <li key={item.id} id={item.id}>
              <Chip
                disabled={props.searching}
                color={selectedItems.includes(item.id) ? 'primary' : 'default'}
                // variant={selectedItems.includes(item.id) ? 'outlined' : 'default'}
                clickable
                onClick={() => handleSelectItem(item.id)}
                icon={icon}
                size="small"
                label={item.descricao}
                className={classes.chip}
              />
            </li>
          )
        })}
      {selectedItems.length > 0 && (
        <Link
          component="button"
          variant="caption"
          className={classes.limpar}
          onClick={() => clearCategories()}
        >
          Limpar
        </Link>
      )}
    </Container>
  )
}
const mapStateToProps = (state) => {
  return {
    categorias: state.categoria,
    search: state.search,
    searching: state.search.searching
  }
}
export default connect(mapStateToProps)(CategoriasChips)
