import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import { useParams } from 'react-router'
import Lottie from 'react-lottie'
import { useIsMutating } from 'react-query'

//? Own imports
import { NewCreditCard, UserCreditCardList } from './components'
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useMutationCreateOrder,
  useMutationCreditCardPayment,
  useOrder
} from 'hooks-querys'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import { validateUserNecessaryData } from 'utils'
import { useDidMountEffect } from 'components/Utils'
import { extractRespostasCamposInscricao } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'
import {
  extractCartItems,
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const CreditCard = ({
  eventoId,
  isCheckIn
}: {
  eventoId: number
  isCheckIn: boolean
}) => {
  const { pedidoId } = useParams<{ pedidoId: string }>()
  const dispatch = useDispatch()
  //@ts-ignore
  const { user } = useSelector((state) => state.authentication)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const {
    selectedCreditCard,
    paymentMethodId,
    creditCardHash,
    billingAddress,
    installmentsCreditCard
    //@ts-ignore
  } = useSelector((state) => state.orderPayment)

  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.purchaseApplicationFormItens
  )

  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens,
    refetch
  } = useCarrinhoItensEvento(Number(eventoId))
  const filteredCartItems = filterValidCartItems(data || [])
  const carrinhoItensWithoutCheckIn = filterItemsWithoutCheckIn(
    filteredCartItems || []
  )
  const carrinhoItensWithCheckIn = filterItemsWithCheckIn(
    filteredCartItems || []
  )
  const carrinhoItens = isCheckIn
    ? carrinhoItensWithCheckIn
    : carrinhoItensWithoutCheckIn

  const { data: discountCoupon, isSuccess: discountCouponSuccess } =
    useDiscountCoupon('', undefined, false)
  const totalValue = calculaValorAPagar(
    carrinhoItens || [],
    discountCoupon?.carrinhoItens || [],
    discountCoupon?.cupomDesconto
  )
  const mutationCreateOrder = useMutationCreateOrder({
    textoCupom:
      discountCoupon && discountCoupon?.cupomDesconto
        ? discountCoupon?.cupomDesconto.codigo
        : '',
    carrinhoItens: extractCartItems(carrinhoItens),
    formaPagamentoId: paymentMethodId,
    parcelas: installmentsCreditCard?.parcelas,
    respostasCamposInscricao: extractRespostasCamposInscricao(
      purchaseApplicationFormItens
    )
  })
  const mutationCreditCardPayment = useMutationCreditCardPayment()
  const handleSubmit = () => {
    if (validateUserNecessaryData(dispatch, user)) {
      if (pedidoId) {
        mutationCreditCardPayment.mutate({
          pedido: order.data,
          cartaoCredito: selectedCreditCard
        })
      } else {
        if (carrinhoItens?.length > 0) {
          mutationCreateOrder.mutateAsync().then((data) => {
            mutationCreditCardPayment.mutate({
              pedido: data.pedido,
              cartaoCredito: selectedCreditCard
            })
          })
        }
      }
    }
  }
  const order = useOrder(Number(pedidoId), undefined)
  const isOrder = order && order.data && order.data.id > 0
  const isMutationCreditCardPayment = useIsMutating({
    mutationKey: 'mutationCreditCardPayment',
    exact: true
  })
  const isLoading =
    mutationCreditCardPayment.isLoading ||
    mutationCreateOrder.isLoading ||
    isMutationCreditCardPayment > 0

  useDidMountEffect(() => {
    if (creditCardHash && creditCardHash.length > 0 && creditCardHash !== '') {
      if (pedidoId) {
        mutationCreditCardPayment.mutate({
          pedido: order.data,
          hashCartaoCreditoUnico: creditCardHash,
          ...(billingAddress !== null && {
            bairro: billingAddress?.bairro,
            cep: billingAddress?.cep.replace(/-/g, ''),
            endereco: billingAddress?.endereco,
            numero: billingAddress?.numero,
            complemento: billingAddress?.complemento,
            cidadeId: billingAddress?.cidadeId
          })
        })
      } else {
        if (carrinhoItens && carrinhoItens?.length > 0) {
          mutationCreateOrder.mutateAsync().then((data) => {
            mutationCreditCardPayment.mutate({
              pedido: data.pedido,
              hashCartaoCreditoUnico: creditCardHash,
              ...(billingAddress !== null && {
                bairro: billingAddress?.bairro,
                cep: billingAddress?.cep.replace(/-/g, ''),
                endereco: billingAddress?.endereco,
                numero: billingAddress?.numero,
                complemento: billingAddress?.complemento,
                cidadeId: billingAddress?.cidadeId
              })
            })
          })
        }
      }
    }
  }, [creditCardHash])

  return (
    <div id="CreditCard">
      {isOrder && (
        <Box mt={2} mb={4}>
          {mutationCreditCardPayment.isLoading ||
          isMutationCreditCardPayment > 0 ? (
            <Box>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: require('assets/animations/creditCardPaymentFetching.json'),
                  rendererSettings: {}
                }}
                width={160}
              />
              <Box mt={2}>
                <Typography variant="h6" color="secondary">
                  Conferindo os dados do pagamento com segurança...
                </Typography>
              </Box>
            </Box>
          ) : (
            !isLoading && (
              <Box
                justifyContent={'center'}
                display="flex"
                alignItems={'center'}
                alignContent="center"
              >
                <Box marginRight={2}>
                  <ErrorRoundedIcon
                    style={{
                      color: mutationCreditCardPayment.isError
                        ? theme.palette.error.dark
                        : theme.palette.warning.dark
                    }}
                    fontSize="large"
                  />
                </Box>
                <Box>
                  <Typography variant="h6">
                    {mutationCreditCardPayment.isError
                      ? 'Não foi possível realizar o pagamento pelo método escolhido. Tente novamente com outro cartão de credito.'
                      : 'Pedido pendente de pagamento.'}
                  </Typography>
                </Box>
              </Box>
            )
          )}
        </Box>
      )}
      {!mutationCreditCardPayment.isLoading &&
        isMutationCreditCardPayment === 0 && (
          <>
            <UserCreditCardList />
            {selectedCreditCard === 0 && <NewCreditCard />}
            {selectedCreditCard && selectedCreditCard.id > 0 ? (
              <Box mt={2}>
                <Button
                  color="primary"
                  onClick={handleSubmit}
                  variant="contained"
                  fullWidth
                  disabled={isLoading}
                >
                  {isLoading
                    ? 'Finalizando pagamento...'
                    : isOrder &&
                      order.data?.parcelas &&
                      order.data?.parcelas > 1
                    ? `Pagar com cartão em ${order.data?.parcelas}x`
                    : installmentsCreditCard &&
                      installmentsCreditCard?.parcelas &&
                      installmentsCreditCard?.parcelas > 1
                    ? `Pagar com cartão em ${installmentsCreditCard?.parcelas}x`
                    : 'Pagar com cartão'}
                  {isLoading && (
                    <CircularProgress style={{ marginLeft: 10 }} size={20} />
                  )}
                </Button>
              </Box>
            ) : null}
          </>
        )}
    </div>
  )
}

export default CreditCard
