import { crudService } from '../_services'
import { history } from '../_helpers'
import { authActions, errorHandlerAction } from '.'
import { toast } from 'react-toastify'

export const usuarioActions = {
  getUsuario,
  getUsuarioById,
  onChangeProps,
  editUsuarioInfo,
  createUsuario,
  deleteUsuarioById,
  editUserDetails,
  initUsuarioDetails,
  onChangeData,
  clear,
  requestPasswordReset,
  passwordReset,
  emailVerification,
  changeIsEditable,
  cancelEditUsuario,
  onChangeEstado,
  openModalUserNecessaryData,
  closeModalUserNecessaryData,
}
function getUsuario() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data))
      })
      .catch((error) => {
        dispatch(errorHandlerAction.errors(error))
      })
  }
}

function createUsuario(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(authActions.login(payload.email, payload.senha))
        toast.success('Usuário cadastrado com sucesso!')
        toast('Use sem moderação! 😎', { autoClose: 6000 })
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(errorHandlerAction.errors(err))
        if(err && err.data) {
          toast.error(err.data)
        } else {
         toast.error('Opss! Algo deu errado 😥')
      }
      })
  }

  function request(payload) {
    return { type: 'CREATE_USUARIO_REQUEST', payload }
  }

  function success(usuario) {
    return {
      type: 'CREATE_USUARIO_SUCCESS',
      usuario: usuario
    }
  }

  function failure(error) {
    return { type: 'CREATE_USUARIO_FAILURE', error }
  }
}

function editUsuarioInfo(id, payload, isUserNecessaryDataToPurchase) {
  return (dispatch) => {
    dispatch(request(id, payload))
    let apiEndpoint = 'usuarios/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        toast.success('Feito! Dados atualizados com sucesso! 😎')
        isUserNecessaryDataToPurchase && toast('Agora você pode finalizar a compra! 😎', { autoClose: 6000 })
        dispatch(success(response.data))
        dispatch(authActions.setAuthDetails({ user: payload }))
        localStorage.setItem('user', JSON.stringify(payload))
        dispatch(closeModalUserNecessaryData())
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(errorHandlerAction.errors(err))
      })
  }

  function request(id, payload) {
    return { type: 'EDIT_USUARIO_INFO_REQUEST', id, payload }
  }

  function success(usuario) {
    return {
      type: 'EDIT_USUARIO_INFO_SUCCESS',
      usuario: usuario
    }
  }

  function failure(error) {
    return { type: 'EDIT_USUARIO_INFO_FAILURE', error }
  }
}

function clear() {
  return {
    type: 'USUARIO_CLEAR'
  }
}
function requestPasswordReset(payload) {
  return (dispatch) => {
    console.log('payload', payload)
    let apiEndpoint = 'usuarios/request-reset-password'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status == 200) {
          //dispatch(createUserInfo());
          toast.success('E-mail de recuperação enviado! 😎')
          history.push('/login')
        } else if (response && response.request.status == 404) {
          toast.warn('Não existe nenhum usuário com este e-mail')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((error) => {
        toast.error(error && error.data ? error.data : 'Erro ao recuperar senha do usuário')
      })
  }
}
function passwordReset(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/reset-password'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status == 200) {
          //dispatch(createUserInfo());
          toast.success('Senha redefinida com sucesso! 😎')
          history.push('/meus-dados')
        } else if (response && response.request.status == 404) {
          toast.success('Não existe nehumum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        toast.error('Erro ao recuperar senha do usuário')
      })
  }
}

function emailVerification(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/email-verification'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status == 200) {
          //dispatch(createUserInfo());
          toast.success('E-mail verificado com sucesso! 😎')
          history.push('/meus-dados')
        } else if (response && response.request.status == 404) {
          toast.success('Não existe nehumum usuário com o e-mail informado!')
        } else {
          toast.error('Erro ao verificar e-mail do usuário')
        }
      })
      .catch((err) => {
        toast.error('Erro ao verificar e-mail do usuário')
      })
  }
}

function getUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(editUserDetails(response.data.usuario))
      })
      .catch((error) => {
        dispatch(errorHandlerAction.errors(error))
      })
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      if (props === 'email') {
        dispatch(handleOnChangeProps(props, event.target.value.toLowerCase()))
      } else {
        dispatch(handleOnChangeProps(props, event.target.value))
      }
    }
  }
}
function onChangeData(newValue) {
  return (dispatch) => {
    dispatch(handleOnChangeProps('dataNascimento', newValue))
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(deleteUsuariosDetails())
        dispatch(usuarioActions.getUsuario())
      })
      .catch((error) => {
        dispatch(errorHandlerAction.errors(error))
      })
  }
}
function initUsuarioDetails(usuario) {
  return (dispatch) => {
    dispatch(editUserDetails(usuario))
  }
}
function cancelEditUsuario(usuario) {
  return (dispatch) => {
    dispatch(changeIsEditable())
    dispatch(closeModalUserNecessaryData())
    dispatch(initUsuarioDetails(usuario))
  }
}
export function changeUsuariosList(usuario) {
  return {
    type: 'FETCHED_ALL_USUARIO',
    usuario: usuario
  }
}
function openModalUserNecessaryData() {
  return {
    type: 'OPEN_MODAL_USER_NECESSARY_DATA'
  }
}
function closeModalUserNecessaryData() {
  return {
    type: 'CLOSE_MODAL_USER_NECESSARY_DATA'
  }
}
export function changeIsEditable() {
  return {
    type: 'CHANGE_IS_EDITABLE'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'USUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function onChangeEstado(event) {
  return {
    type: 'USUARIO_HANDLE_ON_CHANGE_ESTADO',
    estadoId: event.target.value
  }
}
export function editUserDetails(usuario) {
  return {
    type: 'USUARIO_DETAIL',
    id: usuario.id,
    aceiteEmails: usuario.aceiteEmails,
    aceiteTermosUso: usuario.aceiteTermosUso,
    bairro: usuario.bairro,
    carrinhoItem: usuario.carrinhoItem,
    cartaoCredito: usuario.cartaoCredito,
    cep: usuario.cep,
    cidade: usuario.cidade !== null ? usuario.cidade : { estadoId: null },
    cidadeId: usuario.cidadeId,
    corujaCoin: usuario.corujaCoin,
    cpfCnpj: usuario.cpfCnpj,
    dataNascimento: usuario.dataNascimento,
    email: usuario.email,
    emailVerificado: usuario.emailVerificado,
    endereco: usuario.endereco,
    numero: usuario.numero,
    complemento: usuario.complemento,
    excluido: usuario.excluido,
    genero: usuario.genero,
    imagem: usuario.imagem,
    ingresso: usuario.ingresso,
    nome: usuario.nome,
    senha: usuario.senha,
    telefone: usuario.telefone,
    apelido: usuario.apelido,
    username: usuario.username,
    telefoneVerificado: usuario.telefoneVerificado
  }
}

export function deleteUsuariosDetails() {
  return {
    type: 'DELETED_USUARIO_DETAILS'
  }
}
