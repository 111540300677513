import { combineReducers } from 'redux'
import { authentication } from './auth.reducer'
import { usuario } from './usuario.reducer'
import { evento } from './evento.reducer'
import { ingresso } from './ingresso.reducer'
import { search } from './search.reducer'
import { searchTopBar } from './searchTopBar.reducer'
import { categoria } from './categoria.reducer'
import { estabelecimento } from './estabelecimento.reducer'
import { cidade } from './cidade.reducer'
import { estado } from './estado.reducer'
import { global } from './global.reducer'
import { pedido } from './pedido.reducer'
import { orderPayment } from './orderPayment.reducer'
import { default as purchaseApplicationForm } from './purchaseApplicationForm.reducer'

const appReducer = combineReducers({
  global,
  authentication,
  usuario,
  evento,
  ingresso,
  search,
  searchTopBar,
  categoria,
  estabelecimento,
  estado,
  cidade,
  pedido,
  orderPayment,
  purchaseApplicationForm
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESS') {
    const { routing } = state
    state = { routing }
  }
  return appReducer(state, action)
}
export default rootReducer
