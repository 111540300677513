import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors,
  CircularProgress,
  Typography
} from '@material-ui/core'

import { Label, GenericMoreButton } from 'components'
import { useSelector } from 'react-redux'
import { toLocalDateHourFormat } from 'utils'
import { useUserOrders } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 900
  }
}))

const MyOrdersDetails = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const usuarioId = useSelector((state) => state.authentication.user.id)
  const { data: orders, isLoading } = useUserOrders(usuarioId)
  const statusColors = {
    1: colors.orange[600],
    2: colors.red[600],
    3: colors.green[600]
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card>
        <CardHeader
          // action={<GenericMoreButton />}
          title="Histórico de pedidos"
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              {orders && orders.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Nº</TableCell>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="center">Forma de pagamento</TableCell>
                      <TableCell align="center">Valor total (R$)</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders.map((pedido) => (
                      <TableRow key={pedido.id}>
                        <TableCell align="left">{pedido.id}</TableCell>
                        <TableCell align="center">
                          {toLocalDateHourFormat(pedido.dataHora)}
                        </TableCell>
                        <TableCell align="center">
                          {pedido.formaPagamento
                            ? pedido.formaPagamento.descricao
                            : '------------'}
                        </TableCell>
                        <TableCell align="center">
                          {Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(pedido.valorTotal)}
                        </TableCell>
                        <TableCell align="center">
                          <Label
                            color={statusColors[pedido.statusPedido.id]}
                            variant="outlined"
                          >
                            {pedido.statusPedido.descricao}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            color={
                              pedido.statusPedido.pendente
                                ? 'secondary'
                                : 'primary'
                            }
                            component={RouterLink}
                            size="small"
                            to={`pedido/${pedido.id}`}
                            variant="outlined"
                          >
                            {pedido.statusPedido.pendente
                              ? 'Pagar'
                              : 'Detalhes'}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div style={{ textAlign: 'center', paddingTop: 20 }}>
                  {isLoading ? (
                    <>
                      <CircularProgress color="primary" />
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="textSecondary"
                      >
                        Carregando...
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="h4">
                      Nenhum pedido encontrado
                    </Typography>
                  )}
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </div>
  )
}

MyOrdersDetails.propTypes = {
  className: PropTypes.string
}

export default MyOrdersDetails
