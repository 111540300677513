import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { history } from '_helpers'

const PrivateRouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props
  const { token } = useSelector((state) => ({
    token: state.authentication.token
  }))

  const saveLastRoute = () => {
    const lastRoute = history.location?.state?.from?.pathname
    localStorage.setItem('lastVisitedRoute', lastRoute)
  }

  useEffect(() => {
    if (!token) {
      saveLastRoute()
    }
  }, [])

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        token !== null && token !== undefined ? (
          <React.Fragment>
            <Layout>
              <Component {...matchProps} />
            </Layout>
          </React.Fragment>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default PrivateRouteWithLayout
