import React, { useEffect } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

//? Own imports
import { formatCurrency } from 'utils'
import {
  useCarrinhoItensEvento,
  useCreditCardInstallmentsSimulation,
  useDiscountCoupon,
  useOrder
} from 'hooks-querys'
import { useParams } from 'react-router-dom'
import { Pedido } from 'types/api'

const SelectInputInstallments = () => {
  const { eventoId, pedidoId } =
    useParams<{ eventoId: string; pedidoId: string }>()
  const {
    data: order,
    isLoading: isLoadingOrder,
    isFetching: isFetchingOrder
  } = useOrder(Number(pedidoId), undefined)
  const isOrder = order && order.id > 0
  const { installmentsCreditCard, selectedCreditCard } = useSelector(
    // @ts-ignore
    (state) => state.orderPayment
  )
  const dispatch = useDispatch()
  const {
    data: carrinhoItensEvento,
    isError: isErrorCarrinhoItens,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(Number(eventoId))
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const carrinhoItens =
    carrinhoItensEvento &&
    carrinhoItensEvento.filter(
      (carrinhoItem) =>
        carrinhoItem.carrinhoItem.id > 0 &&
        carrinhoItem.carrinhoItem.quantidade > 0
    )
  const carrinhoItensPayload =
    carrinhoItens &&
    carrinhoItens.map((carrinhoItemDetails) => carrinhoItemDetails.carrinhoItem)
  const { data, isLoading } = useCreditCardInstallmentsSimulation({
    carrinhoItens: isOrder ? order.carrinhoItem : carrinhoItensPayload,
    textoCupom: isOrder
      ? order.cupomDesconto?.codigo
      : discountCoupon && discountCoupon?.cupomDesconto
      ? discountCoupon?.cupomDesconto.codigo
      : ''
  })
  const handleChangeInstallments = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch({
      type: 'SET_INSTALLMENTS_CREDIT_CARD',
      payload: data?.find(
        (item) => item.parcelas === Number(event.target.value)
      )
    })
  }

  useEffect(() => {
    if (data && data?.length > 0) {
      dispatch({
        type: 'SET_INSTALLMENTS_CREDIT_CARD',
        payload: data?.find((item) => item.parcelas === 1)
      })
    }
    return () => {
      dispatch({
        type: 'SET_INSTALLMENTS_CREDIT_CARD',
        payload: {
          parcelas: 1,
          valorParcela: null,
          valorTotal: null
        }
      })
    }
  }, [selectedCreditCard, data])

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="parcelamento-label">Número de parcelas</InputLabel>
      <Select
        labelId="parcelamento-label"
        variant="outlined"
        label="Número de parcelas"
        id="parcelamento"
        value={isOrder ? order.parcelas : installmentsCreditCard.parcelas}
        onChange={handleChangeInstallments}
        IconComponent={isLoading ? () => <></> : undefined}
        fullWidth
        endAdornment={
          isLoading ? (
            <InputAdornment position="end">
              <CircularProgress color="primary" size={20} />
            </InputAdornment>
          ) : undefined
        }
      >
        <MenuItem disabled value="">
          <em>Selecione o número de parcelas</em>
        </MenuItem>
        {data && data.length > 0
          ? data.map((item) => (
              <MenuItem
                disabled={isOrder && order.parcelas !== item.parcelas}
                key={item.parcelas}
                value={item.parcelas}
              >
                <Typography>
                  {item.parcelas}x de {formatCurrency(item.valorParcela)}
                  <strong>
                    {`  - Total`} {formatCurrency(item.valorTotal)}
                  </strong>
                </Typography>
              </MenuItem>
            ))
          : null}
        {isLoading ? (
          <Box mx={1} mt={3} mb={1}>
            <Grid
              spacing={2}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <Typography align="center">
                  Carregando opções de parcelamento...
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress color="primary" size={20} />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Select>
    </FormControl>
  )
}

export default SelectInputInstallments
