import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useMemo } from 'react'

//? Own imports
import { crudService } from '_services/'
import { Pedido } from 'types/api'

const getOrder = async (orderId: number) => {
  const { data } = await crudService.get(`pedidos/${orderId}`)
  return data
}

export default function useOrder(orderId: number, orderItem?: Pedido) {
  const initialData = useMemo(() => orderItem, [orderItem])
  return useQuery<Pedido>(['orderDetails', orderId], () => getOrder(orderId), {
    initialData,
    enabled: orderId && orderId > 0 ? true : false,
    refetchInterval: (data) => {
      return data && data?.statusPedido?.pendente && data.formaPagamentoId === 3
        ? 1000 * 5
        : false
    },
    onError: (error) => {
      //@ts-ignore
      toast.error(error.message || 'Não foi possível obter os dados do pedido')
    }
  })
}
