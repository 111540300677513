import React from 'react'
import { Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import { useIsMutating } from 'react-query'

//? Own imports
import { NewCreditCard } from './components'
import { validateUserNecessaryData } from 'utils'
import { useDidMountEffect } from 'components/Utils'

type CreditCardProps = {
  handleSubmit: () => void
}
const CreditCard = ({ handleSubmit }: CreditCardProps) => {
  const dispatch = useDispatch()
  //@ts-ignore
  const { user } = useSelector((state) => state.authentication)
  const {
    selectedCreditCard,
    creditCardHash
    //@ts-ignore
  } = useSelector((state) => state.orderPayment)

  const handleClickSubmit = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      handleSubmit()
    }
  }
  const totalMutationsCreateMember = useIsMutating({
    mutationKey: ['mutationCreateMember']
  })

  const isMutating = totalMutationsCreateMember > 0

  useDidMountEffect(() => {
    if (creditCardHash && creditCardHash.length > 0 && creditCardHash !== '') {
      handleSubmit()
    }
  }, [creditCardHash])

  return (
    <div id="CreditCard">
      {isMutating && (
        <Box mt={2} mb={4}>
          <Box>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: require('assets/animations/creditCardPaymentFetching.json'),
                rendererSettings: {}
              }}
              width={160}
            />
            <Box mt={2}>
              <Typography variant="h6" color="secondary">
                Conferindo os dados do pagamento com segurança...
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {!isMutating && (
        <>
          {/* //!TODO: waiting backend */}
          {/* <UserCreditCardList /> */}
          {!selectedCreditCard?.id && <NewCreditCard />}
          {selectedCreditCard && selectedCreditCard.id > 0 ? (
            <Box mt={2}>
              <Button
                color="primary"
                onClick={handleClickSubmit}
                variant="contained"
                fullWidth
                disabled={isMutating}
              >
                {isMutating
                  ? 'Finalizando associação...'
                  : 'Pagar com cartão de credito'}
                {isMutating && (
                  <CircularProgress style={{ marginLeft: 10 }} size={20} />
                )}
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </div>
  )
}

export default CreditCard
