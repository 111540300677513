import { useMemo } from 'react'
import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services'
import { Evento } from '../types/api'
import { history } from '_helpers'

const getEvento = async (eventoId: string | number) => {
  if (Number.isNaN(Number(eventoId))) {
    const { data } = await crudService.get(`eventos/slug/${eventoId}`)
    return data?.evento
  } else {
    const { data } = await crudService.get(`eventos/web/${eventoId}`)
    return data
  }
}

export default function useEvento(
  eventoId: string | number,
  eventoDetails?: Evento
) {
  const placeholderData = useMemo(() => eventoDetails, [eventoDetails])
  return useQuery<Evento>(
    ['eventDetails', eventoId?.toString()],
    () => getEvento(eventoId),
    {
      placeholderData,
      onError: () => {
        history.push('/evento-nao-encontrado')
      }
    }
  )
}
