import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = (props) =>
  makeStyles((theme) => ({
    div: {
      backgroundImage: `url("${props.src}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      [theme.breakpoints.up('md')]: {
        boxShadow: '0 3px 5px 2px rgba(0,0, 0, .3)',
        borderRadius: '5px',
        webkitBorderRadius: '5px',
        mozBorderRadius: '5px',
        height: '410px',
        maxHeight: '410px'
      },
      [theme.breakpoints.down('sm')]: {
        height: '320px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '220px'
      }
    }
  }))

export default function ImageCarrossel({ children, ...props }) {
  const { div } = useStyles(props)()
  return <div className={div}>{children}</div>
}
