import React from 'react'
import { Redirect } from 'react-router-dom'
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded'
import LockRoundedIcon from '@material-ui/icons/LockRounded'
import {
  Tabs,
  Tab,
  Divider,
  colors,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Page } from 'components'
import { Header, General, Security } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    textAlign: 'initial'
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const MyAccount = (props) => {
  const { match, history } = props
  const classes = useStyles()
  const { tab } = match.params
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    { value: 'geral', label: 'Geral', icon: <PersonOutlineRoundedIcon /> }
    // { value: 'seguranca', label: 'Segurança', icon: <LockRoundedIcon /> }
  ]

  if (!tab) {
    return <Redirect to="/meus-dados/geral" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root} title="Configurações">
      <Header />
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant={isMobile ? 'fullWidth' : 'scrollable'}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'geral' && <General />}
        {tab === 'seguranca' && <Security />}
      </div>
    </Page>
  )
}
export default MyAccount
