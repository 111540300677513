import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import brLocale from 'date-fns/locale/pt-BR'
import {
  formatCurrency,
  getImageUrls,
  getInitials,
  mapPeriodicityChar
} from 'utils'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined'
import { useStyles } from './styles'
import { Plano, PlanoValor } from 'types/api'
import { history } from '_helpers'

const NUMBER_OF_LINES_DESCRIPTION = 15

const findMonthlyPlan = (planoValor: PlanoValor[]): PlanoValor | undefined => {
  return planoValor.find((plan) => plan.periodicidade === 'M')
}
const findAnnuallyPlan = (planoValor: PlanoValor[]): PlanoValor | undefined => {
  return planoValor.find((plan) => plan.periodicidade === 'A')
}
type AssociationPlanItemProps = { item: Plano }

const AssociationPlanItem = ({ item }: AssociationPlanItemProps) => {
  const classes = useStyles()
  const [numberOfLinesDescription, setNumberOfLinesDescription] = useState(
    NUMBER_OF_LINES_DESCRIPTION
  )
  const [selectedPlan, setSelectedPlan] = useState(
    findMonthlyPlan(item?.planoValor) || findAnnuallyPlan(item?.planoValor)
  )
  const image =
    getImageUrls(item?.imagem)?.thumbnailUrl ||
    getImageUrls(item?.estabelecimento?.imagem)?.thumbnailUrl

  const handleShowFullDescription = () => {
    if (numberOfLinesDescription) {
      setNumberOfLinesDescription(0)
    } else {
      setNumberOfLinesDescription(NUMBER_OF_LINES_DESCRIPTION)
    }
  }

  const handleSelectPlan = (planoValor: PlanoValor) => {
    setSelectedPlan(planoValor)
  }

  const monthlyPlan = findMonthlyPlan(item?.planoValor)

  function returnHtmlContent(htmlText: string) {
    return { __html: htmlText }
  }
  const navigateToEstablishmentPlans = () => {
    history.push(`/plan-purchase-details/${selectedPlan?.id}`)
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Avatar
            variant="rounded"
            className={classes.avatar}
            src={image}
            alt={getInitials(item?.nome)}
          >
            {getInitials(item?.nome)}
          </Avatar>
          <Box className={classes.info}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flex: 1
              }}
            >
              <Typography
                align="left"
                variant="subtitle1"
                style={{ textTransform: 'uppercase', fontWeight: 600 }}
              >
                {item?.nome}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box className={classes.info}>
          <Typography variant="subtitle2">Detalhes e preços</Typography>
          <Typography
            variant="body2"
            style={{
              whiteSpace: 'pre-wrap',
              maxHeight: !numberOfLinesDescription ? 'none' : '15em',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <div dangerouslySetInnerHTML={returnHtmlContent(item?.descricao)} />
          </Typography>
          <Link
            style={{
              alignItems: 'center',
              display: 'flex'
            }}
            component="button"
            variant="body2"
            onClick={handleShowFullDescription}
          >
            {!numberOfLinesDescription
              ? 'Ocultar detalhes'
              : 'Mostrar detalhes'}
            {numberOfLinesDescription ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Link>
          <FormGroup>
            {item?.planoValor && item?.planoValor?.length > 0 ? (
              item?.planoValor?.map((planoValor) => {
                const isSelected = selectedPlan?.id === planoValor?.id
                return (
                  <FormControlLabel
                    key={planoValor.id}
                    onChange={() => handleSelectPlan(planoValor)}
                    control={
                      <Checkbox
                        icon={<RadioButtonUncheckedOutlinedIcon />}
                        checkedIcon={<CheckCircleOutlineOutlinedIcon />}
                        checked={isSelected}
                        color={isSelected ? 'primary' : 'default'}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        color={isSelected ? 'primary' : 'initial'}
                      >
                        {`Plano ${mapPeriodicityChar(
                          planoValor?.periodicidade
                        )}`}
                      </Typography>
                    }
                  />
                )
              })
            ) : (
              <Button variant="contained" disabled>
                Plano indisponível no momento
              </Button>
            )}
          </FormGroup>
        </Box>
        <Divider />
        {selectedPlan && (
          <Box className={classes.footer}>
            <Box>
              {selectedPlan?.valor &&
                selectedPlan?.periodicidade === 'A' &&
                monthlyPlan?.valor &&
                monthlyPlan?.valor * 12 > selectedPlan?.valor && (
                  <Typography
                    style={{
                      textDecorationLine: 'line-through'
                    }}
                    variant="caption"
                  >
                    {formatCurrency(monthlyPlan?.valor * 12)}
                  </Typography>
                )}
              <Typography variant="h4">
                {formatCurrency(selectedPlan.valor)}
              </Typography>
              <Typography
                style={{
                  display: 'flex',
                  flexShrink: 1,
                  justifyContent: 'flex-end'
                }}
                variant="caption"
              >
                {mapPeriodicityChar(selectedPlan?.periodicidade)}
              </Typography>
            </Box>
            <Button
              onClick={navigateToEstablishmentPlans}
              fullWidth
              variant="contained"
              color="primary"
            >
              Escolher
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AssociationPlanItem
