const initialState = {
  anchor: 'left',
  loading: false,
  evento: [],
  open: false,
  id: '',
  nome: '',
  estabelecimentoId: 0,
  eventoId: 0,
  detalhes: '',
  dataHoraInicio: '',
  dataHoraFim: '',
  imagem: '',
  nomeLocal: '',
  cidadeId: 0,
  endereco: '',
  latLon: '',
  numero: '',
  bairro: '',
  cep: '',
  complemento: '',
  excluido: false,
  cidade: null,
  estabelecimento: null,
  cupomDesconto: [],
  tipoLocalizacao: '',
  lote: [],
  slug: ''
}

export function evento(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_EVENTO':
      return {
        ...state,
        evento: action.evento
      }
    case 'EVENTO_UPDATED':
      return state
    case 'HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
