import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import Image from 'material-ui-image'
import { history } from '_helpers'
import { useSelector } from 'react-redux'
const useStyles = makeStyles((theme) => ({
  root: {},
  noResultText: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    textAlign: 'center'
  },
  image: { marginTop: theme.spacing(3) },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 9px 40px -14px #FFC20A',
      webkitTransition: ' all 300ms ease',
      transition: 'all 300ms ease',
      letterSpacing: '1.2px'
    }
  }
}))
const NoTicketsFound = () => {
  const classes = useStyles()
  const handleClickFindEvento = () => {
    history.push('/search')
  }

  const { ingressosAtivos } = useSelector((state) => state.ingresso)

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      >
        <Grid item>
          <Image
            className={classes.image}
            disableTransition
            style={{ width: '300px', height: '120px' }}
            color="transparent"
            src={require('img/no-tickets-found.svg')}
            aspectRatio={16 / 9}
          />
        </Grid>
        <Grid item>
          <Typography
            className={classes.noResultText}
            gutterBottom
            variant="h4"
            color="textPrimary"
          >
            {ingressosAtivos
              ? 'Nenhum ticket para próximos eventos'
              : 'Nenhum ticket encontrado'}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClickFindEvento}
            color="primary"
          >
            Encontrar eventos!
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default NoTicketsFound
