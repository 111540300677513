import { PurchaseApplicationFormItem } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'

interface PurchaseApplicationFormState {
  purchaseApplicationFormItens: PurchaseApplicationFormItem[] | undefined
  activeStep: number
}

const initialState: PurchaseApplicationFormState = {
  purchaseApplicationFormItens: undefined,
  activeStep: 0
}

type SetPurchaseApplicationFormAction = {
  type: 'SET_PURCHASE_APPLICATION_FORM_STORE'
  payload: {
    purchaseApplicationFormItens: PurchaseApplicationFormItem[] | undefined
  }
}

type SetActiveStepAction = {
  type: 'SET_ACTIVE_STEP'
  payload: {
    activeStep: number
  }
}

type PurchaseApplicationFormAction =
  | SetPurchaseApplicationFormAction
  | SetActiveStepAction

const purchaseApplicationForm = (
  state = initialState,
  action: PurchaseApplicationFormAction
): PurchaseApplicationFormState => {
  switch (action.type) {
    case 'SET_PURCHASE_APPLICATION_FORM_STORE':
      return {
        ...state,
        purchaseApplicationFormItens:
          action.payload.purchaseApplicationFormItens
      }

    case 'SET_ACTIVE_STEP':
      return {
        ...state,
        activeStep: action.payload.activeStep
      }

    default:
      return state
  }
}

export default purchaseApplicationForm
