import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Hidden, Typography, Chip } from '@material-ui/core'
import MetaTags, { ReactTitle } from 'react-meta-tags'
import BackgroundImage from './backgroundImage'
import EventOutlinedIcon from '@material-ui/icons/EventOutlined'
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined'
import { toLocalDateHourFormat } from 'utils'
import { isSameDay, format, isPast } from 'date-fns'
import { Share } from 'components'
import EventBusyRoundedIcon from '@material-ui/icons/EventBusyRounded'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4)
  },
  cardImagem: {
    [theme.breakpoints.up('md')]: {
      width: '55%',
      height: 'auto',
      position: 'relative',
      userSelect: 'none',
      boxShadow: '0px 0px 5px 5px rgba(0,0,0,0.12)',
      borderRadius: '10px',
      webkitBorderRadius: '10px',
      mozBorderRadius: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '100%'
    }
  },
  shareDial: {
    [theme.breakpoints.up('md')]: {
      width: '55%',
      maxHeight: '410px',
      left: '50%',
      top: '60%',
      position: 'relative',
      transform: 'translateX(-50%)'
    },
    marginLeft: '15px',
    marginTop: '-35px'
  },
  iconText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '2px'
  },
  nome: {
    padding: theme.spacing(1, 1, 1.5, 1)
  },
  chipPast: {
    padding: theme.spacing(1)
  }
}))

export default function EventoHeader(props) {
  const classes = useStyles()
  const { evento } = props
  const isPastEvent = isPast(new Date(new Date(evento.dataHoraFim)))
  const generateDateHoursElement = () => {
    const isSameDate = isSameDay(
      new Date(evento.dataHoraInicio),
      new Date(evento.dataHoraFim)
    )
    return (
      <div className={classes.iconText}>
        <EventOutlinedIcon style={{ fontSize: 16, marginRight: '7px' }} />
        <Typography color="textPrimary" variant="caption">
          {!isSameDate
            ? `${toLocalDateHourFormat(
                evento.dataHoraInicio
              )} - ${toLocalDateHourFormat(evento.dataHoraFim)}`
            : `${toLocalDateHourFormat(evento.dataHoraInicio)} às ${format(
                new Date(evento.dataHoraFim),
                'HH:mm'
              )}`}
        </Typography>
      </div>
    )
  }
  return (
    <div>
      <div className="wrapper">
        <ReactTitle title={`${evento.nome} - Corujas`} />
        <MetaTags>
          <title>{evento.nome} - Corujas</title>
          <meta name="description" content={`${evento.nome} - Corujas`} />
          <meta property="og:title" content={`${evento.nome} - Corujas`} />
          <meta
            property="og:image"
            content={`https://corujatickets.com/event-details/${evento.id}`}
          />
          <meta property="fb:app_id" content="123456789" />
          <meta property="og:url" content="http://example.com/page.html" />
          <meta property="og:type" content="website" />
          <meta property="og:description" content="Description Here" />
          <meta property="og:site_name" content="Site Name" />
          <meta property="og:locale" content="pt_BR" />
          <meta property="article:author" content="" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@site_account" />
          <meta name="twitter:creator" content="@individual_account" />
          <meta name="twitter:url" content="http://example.com/page.html" />
          <meta name="twitter:title" content="Content Title" />
          <meta
            name="twitter:description"
            content="Content description less than 200 characters"
          />
          <meta name="twitter:image" content="http://example.com/image.jpg" />
        </MetaTags>
        <div className={classes.root}>
          <Hidden smDown>
            <BackgroundImage src={evento.imagem} />
          </Hidden>
          <img className={classes.cardImagem} src={evento.imagem} alt="" />
          <div className={classes.shareDial}>
            <Share evento={evento} />
          </div>
          {isPastEvent && (
            <Chip
              variant="default"
              className={classes.chipPast}
              color="primary"
              label="Evento encerrado"
              icon={<EventBusyRoundedIcon />}
            />
          )}
          <Typography
            color="textPrimary"
            variant="h2"
            className={classes.nome}
            gutterBottom
            align="center"
          >
            <strong>{evento.nome}</strong>{' '}
          </Typography>
          {generateDateHoursElement()}
          <div className={classes.iconText}>
            <PlaceOutlinedIcon style={{ fontSize: 16, marginRight: '7px' }} />
            <Typography color="textPrimary" variant="caption">
              {evento.tipoLocalizacao === 'O'
                ? 'Evento online'
                : evento.tipoLocalizacao !== null &&
                  !!evento.cidade &&
                  `${evento.nomeLocal ? `${evento.nomeLocal} - ` : ''}${
                    evento.cidade.nome
                  }, ${evento.cidade.estado.uf}`}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

EventoHeader.propTypes = {
  evento: PropTypes.object.isRequired
}
