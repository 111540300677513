import React from 'react'
import { Typography, Button, Box, CircularProgress } from '@material-ui/core'
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useMutationCreateOrder
} from 'hooks-querys'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import { useRouter, validateUserNecessaryData } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import CardGiftCardRoundedIcon from '@material-ui/icons/CardGiftcardRounded'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import { extractRespostasCamposInscricao } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'
import {
  extractCartItems,
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const Free = ({ eventoId, isCheckIn }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.authentication)
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(eventoId)

  const filteredCartItems = filterValidCartItems(data)
  const carrinhoItensWithoutCheckIn = filterItemsWithoutCheckIn(
    filteredCartItems || []
  )
  const carrinhoItensWithCheckIn = filterItemsWithCheckIn(
    filteredCartItems || []
  )
  const carrinhoItens = isCheckIn
    ? carrinhoItensWithCheckIn
    : carrinhoItensWithoutCheckIn

  const totalValue = calculaValorAPagar(
    carrinhoItens || [],
    (discountCoupon && discountCoupon.carrinhoItens) || [],
    discountCoupon && discountCoupon.cupomDesconto
  )

  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.purchaseApplicationFormItens
  )

  const mutationCreateOrder = useMutationCreateOrder(
    {
      textoCupom:
        discountCoupon && discountCoupon?.cupomDesconto
          ? discountCoupon?.cupomDesconto.codigo
          : '',
      carrinhoItens: extractCartItems(carrinhoItens),
      formaPagamentoId: null,
      respostasCamposInscricao: extractRespostasCamposInscricao(
        purchaseApplicationFormItens
      )
    },
    totalValue
  )

  const handleClickCreateOrder = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      mutationCreateOrder.mutate()
    }
  }
  return (
    <div>
      {isCheckIn ? (
        <div>
          <CardMembershipIcon style={{ fontSize: 55 }} />
          <Typography variant={'h5'} gutterBottom>
            <strong>Este é um benefício exclusivo para membros!</strong>
          </Typography>
          <Box paddingTop={4}>
            <Typography variant="body2" align="left" gutterBottom>
              Como membro, você tem acesso a este evento de forma totalmente
              gratuita. Finalize seu pedido e aproveite essa vantagem!
            </Typography>
          </Box>
        </div>
      ) : (
        <div>
          <CardGiftCardRoundedIcon style={{ fontSize: 55 }} />
          <Typography variant={'h5'} gutterBottom>
            <strong>Esse pedido é gratuito!</strong>
          </Typography>
          <Box paddingTop={4}>
            <Typography variant="body2" align="left" gutterBottom>
              Clique no botão abaixo para finalizar o pedido e participar do
              evento de forma gratuita, sem nenhum custo! 🤘
            </Typography>
          </Box>
        </div>
      )}
      <Box paddingTop={2}>
        <Button
          endIcon={
            mutationCreateOrder.isLoading && (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: 5 }}
              />
            )
          }
          variant="contained"
          color="primary"
          onClick={handleClickCreateOrder}
          disabled={mutationCreateOrder.isLoading}
        >
          {mutationCreateOrder.isLoading
            ? 'Finalizando pedido...'
            : isCheckIn
            ? 'Finalizar check-in'
            : 'Participar gratuitamente'}
        </Button>
      </Box>
    </div>
  )
}

export default Free
