import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { differenceInDays, format, intervalToDuration } from 'date-fns'
import { ptBR } from 'date-fns/locale'

//? Own imports
import { CarrinhoItem } from 'types/api'

type CheckInFinalDateProps = {
  carrinhoItem: CarrinhoItem
}

const CheckInFinalDate = ({ carrinhoItem }: CheckInFinalDateProps) => {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null)

  useEffect(() => {
    const calculateTimeRemaining = () => {
      if (!carrinhoItem?.lote?.dataHoraTermino) return null

      const endDate = new Date(carrinhoItem.lote.dataHoraTermino)
      const now = new Date()
      const timeDiff = differenceInDays(endDate, now)

      if (timeDiff < 0) {
        return 'Check-in expirado'
      } else if (timeDiff > 0) {
        return `Disponível até ${format(endDate, "EEEE', dia' d 'de' MMMM", {
          locale: ptBR
        })}`
      } else {
        const duration = intervalToDuration({
          start: now,
          end: endDate
        })

        return `Tempo restante: ${duration.hours}h ${duration.minutes}m ${duration.seconds}s`
      }
    }

    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [carrinhoItem?.lote?.dataHoraTermino])

  return (
    <Box paddingY={1}>
      <Typography variant="caption">{timeRemaining}</Typography>
    </Box>
  )
}

export default CheckInFinalDate
