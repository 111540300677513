const initialState = {
  loading: false,
  id: '',
  nome: '',
  categoriaId: '',
  detalhes: '',
  cidadeId: '',
  endereco: '',
  cep: '',
  bairro: '',
  latLon: '',
  capacidade: '',
  imagemLogo: '',
  imagemHeader: '',
  cpfCnpj: '',
  excluido: false,
  eventos: [],
  eventosPassados: []
}

export function estabelecimento(state = initialState, action) {
  switch (action.type) {
    case 'ESTABELECIMENTO_DETAIL':
      return {
        ...state,
        id: action.id,
        nome: action.nome,
        detalhes: action.detalhes,
        cidadeId: action.cidadeId,
        endereco: action.endereco,
        latLon: action.latLon,
        capacidade: action.capacidade,
        imagem: action.imagem,
        excluido: action.excluido,
        cidade: action.cidade,
        bairro: action.bairro,
        cep: action.cep,
        categoriaId: action.categoriaId
      }
    case 'ESTABELECIMENTO_CLEAR':
      return initialState
    case 'EVENTOS_BY_ESTABELECIMENTO_REQUEST':
      return {
        ...state,
        loading: true,
        id: action.estabelecimentoId
      }
    case 'EVENTOS_BY_ESTABELECIMENTO_SUCCESS':
      return {
        ...state,
        loading: false,
        id: action.estabelecimento.id,
        nome: action.estabelecimento.nome,
        categoriaId: action.estabelecimento.categoriaId,
        detalhes: action.estabelecimento.detalhes,
        cidadeId: action.estabelecimento.cidadeId,
        endereco: action.estabelecimento.endereco,
        cep: action.estabelecimento.cep,
        bairro: action.estabelecimento.bairro,
        latLon: action.estabelecimento.latLon,
        capacidade: action.estabelecimento.capacidade,
        imagemLogo: action.estabelecimento.imagem,
        imagemHeader: action.estabelecimento.banner,
        cpfCnpj: action.estabelecimento.cpfCnpj,
        excluido: action.estabelecimento.excluido,
        eventos: action.eventos,
        eventosPassados: action.eventosPassados
      }
    case 'EVENTOS_BY_ESTABELECIMENTO_FAILURE':
      return {
        ...state,
        loading: false,
        eventos: [],
        error: action.error
      }
    default:
      return state
  }
}
