import React, { useEffect } from 'react'
import { makeStyles, Typography, useTheme } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { usuarioActions, cidadeAction, estadoAction } from '_actions'
import InputLabel from '@material-ui/core/InputLabel'
import { isEmpty } from 'lodash'
import { KeyboardDatePicker } from '@material-ui/pickers'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { cpfCnpjMask, phoneMask, cepMask } from 'utils'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { useDidMountEffect } from 'components/Utils/useDidMountEffect'
import { useDispatch, useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}))

const UsuarioForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const estados = useSelector((state) => state.estado.estado)
  const cidades = useSelector((state) => state.cidade.cidade)
  const usuario = useSelector((state) => state.usuario)
  const theme = useTheme()

  useEffect(() => {
    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
    ValidatorForm.addValidationRule('isValidPhone', (value) => {
      if (value && value !== null) {
        if (value.length > 0 && value.length < 14) {
          return false
        }
        return true
      }
      return true
    })
    ValidatorForm.addValidationRule('isValidCep', (value) => {
      if (value && value.length && value.length === 9) {
        return true
      } else {
        return false
      }
    })
    if (estados.length === 0) {
      dispatch(estadoAction.getEstado())
    }
    if (usuario.cidade.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(usuario.cidade.estadoId))
    }
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidCnpj')
      ValidatorForm.removeValidationRule('isValidCep')
      ValidatorForm.removeValidationRule('isValidPhone')
    }
  }, [])

  const handleChangeData = (newValue) => {
    dispatch(usuarioActions.onChangeData(newValue))
  }

  useDidMountEffect(() => {
    if (usuario.cidade.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(usuario.cidade.estadoId))
    }
  }, [usuario.cidade.estadoId])

  const handleChange = (prop) => (event) => {
    dispatch(usuarioActions.onChangeProps(prop, event))
  }

  const handleChangeEstado = (event) => {
    dispatch(usuarioActions.onChangeEstado(event))
  }

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          autoComplete="nome"
          name="nome"
          variant="outlined"
          fullWidth
          value={usuario.nome}
          onChange={handleChange('nome')}
          id="nome"
          label="Nome completo *"
          // autoFocus
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="cpfCnpj"
          label="CPF/CNPJ *"
          name="cpfCnpj"
          type="text"
          inputProps={{
            maxLength: '18'
          }}
          value={cpfCnpjMask(usuario.cpfCnpj)}
          onChange={handleChange('cpfCnpj')}
          autoComplete="cpf"
          validators={['required', 'isValidCpf', 'isValidCnpj']}
          errorMessages={[
            'Campo obrigatório!',
            'CPF inválido!',
            'CNPJ inválido!'
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          autoComplete="apelido"
          name="apelido"
          variant="outlined"
          fullWidth
          value={usuario.apelido}
          onChange={handleChange('apelido')}
          id="apelido"
          label="Apelido"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          id="username"
          label="Nome de usuário *"
          value={usuario.username}
          onChange={handleChange('username')}
          fullWidth
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
          InputProps={{
            // endAdornment: (
            //   <InputAdornment position="end">
            //     <Tooltip title="Gerar apelido">
            //       <IconButton
            //         aria-label="gerar apelido automaticamente"
            //         onClick={() => generateUsername()}
            //       >
            //         <YoutubeSearchedForRoundedIcon />
            //       </IconButton>
            //     </Tooltip>
            //   </InputAdornment>
            // ),
            startAdornment: (
              <Typography
                style={{
                  color: !usuario.isEditable && theme.palette.text.disabled
                }}
              >
                @
              </Typography>
            )
          }}
          // onFocus={() =>
          //   (usuario.username === '' ||
          //     usuario.username === null) &&
          //   generateUsername()
          // }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <KeyboardDatePicker
          disabled={!usuario.isEditable}
          autoOk
          fullWidth
          disableFuture
          inputVariant="outlined"
          invalidDateMessage="Data inválida"
          invalidLabel="dd/mm/aaaa"
          maxDateMessage="A data não deve ser posterior à data máxima"
          minDateMessage="A data não deve ser anterior à data mínima"
          cancelLabel="Cancelar"
          okLabel="Definir"
          clearable
          clearLabel="Limpar"
          placeholder="dd/mm/aaaa"
          todayLabel="Hoje"
          label="Data de Nascimento"
          format="dd/MM/yyyy"
          openTo="year"
          KeyboardButtonProps={{ size: 'small' }}
          views={['year', 'month', 'date']}
          inputValue={usuario.dataNascimento}
          value={usuario.dataNascimento}
          onChange={(_, newValue) => handleChangeData(newValue)}
          InputAdornmentProps={{ position: 'start' }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="telefone"
          label="Telefone *"
          name="telefone"
          value={phoneMask(usuario.telefone)}
          onChange={handleChange('telefone')}
          autoComplete="telefone"
          validators={['required', 'isValidPhone']}
          errorMessages={['Campo obrigatório!', 'Telefone inválido!']}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel disabled={!usuario.isEditable} id="sexo-label">
            Sexo
          </InputLabel>
          <Select
            disabled={!usuario.isEditable}
            labelId="sexo-label"
            variant="outlined"
            id="Sexo"
            label="Sexo"
            InputLabelProps={{
              shrink: true
            }}
            value={usuario.genero}
            onChange={handleChange('genero')}
            fullWidth
          >
            <MenuItem disabled value="">
              <em>Sexo</em>
            </MenuItem>
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Feminino</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectValidator
          disabled={!usuario.isEditable}
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
          variant="outlined"
          id="estadoId"
          label="Estado *"
          value={usuario.cidade.estadoId}
          onChange={handleChangeEstado}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          displayEmpty
          labelId="estadoId"
        >
          <MenuItem disabled value="">
            <em>Estado *</em>
          </MenuItem>
          {undefined !== estados && estados.length
            ? estados.map((row) => (
                <MenuItem key={row.id} value={row.id}>
                  {row.nome}
                </MenuItem>
              ))
            : null}
        </SelectValidator>
      </Grid>
      <Grid item xs={12} sm={5}>
        <SelectValidator
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
          disabled={!usuario.cidade.estadoId || !usuario.isEditable}
          variant="outlined"
          id="cidadeId"
          label="Cidade *"
          value={usuario.cidadeId}
          onChange={handleChange('cidadeId')}
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          displayEmpty
          labelId="cidadeId"
        >
          <MenuItem disabled value="">
            <em>Cidade *</em>
          </MenuItem>
          {cidades.map((row) => (
            <MenuItem key={row.id} value={row.id}>
              {row.nome}
            </MenuItem>
          ))}
        </SelectValidator>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="cep"
          label="CEP *"
          name="cep"
          type="text"
          inputProps={{
            maxlength: '9',
            minlength: '9'
          }}
          value={cepMask(usuario.cep)}
          onChange={handleChange('cep')}
          autoComplete="cep"
          validators={['required', 'isValidCep']}
          errorMessages={['Campo obrigatório!', 'O CEP não é válido']}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="endereco"
          label="Endereço *"
          name="endereco"
          value={usuario.endereco}
          onChange={handleChange('endereco')}
          autoComplete="endereco"
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="numero"
          label="Numero *"
          name="numero"
          inputProps={{
            maxlength: '10',
            minlength: '1'
          }}
          value={usuario.numero}
          onChange={handleChange('numero')}
          autoComplete="numero"
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="bairro"
          label="Bairro *"
          name="bairro"
          value={usuario.bairro}
          onChange={handleChange('bairro')}
          autoComplete="bairro"
          validators={['required']}
          errorMessages={['Campo obrigatório!']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextValidator
          disabled={!usuario.isEditable}
          variant="outlined"
          fullWidth
          id="complemento"
          label="Complemento"
          name="complemento"
          value={usuario.complemento}
          onChange={handleChange('complemento')}
          autoComplete="complemento"
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          disabled={!usuario.isEditable}
          onChange={handleChange('aceiteEmails')}
          control={<Checkbox checked={usuario.aceiteEmails} color="primary" />}
          label={
            <Typography variant="caption">
              Quero receber diversão, promoções de marketing e atualizações por
              e-mail!
            </Typography>
          }
        />
      </Grid>
    </Grid>
  )
}

export default UsuarioForm
