import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

export default function Titulo(props) {
  const useStyles = makeStyles((theme) => ({
    title: {
      '&::after': {
        width: '3rem'
      },
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1.5),
      borderLeft: `0.5rem solid ${theme.palette.primary.main}`,
      textAlign: 'left',
      userSelect: 'none',
      fontWeight: 'bold'
    }
  }))

  const classes = useStyles()
  return (
    <Typography className={classes.title} color="secondary" variant="h3">
      {props.children}
    </Typography>
  )
}

Titulo.propTypes = {
  children: PropTypes.node
}
