import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Box, useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { usuarioActions } from '_actions'
import UsuarioForm from 'views/MyAccount/components/General/components/GeneralSettings/UsuarioForm'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { GeneralSettings } from 'views/MyAccount/components/General/components'

const styles = (theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <div>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          onClick={onClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  )
})

export default function DialogUserNecessaryDataToPurchase() {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { openModalUserNecessaryData } = useSelector((state) => state.usuario)

  const handleClose = () => {
    dispatch(usuarioActions.closeModalUserNecessaryData())
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModalUserNecessaryData}
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} />
      <Box>
        <ValidatorForm debounceTime={400}>
          <GeneralSettings />
        </ValidatorForm>
      </Box>
    </Dialog>
  )
}
