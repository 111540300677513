import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import StarRoundedIcon from '@material-ui/icons/StarRounded'

//? Own imports
import { useUserCreditCards } from 'hooks-querys'
import { getCreditCardImage } from 'utils'
import { CreditCard } from 'types/api'
import SelectInputInstallments from '../SelectInputInstallments'

export default function UserCreditCardList() {
  // @ts-ignore
  const usuarioId = useSelector((state) => state.authentication.user.id)
  // @ts-ignore
  const { selectedCreditCard } = useSelector((state) => state.orderPayment)
  const { data: creditCardsList, isLoading } = useUserCreditCards(usuarioId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const dispatch = useDispatch()

  const handleChange = (creditCard: CreditCard) => {
    dispatch({
      type: 'SET_SELECTED_CREDIT_CARD',
      payload: creditCard
    })
  }
  const handleChangeNewCreditCard = () => {
    dispatch({
      type: 'SET_SELECTED_CREDIT_CARD',
      payload: 0
    })
  }
  useEffect(() => {
    if (creditCardsList && creditCardsList.length > 0) {
      const favoriteCreditCard = creditCardsList.find(
        (creditCard) => creditCard.padrao
      )
      if (favoriteCreditCard) {
        dispatch({
          type: 'SET_SELECTED_CREDIT_CARD',
          payload: favoriteCreditCard
        })
      }
    }
    return () => {
      dispatch({
        type: 'SET_SELECTED_CREDIT_CARD',
        payload: null
      })
    }
  }, [creditCardsList])

  return (
    <FormControl fullWidth component="fieldset">
      <FormLabel
        style={{ marginBottom: 20, textAlign: 'left' }}
        component="legend"
      >
        Escolha a forma de pagamento
      </FormLabel>
      <RadioGroup
        aria-label="creditCard"
        name="creditCard"
        value={
          selectedCreditCard && selectedCreditCard.id && selectedCreditCard.id
        }
      >
        {!isLoading &&
          creditCardsList &&
          creditCardsList.length > 0 &&
          creditCardsList.map((creditCard: CreditCard, index) => (
            <>
              <FormControlLabel
                onChange={() => handleChange(creditCard)}
                key={creditCard.id}
                value={creditCard.id}
                control={<Radio />}
                label={
                  <>
                    <Grid
                      container
                      spacing={isMobile ? 1 : 4}
                      alignItems="center"
                      justifyContent={'flex-start'}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Box display="flex" alignItems="center">
                          <img
                            // @ts-ignore
                            src={getCreditCardImage(
                              creditCard.bandeiraCartaoCredito.nome
                            )}
                            alt={creditCard.bandeiraCartaoCredito.nome}
                            style={{ width: 40 }}
                          />
                          {creditCard.padrao && (
                            <Tooltip title={'Cartão padrão'}>
                              <StarRoundedIcon
                                style={{ color: '#f2ce60' }}
                                fontSize="small"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                      <Grid style={{ width: isMobile ? 'auto' : 170 }} item>
                        <Typography align="left">
                          **** **** **** {creditCard.ultimos4Digitos}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="right">
                          {creditCard.identificacao || creditCard.nomeTitular}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              />
              {selectedCreditCard && selectedCreditCard.id === creditCard.id ? (
                <Box mt={1} mb={2}>
                  <SelectInputInstallments />
                </Box>
              ) : null}
            </>
          ))}
        <FormControlLabel
          onChange={handleChangeNewCreditCard}
          value={0}
          control={<Radio />}
          label={
            <Box display="flex" alignItems={'center'}>
              <CreditCardIcon style={{ marginRight: 10 }} />
              <Typography>Novo cartão de credito</Typography>
            </Box>
          }
        />
      </RadioGroup>
    </FormControl>
  )
}
