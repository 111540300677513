import { CssBaseline, Grid, IconButton, Paper } from '@material-ui/core'
import React from 'react'
import { history } from '_helpers'
import { FormLogin } from './components'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { makeStyles } from '@material-ui/core/styles'
import Background from '../../img/img-login.svg'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.secondary.main,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundSize: '60% 60%'
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(8, 4)
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(3, 4)
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const Login = () => {
  const classes = useStyles()
  const { token } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (token) {
      history.push('/')
    }
  }, [token])

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} square>
        <div className={classes.paper}>
          <Grid container justify="flex-start">
            <IconButton
              onClick={() => history.goBack()}
              aria-label="Voltar"
              color="primary"
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Grid>
          <FormLogin />
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
