import { toast } from 'react-toastify'
import { useMutation } from 'react-query'

//? Own imports
import { crudService } from '_services/'
import { Membro } from '../../types/api'

type Payload = Membro

type Return = Membro
const postCreateMember = async (payload: Payload) => {
  const { data } = await crudService.post('membros', payload)
  return data
}

export default function useMutationCreateMember(payload: Payload) {
  return useMutation<Return>(
    ['mutationCreateMember'],
    () => postCreateMember(payload),
    {
      //@ts-ignore
      onError: (error: any) => {
        if (error && (error?.status === 400 || error?.status === 200)) {
          toast.error(error?.data)
        } else {
          toast.error('Opss! Houve um erro ao finalizar o cadastro.')
        }
      },
      onSuccess: (data) => {}
    }
  )
}
