import { useQuery } from 'react-query'

//? Own imports
import { CarrinhoItemDetails } from './../types/api'
import { crudService } from '_services/'

const getCarrinhoItensEvento = async (eventoId: number) => {
  const { data } = await crudService.get(`carrinhoItens/evento/${eventoId}`)
  return data
}

export default function useCarrinhoItensEvento(eventoId: number) {
  return useQuery<CarrinhoItemDetails[]>(
    ['carrinhoItens', eventoId],
    () => getCarrinhoItensEvento(eventoId),
    { enabled: eventoId !== undefined && eventoId > 0 }
  )
}
