import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const AssociationPlanListSkeleton = () => {
  const repetitions = 3

  const gridItems = Array.from({ length: repetitions }, (_, index) => (
    <Grid key={index} item xs={12} sm={6} md={4}>
      <Skeleton
        style={{ borderRadius: 8 }}
        animation="wave"
        variant="rect"
        width={'100%'}
        height={400}
      />
    </Grid>
  ))

  return (
    <Grid container spacing={2} alignItems="stretch">
      {gridItems}
    </Grid>
  )
}

export default AssociationPlanListSkeleton
