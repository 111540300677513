import { crudService } from '_services'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const postCarrinhoItem = async (carrinhoItem) => {
  const { data } = await crudService.post('carrinhoItens', carrinhoItem)
  return data
}

export default function useMutationCreateCarrinhoItem(carrinhoItem) {
  const queryClient = useQueryClient()
  return useMutation(
    (carrinhoItemPayload) => postCarrinhoItem(carrinhoItemPayload),
    {
      onMutate: async () => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries([
          'carrinhoItens',
          carrinhoItem.lote.eventoId
        ])
      },
      onSuccess: () => {
        queryClient.setQueryData(
          ['carrinhoItens', carrinhoItem.lote.eventoId],
          (oldDate) => {
            const newCarrinhoItens = []
            oldDate.map((item) => {
              item.carrinhoItem.loteId === carrinhoItem.loteId
                ? newCarrinhoItens.push({
                    ...item,
                    carrinhoItem: {
                      ...item.carrinhoItem,
                      quantidade: item.carrinhoItem.quantidade + 1
                    }
                  })
                : newCarrinhoItens.push(item)
            })

            return newCarrinhoItens
          }
        )
        queryClient.invalidateQueries([
          'carrinhoItens',
          carrinhoItem.lote.eventoId
        ])
      },

      onError: (error) => {
        const message =
          error?.data ||
          'Opss! Não conseguimos adicionar o ingresso, tente novamente.'
        toast.error(message)
      }
    }
  )
}
