import { crudService } from '_services'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { CreditCard } from 'types/api'

type PayloadCreditCard = {
  creditCardHash: string
  bandeiraCartaoCreditoNome: string
  identificacao?: string
  endereco: string
  numero: string
  complemento?: string
  bairro: string
  cep: string
  cidadeId: number
}
const postCreditCard = async (payload: PayloadCreditCard) => {
  const { data } = await crudService.post('cartoesCredito', payload)
  return data
}

export default function useMutationCreateCreditCard() {
  const queryClient = useQueryClient()

  return useMutation((payload: PayloadCreditCard) => postCreditCard(payload), {
    onError: (error) => {
      toast.error('Opss! Não foi possível salvar seu cartão, tente novamente')
    },
    onSuccess: (data: CreditCard) => {
      queryClient.setQueryData<CreditCard[]>(
        ['creditCards', data.usuarioId],
        (oldCreditCards) => {
          if (oldCreditCards) {
            return [{ ...data }, ...oldCreditCards]
          } else {
            return [{ ...data }]
          }
        }
      )
      queryClient.invalidateQueries(['creditCards', data.usuarioId])
    }
  })
}
