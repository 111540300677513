const initialState = {
  ingresso: [],
  isSearching: false,
  textSearch: '',
  ingressosAtivos: true,
  params: {},
  id: 0,
  usuarioId: 0,
  loteId: 0,
  statusIngressoId: 0,
  valor: 0,
  taxa: 0,
  qrCode: '',
  excluido: false,
  nomeacao: '',
  cpfNomeacao: '',
  usuario: null,
  lote: null,
  statusIngresso: null,
  corujaCoin: []
}

export function ingresso(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_INGRESSO':
      return {
        ...state,
        ingresso: action.ingresso
      }
    case 'INGRESSO_DETAIL':
      return {
        ...state,
        id: action.id,
        usuarioId: action.usuarioId,
        loteId: action.loteId,
        statusIngressoId: action.statusIngressoId,
        valor: action.valor,
        taxa: action.taxa,
        qrCode: action.qrCode,
        excluido: action.excluido,
        nomeacao: action.nomeacao,
        cpfNomeacao: action.cpfNomeacao,
        usuario: action.usuario,
        lote: action.lote,
        statusIngresso: action.statusIngresso,
        corujaCoin: action.corujaCoin
      }
    case 'INGRESSO_UPDATED':
      return state
    case 'INGRESSO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'SEARCH_INGRESSOS_REQUEST':
      return {
        ...state,
        isSearching: true,
        params: action.params
      }
    case 'SEARCH_INGRESSOS_SUCCESS':
      return {
        ...state,
        isSearching: false,
        ingresso: action.ingresso
      }
    case 'SEARCH_INGRESSOS_FAILURE':
      return {
        ...state,
        isSearching: false,
        error: action.error
      }
    case 'CHANGE_INGRESSOS_ATIVOS':
      return {
        ...state,
        ingressosAtivos: action.ingressosAtivos,
        textSearch: ''
      }
    case 'CLEAR_TEXT_SEARCH':
      return {
        ...state,
        textSearch: ''
      }
    default:
      return state
  }
}
