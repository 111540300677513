import React from 'react'
import { aplicaDescontos, calculaTaxa } from 'utils/purchaseCalculate'
import { makeStyles, Typography } from '@material-ui/core'
import { formatCurrency, getGenderFull } from 'utils'
import { useDiscountCoupon } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
}))

export default function CarrinhoItemDetails({
  carrinhoItem,
  parametroTaxaPadrao,
  taxa
}) {
  const classes = useStyles()
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const isFree =
    carrinhoItem.lote && carrinhoItem.lote.valor == 0 ? true : false
  const haveDiscount = carrinhoItem
    ? discountCoupon &&
      discountCoupon.carrinhoItens.some(
        (carrinhoItemDesconto) =>
          carrinhoItemDesconto.id === carrinhoItem.id &&
          (discountCoupon?.cupomDesconto?.valorDesconto > 0 ||
            discountCoupon?.cupomDesconto?.percentualDesconto > 0)
      )
    : false
  const haveTax = carrinhoItem.lote.repassarTaxa

  return (
    <div key={carrinhoItem.id} className={classes.content}>
      {carrinhoItem && (
        <Typography display="inline" align="left">
          <Typography
            display="inline"
            align="left"
            color="textPrimary"
            variant="body2"
          >
            {carrinhoItem.quantidade} {carrinhoItem.lote.tipoIngresso.descricao}{' '}
            - {carrinhoItem.lote.descricao} (
            {getGenderFull(carrinhoItem.lote.genero)}) -{' '}
          </Typography>
          <Typography
            display="inline"
            align="left"
            color="textPrimary"
            variant="body2"
          >
            {isFree ? (
              <strong>Gratuito</strong>
            ) : (
              <Typography display="inline" align="left">
                <Typography
                  display="inline"
                  align="left"
                  color="textPrimary"
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: haveDiscount ? 'line-through' : 'none',
                    color: haveDiscount ? '#9e9e9e' : 'inherit'
                  }}
                >
                  {`${formatCurrency(
                    carrinhoItem.quantidade * carrinhoItem.lote.valor
                  )}`}
                </Typography>
                {haveDiscount && discountCoupon.cupomDesconto && (
                  <Typography
                    display="inline"
                    align="left"
                    color="textPrimary"
                    variant="body2"
                  >
                    {' '}
                    <strong>
                      {formatCurrency(
                        aplicaDescontos(
                          carrinhoItem,
                          discountCoupon.cupomDesconto
                        )
                      )}
                    </strong>
                  </Typography>
                )}
                {haveTax &&
                  calculaTaxa(
                    {
                      carrinhoItem: carrinhoItem,
                      taxa: taxa,
                      parametroTaxaPadrao: parametroTaxaPadrao
                    },
                    (discountCoupon && discountCoupon.carrinhoItens) || [],
                    discountCoupon && discountCoupon.cupomDesconto
                  ) > 0 && (
                    <Typography
                      display="inline"
                      align="left"
                      color="textPrimary"
                      variant="body2"
                    >
                      <strong>
                        {' (+ '}
                        {formatCurrency(
                          calculaTaxa(
                            {
                              carrinhoItem: carrinhoItem,
                              taxa: taxa,
                              parametroTaxaPadrao: parametroTaxaPadrao
                            },
                            (discountCoupon && discountCoupon.carrinhoItens) ||
                              [],
                            discountCoupon && discountCoupon.cupomDesconto
                          )
                        ) || ''}{' '}
                        taxa)
                      </strong>
                    </Typography>
                  )}
              </Typography>
            )}
          </Typography>
        </Typography>
      )}
    </div>
  )
}
