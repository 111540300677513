import React, { useState } from 'react'
import {
  Box,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined'
import ScheduleIcon from '@material-ui/icons/Schedule'

//? Own imports
import { formatCurrency, getImageUrls, mapPeriodicityChar } from 'utils'
import { PlanoValor } from 'types/api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  imagePlan: {
    width: '100%',
    [theme.breakpoints.down('sm')]: { width: '50%' },
    borderRadius: '5px',
    webkitBorderRadius: '5px',
    mozBorderRadius: '5px',
    objectFit: 'cover',
    height: 110
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  },
  inlineBlock: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center'
  }
}))

const PlanPurchaseSummary = ({ planValue }: { planValue: PlanoValor }) => {
  const classes = useStyles()
  const [showFullDescription, setShowFullDescription] = useState(false)

  const plan = planValue?.plano
  const establishment = plan?.estabelecimento
  const image =
    getImageUrls(plan?.imagem)?.thumbnailUrl ||
    getImageUrls(establishment?.imagem)?.thumbnailUrl

  function returnHtmlContent(htmlText: string) {
    return { __html: htmlText }
  }

  const toggleShowFullDescription = () => {
    setShowFullDescription(!showFullDescription)
  }

  return (
    <div className={classes.root}>
      <div>
        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={12} md={5}>
            <img className={classes.imagePlan} src={image} />
          </Grid>
          <Grid item xs={12} md={7}>
            <Box mb={1.5}>
              <Typography variant="h5" align="left">
                <strong>{plan.nome}</strong>
              </Typography>
            </Box>
            <Box className={classes.inlineBlock}>
              <HomeWorkOutlinedIcon fontSize="small" />
              <Typography variant="body2" color="textSecondary" align="left">
                {establishment?.nome?.toUpperCase()}
              </Typography>
            </Box>
            <Box className={classes.inlineBlock}>
              <ScheduleIcon fontSize="small" />
              <Typography variant="body2" color="textSecondary" align="left">
                {`PERÍODO DE RECORRÊNCIA ${mapPeriodicityChar(
                  planValue?.periodicidade
                )}`?.toUpperCase()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
      <>
        <Typography
          color="textPrimary"
          align="left"
          gutterBottom
          variant="body2"
        >
          <strong>Descrição do plano</strong>
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          gutterBottom
          align="left"
          style={{
            whiteSpace: 'pre-wrap',
            maxHeight: showFullDescription ? 'none' : '15em',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <div dangerouslySetInnerHTML={returnHtmlContent(plan?.descricao)} />
        </Typography>
        <Link
          style={{
            alignItems: 'center',
            display: 'flex'
          }}
          component="button"
          variant="body2"
          onClick={toggleShowFullDescription}
        >
          {showFullDescription ? 'Ocultar detalhes' : 'Mostrar detalhes'}
          {showFullDescription ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Link>
        <Divider className={classes.divider} />
        <Grid style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} container>
          <Grid item>
            <Typography variant="h4" color="textPrimary" align="left">
              <strong>Valor total:</strong>&nbsp;
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h4" color="textPrimary" align="left">
                  <strong>{formatCurrency(planValue?.valor)}</strong>
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  justifyContent: 'flex-end',
                  display: 'flex'
                }}
              >
                <Typography
                  variant="caption"
                  align="right"
                  color="textSecondary"
                >
                  {`Valor ${mapPeriodicityChar(planValue?.periodicidade)}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </div>
  )
}

export default PlanPurchaseSummary
