import React, { useEffect } from 'react'
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Grid,
  Stepper,
  Step,
  Typography,
  StepButton
} from '@material-ui/core'
import {
  CardInformation,
  DotsMobileStepper,
  PurchaseApplicationFormInputs
} from './components'
import { useDispatch, useSelector } from 'react-redux'
import { setPurchaseApplicationFormStore, setActiveStep } from '_actions'
import {
  PurchaseApplicationFormItem,
  areAllRequiredItemsFilled
} from './purchaseApplicationFormUtils'
import { useCarrinhoItensEvento } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%'
  },
  stepper: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}))

type PurchaseApplicationFormProps = {
  eventoId: number
}
export default function PurchaseApplicationForm({
  eventoId
}: PurchaseApplicationFormProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const {
    data: carrinhoItens,
    isError,
    isFetching,
    refetch
  } = useCarrinhoItensEvento(Number(eventoId))

  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm
        ?.purchaseApplicationFormItens as PurchaseApplicationFormItem[]
  )
  const activeStep = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.activeStep as number
  )
  const totalItemCount = purchaseApplicationFormItens?.length

  const handleStepChange = (step: number) => {
    dispatch(setActiveStep(step))
  }

  // Check if all required items are filled
  const allRequiredItemsFilled = areAllRequiredItemsFilled(
    purchaseApplicationFormItens
  )

  useEffect(() => {
    const requiredItemsApplicationForm = carrinhoItens?.filter(
      (carrinhoItem) =>
        carrinhoItem?.carrinhoItem?.lote?.exigeInscricao === true &&
        carrinhoItem.carrinhoItem.quantidade > 0
    )
    // Criar um novo array de ingressos com base nas quantidades
    const newIngressosArray: PurchaseApplicationFormItem[] | undefined =
      requiredItemsApplicationForm?.flatMap((carrinhoItemDetails) =>
        Array.from(
          { length: carrinhoItemDetails?.carrinhoItem?.quantidade || 0 },
          (_, index) => ({
            carrinhoItemDetails: { ...carrinhoItemDetails },
            respostasCamposInscricao: undefined,
            sequencial: index,
            id: `${carrinhoItemDetails?.carrinhoItem?.id}_${index}`
          })
        )
      )
    if (newIngressosArray) {
      dispatch(setPurchaseApplicationFormStore(newIngressosArray))
    }
    return () => {
      dispatch(setPurchaseApplicationFormStore(undefined))
      dispatch(setActiveStep(0))
    }
  }, [carrinhoItens])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [activeStep])

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          {isMobile ? (
            <DotsMobileStepper />
          ) : (
            <Stepper
              nonLinear
              activeStep={activeStep}
              orientation={'vertical'}
              className={classes.stepper}
            >
              <Step key={0}>
                <StepButton
                  onClick={() => handleStepChange(0)}
                >{`Início`}</StepButton>
              </Step>
              {purchaseApplicationFormItens?.map((item, index) => {
                return (
                  <Step key={item?.id}>
                    <StepButton onClick={() => handleStepChange(index + 1)}>
                      {`Ingresso ${index + 1}`}
                    </StepButton>
                  </Step>
                )
              })}
              <Step disabled={!allRequiredItemsFilled} key={totalItemCount + 1}>
                <StepButton
                  disabled={!allRequiredItemsFilled}
                  onClick={() => handleStepChange(totalItemCount + 1)}
                >{`Revisão`}</StepButton>
              </Step>
            </Stepper>
          )}
        </Grid>
        <Grid item xs={12} md={8} style={{ display: 'flex' }}>
          {(activeStep === 0 || activeStep === totalItemCount + 1) && (
            <CardInformation />
          )}
          {activeStep !== 0 && activeStep !== totalItemCount + 1 && (
            <PurchaseApplicationFormInputs />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
