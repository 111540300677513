import React, { Component } from 'react'
import TopBarLoggedIn from './topBarLoggedIn.component'
import TopBarNotLoggedIn from './topBarNotLoggedIn.component'
import { connect } from 'react-redux'

class TopBar extends Component {
  render() {
    const token = this.props.token
    function isLoggedIn() {
      if (token !== null && token !== undefined) {
        return <TopBarLoggedIn />
      } else {
        return <TopBarNotLoggedIn />
      }
    }
    return isLoggedIn()
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.authentication.token
  }
}
export default connect(mapStateToProps)(TopBar)
