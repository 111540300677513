import { cpfCnpjMask, dataMask, phoneMask } from 'utils'

type CampoInfo = {
  inputType: 'text' | 'email'
  mask?: (value: string | undefined) => string
  autoCapitalize: 'none' | 'sentences' | 'words' | 'characters' | undefined
  placeholder: string | undefined
}

const campoDefault: CampoInfo = {
  inputType: 'text',
  autoCapitalize: 'sentences',
  placeholder: undefined
}
const keywordVariations: Record<string, string[]> = {
  email: ['email', 'e-mail'],
  telefone: ['telefone', 'phone'],
  data: ['data', 'Data'],
  cpfCnpj: ['cpf', 'cnpj', 'CPF/CNPJ']
}

const campoMapping: Record<string, CampoInfo> = {
  nome: {
    inputType: 'text',
    autoCapitalize: 'words',
    placeholder: 'Nome Completo'
  },
  email: {
    inputType: 'email',
    autoCapitalize: 'none',
    placeholder: 'Digite o e-mail'
  },
  telefone: {
    inputType: 'text',
    mask: phoneMask,
    autoCapitalize: 'none',
    placeholder: '(DD) 00000-0000'
  },
  data: {
    inputType: 'text',
    mask: dataMask,
    autoCapitalize: 'none',
    placeholder: '__/__/____'
  },
  cpfCnpj: {
    inputType: 'text',
    mask: cpfCnpjMask,
    autoCapitalize: 'none',
    placeholder: '___.___.___-__'
  },
  rg: {
    inputType: 'text',
    autoCapitalize: 'none',
    placeholder: 'Digite o RG'
  }
}

const getCampoInfo = (campoNome: string): CampoInfo => {
  const lowerCaseCampoNome = campoNome.toLowerCase()

  // Checking for exact match
  if (campoMapping[lowerCaseCampoNome]) {
    return campoMapping[lowerCaseCampoNome]
  }

  // Checking variations
  for (const [keyword, variations] of Object.entries(keywordVariations)) {
    for (const variation of variations) {
      if (lowerCaseCampoNome.includes(variation)) {
        return campoMapping[keyword]
      }
    }
  }

  // If no match, return default
  return campoDefault
}

export { getCampoInfo }
