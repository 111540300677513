import React from 'react'
import { toast } from 'react-toastify'
import {
  Typography,
  Button,
  Box,
  Divider,
  useMediaQuery,
  Theme
} from '@material-ui/core'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import { formatBoleto } from '@brazilian-utils/format-boleto'

//? Own imports
import { Pagamento } from 'types/api'

type BoletoProps = {
  payment: Pagamento
}

const Boleto = ({ payment }: BoletoProps) => {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(payment?.linhaDigitavelBoleto)
    toast.success('Feito! O código do boleto foi copiado.')
  }
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <Box>
      <Typography color="textSecondary" variant="body2" align="left">
        Abra o aplicativo ou banco de sua preferência e utilize o número copiado
        para pagar seu boleto:
      </Typography>
      {payment && payment?.linkBoleto && (
        <Box mt={2}>
          {/* @ts-ignore */}
          <Typography
            size="small"
            color="secondary"
            fullWidth
            style={{ marginBottom: 10 }}
          >
            <strong>
              {formatBoleto(
                payment && payment?.linhaDigitavelBoleto
                  ? payment?.linhaDigitavelBoleto
                  : ''
              )}
            </strong>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopyToClipboard}
            size="small"
            fullWidth
          >
            Copiar código
          </Button>
        </Box>
      )}
      <Box marginY={2}>
        <Divider />
      </Box>
      <Typography color="textSecondary" variant="body2" align="left">
        {isMobile
          ? 'Salve o boleto em seu celular e utilize-o para pagar em casas lotérias e agências bancárias. Não precisa imprimir 😀'
          : 'Salve o boleto em seu computador para imprimir ou pague pela internet utilizando o código de barras do boleto.'}
      </Typography>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          href={payment?.linkBoleto}
          target="_blank"
          size="small"
          fullWidth
        >
          Salvar boleto
        </Button>
      </Box>
      <Box marginY={2}>
        <Typography color="textSecondary" variant="body2" align="center">
          Prazo para pagamento até{' '}
          {format(
            new Date(payment.dataHoraValidade),
            `dd'/'MM'/'yyyy 'às' kk':'mm`,
            {
              locale: brLocale
            }
          )}
        </Typography>
      </Box>
      {/* <Typography align="left" variant="caption" color="textPrimary">
        Ahh, se o pagamento não for realizado dentro do prazo, não se preocupe.
        O pedido será cancelado automaticamente.
      </Typography> */}
    </Box>
  )
}

export default Boleto
