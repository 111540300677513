export const orderPaymentActions = {
  changePaymentMethod,
  setSelectedCreditCard,
  setCreditCardHash,
}

function changePaymentMethod(paymentMethodId) {
  return { type: 'CHANGE_PAYMENT_METHOD', payload: paymentMethodId }
}
function setSelectedCreditCard(selectedCreditCard) {
  return { type: 'SET_SELECTED_CREDIT_CARD', payload: selectedCreditCard }
}
function setCreditCardHash(payload) {
  return { type: 'SET_CREDIT_CARD_HASH', payload: { creditCardHash: payload.creditCardHash, billingAddress: payload.billingAddress } }
}
