import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

//? Own imports
import { usePlanValue } from 'hooks-querys'
import { DialogUserNecessaryDataToPurchase } from 'components'
import { useRouter, validateUserNecessaryData } from 'utils'
import { PlanPurchaseSummary, PaymentMethods } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4, 0, 10, 0) }
  },
  subtitle: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
  },
  paper: {
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 2px 16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2)
      // minHeight: '60vh'
    },
    [theme.breakpoints.down('sm')]: {}
  }
}))

const PlanPurchaseDetail = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const router = useRouter()
  const { planValueId } = useParams<{ planValueId: string }>()

  const {
    data,
    isError,
    isLoading: isLoadingPlanValue,
    refetch
  } = usePlanValue({
    planValueId: Number(planValueId)
  })
  const dispatch = useDispatch()
  //@ts-ignore
  const { user, loggingIn } = useSelector((state) => state.authentication)

  useEffect(() => {
    if (!loggingIn && user && user.id) {
      validateUserNecessaryData(dispatch, user)
    }
  }, [user, loggingIn])

  return (
    <Container maxWidth="lg" className={classes.root}>
      <DialogUserNecessaryDataToPurchase />
      {isLoadingPlanValue ? (
        <Box marginY={4}>
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={isMobile ? 0 : 2}
          direction={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid item sm={12} md={8}>
            <Typography
              align="left"
              gutterBottom
              variant="h5"
              className={classes.subtitle}
            >
              <b>{'Detalhes de pagamento'}</b>
            </Typography>
            <Paper className={classes.paper}>
              {data ? <PaymentMethods planValue={data} /> : null}
            </Paper>
          </Grid>
          <Grid item sm={12} md={4}>
            <Typography
              align="left"
              className={classes.subtitle}
              gutterBottom
              variant="h5"
            >
              <b>Resumo da associação</b>
            </Typography>
            {data ? (
              <Paper className={classes.paper}>
                <PlanPurchaseSummary planValue={data} />
              </Paper>
            ) : null}
          </Grid>
        </Grid>
      )}
    </Container>
  )
}

export default PlanPurchaseDetail
