import brLocale from 'date-fns/locale/pt-BR'
import { format, isSameDay } from 'date-fns'
import { createDynamicLink } from '_services/firebaseDynamicLinks'
import toISODateFormat from './toISODateFormat'

const dateFormatted = (dataHoraInicio, dataHoraFim) => {
  if (isSameDay(new Date(dataHoraInicio), new Date(dataHoraFim))) {
    return `${format(
      new Date(dataHoraInicio),
      `eee, d 'de' MMMM 'de' yyyy 'às' H:mm`,
      {
        locale: brLocale
      }
    )} até ${format(new Date(dataHoraFim), `H:mm`, {
      locale: brLocale
    })}`
  } else {
    return `Começa: ${format(
      new Date(dataHoraInicio),
      `eee, d 'de' MMMM 'de' yyyy 'às' H:mm`,
      {
        locale: brLocale
      }
    )} - Termina:  ${format(
      new Date(dataHoraFim),
      `eee, d 'de' MMMM 'de' yyyy 'às' H:mm`,
      {
        locale: brLocale
      }
    )}`
  }
}

export const onShare = async (event) => {
  if (undefined === event) return
  const dynamicLink = await createDynamicLink({
    dynamicLinkInfo: {
      domainUriPrefix: 'corujas.page.link',
      link: `https://corujasapp.com/event-details/${event.id}`,
      androidInfo: {
        androidPackageName: 'com.quintos.corujas',
        androidMinPackageVersionCode: '1'
      },
      iosInfo: {
        iosBundleId: 'com.quintos.corujas',
        iosAppStoreId: '1593667736',
        iosFallbackLink: `https://corujasapp.com/event-details/${event.id}`
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      socialMetaTagInfo: {
        socialTitle: !!event && event.nome.toUpperCase(),
        socialDescription:
          event &&
          event.dataHoraInicio &&
          dateFormatted(
            toISODateFormat(event.dataHoraInicio),
            toISODateFormat(event.dataHoraFim)
          ),
        socialImageLink: !!event && event.imagem
      }
    },
    suffix: {
      option: 'SHORT'
    }
  })

  const link = dynamicLink || `https://corujasapp.com/event-details/${event.id}`

  return link
}

export const onShareEstablishment = async (establishment) => {
  if (undefined === establishment) return
  const dynamicLink = await createDynamicLink({
    dynamicLinkInfo: {
      domainUriPrefix: 'corujas.page.link',
      link: `https://corujasapp.com/estabelecimento-profile/${establishment.id}`,
      androidInfo: {
        androidPackageName: 'com.quintos.corujas',
        androidMinPackageVersionCode: '1'
      },
      iosInfo: {
        iosBundleId: 'com.quintos.corujas',
        iosAppStoreId: '1593667736',
        iosFallbackLink: `https://corujasapp.com/estabelecimento-profile/${establishment.id}`
      },
      navigationInfo: {
        enableForcedRedirect: true
      },
      socialMetaTagInfo: {
        socialTitle: `${establishment.nome.toUpperCase()} (@${establishment.username
          })`,
        socialDescription: establishment.detalhes,
        socialImageLink: !!establishment && establishment.imagem
      }
    },
    suffix: {
      option: 'SHORT'
    }
  })
  const link =
    dynamicLink ||
    `https://corujasapp.com/estabelecimento-profile/${establishment.id}`

  return link
}
