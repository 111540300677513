import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Card,
  CardContent,
  CardActions,
  Avatar,
  Typography,
  Button,
  withStyles,
  Divider,
  Tooltip,
  CircularProgress,
  Backdrop,
  Grid
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import getInitials from 'utils/getInitials'
import ImageUploading from 'react-images-uploading'
import { uploadImage } from '_services'
import { usuarioActions } from '_actions'
import { useSelector } from 'react-redux'
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone'
import { useConfirm } from 'material-ui-confirm'
import { errorsUploadImage } from 'utils'

const styles = (theme) => ({
  root: {},
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100,
    cursor: 'pointer'
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ProfileDetails = (props) => {
  const { className, ...rest } = props
  const [images, setImages] = useState([])
  const [open, setOpen] = useState(false)
  const usuario = useSelector((state) => state.authentication.user)
  const { classes } = props
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      let payload = {
        ...usuario,
        imagem: null
      }
      dispatch(usuarioActions.editUsuarioInfo(usuario.id, payload))
      dispatch(usuarioActions.onChangeProps('imagem', null))
    })
  }

  const onChange = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {
        let payload = {
          ...usuario,
          imagem: response
        }
        dispatch(usuarioActions.editUsuarioInfo(usuario.id, payload))
        dispatch(usuarioActions.onChangeProps('imagem', response))
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Backdrop className={classes.backdrop} open={open}>
        <Grid
          container
          spacing={3}
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
        >
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="white">
              Enviando imagem...
            </Typography>
          </Grid>
        </Grid>
      </Backdrop>
      <ImageUploading
        maxFileSize={2000000}
        onError={(errors) => errorsUploadImage(errors)}
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          // write your building UI
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent {...dragProps} className={classes.content}>
              {isDragging ? (
                <>
                  <CloudUploadTwoToneIcon
                    color="primary"
                    style={{ fontSize: 80 }}
                  />
                  <Typography variant="h3" color="initial">
                    Solte a imagem aqui!
                  </Typography>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      usuario.imagem !== null && usuario.imagem !== ''
                        ? 'Clique para trocar a imagem!'
                        : 'Clique para adicionar uma imagem!'
                    }
                  >
                    <Avatar
                      onClick={onImageUpload}
                      className={classes.avatar}
                      src={usuario.imagem}
                    >
                      {getInitials(usuario.nome)}
                    </Avatar>
                  </Tooltip>
                  <Typography
                    className={classes.name}
                    gutterBottom
                    align="center"
                    variant="h3"
                  >
                    {usuario.nome}
                  </Typography>
                  {/* <Typography color="textSecondary" variant="body1">
                    {usuario.tipoUsuario.descricao}
                  </Typography> */}
                  <Typography color="textSecondary" variant="body2">
                    {usuario.email}
                  </Typography>
                </>
              )}
            </CardContent>
            <Divider />
            <CardActions className={classes.uploadButton}>
              <Button color="primary" variant="text" onClick={onImageUpload}>
                {usuario.imagem !== null && usuario.imagem !== ''
                  ? 'Trocar imagem'
                  : 'Carregar imagem'}
              </Button>
              {usuario.imagem !== null && usuario.imagem !== '' && (
                <Button variant="text" onClick={removeImage}>
                  Remover imagem
                </Button>
              )}
            </CardActions>
          </Card>
        )}
      </ImageUploading>
    </div>
  )
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    usuario: state.authentication.usuario
  }
}
const connectedProfileDetailsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ProfileDetails))
)
export default connectedProfileDetailsPage
