import React, { useState, useEffect } from 'react'
import {
  Typography,
  makeStyles,
  Button,
  TextField,
  Grid,
  Link,
  InputAdornment,
  CircularProgress
} from '@material-ui/core'
import { useDiscountCoupon } from 'hooks-querys'
import { useSelector } from 'react-redux'
import { CarrinhoItem } from 'types/api'
import { calculaValorDesconto } from 'utils/purchaseCalculate'

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2)
  },
  grid: {
    minHeight: '80px'
  }
}))

function CupomDesconto({
  carrinhoItens,
  disabled
}: {
  carrinhoItens: CarrinhoItem[]
  disabled: boolean
}) {
  const classes = useStyles()
  const [inputText, setInputText] = useState('')
  const [queryEnabled, setQueryEnabled] = useState(false)
  const { couponCodeByLink: couponCodeGeneratedBy } = useSelector(
    //@ts-ignore
    (state) => state.global
  )

  const eventId = carrinhoItens[0]?.lote?.eventoId

  useEffect(() => {
    if (
      couponCodeGeneratedBy?.couponCode &&
      couponCodeGeneratedBy?.eventId == eventId
    ) {
      setInputText(couponCodeGeneratedBy?.couponCode)
    }
  }, [couponCodeGeneratedBy])

  useEffect(() => {
    if (
      couponCodeGeneratedBy?.couponCode &&
      couponCodeGeneratedBy?.eventId == eventId
    ) {
      setQueryEnabled(true)
    }
  }, [inputText])

  const { data, isLoading, remove, refetch, isFetching } = useDiscountCoupon(
    inputText,
    carrinhoItens.filter(
      (carrinhoItem) => carrinhoItem.id != 0 && carrinhoItem.quantidade > 0
    ),
    queryEnabled
  )
  const discountCouponApply =
    data && data.carrinhoItens && data.carrinhoItens.length > 0

  const formatInputText = (text: string) => {
    const textFormatted = text.trim()
    const textFormattedUpperCase = textFormatted.toUpperCase()
    setInputText(textFormattedUpperCase)
  }
  const removeDiscountCoupon = () => {
    setQueryEnabled(false)
    refetch()
    remove()
  }
  useEffect(() => {
    return () => {
      setQueryEnabled(false)
      remove()
    }
  }, [remove])

  useEffect(() => {
    setQueryEnabled(false)
  }, [isFetching])

  const valorCupomAplicado = () => {
    if (data.cupomDesconto.percentualDesconto > 0) {
      return ` (-${data.cupomDesconto.percentualDesconto}%) `
    } else if (data.cupomDesconto.valorDesconto > 0) {
      return ` (${Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(data.cupomDesconto.valorDesconto)} por item) `
    }
  }
  const haveCouponCodeApplied = data?.carrinhoItens
  const totalDiscountValueApplied = calculaValorDesconto({
    carrinhoItens: data?.carrinhoItens || [],
    cupomDesconto: data?.cupomDesconto
  })
  const hiddenDetails =
    haveCouponCodeApplied &&
    totalDiscountValueApplied <= 0 &&
    couponCodeGeneratedBy?.couponCode &&
    couponCodeGeneratedBy?.eventId == eventId

  if (hiddenDetails) return null
  return (
    <div className={classes.content}>
      {!discountCouponApply ? (
        <Typography align="left" variant="body2" color="primary">
          Se você possui um cupom de desconto, aplique-o abaixo:
        </Typography>
      ) : (
        <Typography align="center" variant="body1" color="primary">
          O cupom foi aplicado com <strong>sucesso! 🤑</strong>
        </Typography>
      )}
      <Grid
        className={classes.grid}
        container
        direction="row"
        spacing={2}
        justify="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          {discountCouponApply ? (
            <>
              <Typography variant="body2" color="initial">
                Cupom aplicado: <strong> {data.cupomDesconto.codigo}</strong>{' '}
                {valorCupomAplicado()}
              </Typography>
              {couponCodeGeneratedBy &&
              couponCodeGeneratedBy?.eventId == eventId ? null : (
                <Link
                  component="button"
                  variant="body2"
                  onClick={removeDiscountCoupon}
                >
                  Remover cupom
                </Link>
              )}
            </>
          ) : (
            <form
              id="form-codigo-cupom"
              onSubmit={(event) => {
                event.preventDefault()
                setQueryEnabled(true)
              }}
            >
              <TextField
                required
                fullWidth
                disabled={disabled || isLoading}
                id="codigo-cupom"
                label="Código cupom"
                placeholder="Insira o código de desconto"
                size="small"
                variant="outlined"
                value={inputText}
                onChange={(e) => {
                  formatInputText(e.target.value)
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <Button
                          disabled={
                            inputText.length < 1 || isLoading || disabled
                          }
                          variant="text"
                          color="primary"
                          fullWidth
                          type="submit"
                          form="form-codigo-cupom"
                        >
                          Aplicar
                        </Button>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </form>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default CupomDesconto
