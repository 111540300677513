import { authServices, crudService } from '../_services/'
import { history } from '../_helpers'
import { toast } from 'react-toastify'
import { usuarioActions } from './usuario.actions'

export const authActions = {
  login,
  facebookLogin,
  logout,
  setAuthDetails,
  refresh,
  openModalLogin,
  setIsRegister
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }))
    let apiEndpoint = 'authentication/request'
    let payload = {
      username: username,
      password: password
    }
    authServices
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(usuarioActions.initUsuarioDetails(response.data.usuario))
        const lastVisitedRoute = localStorage.getItem('lastVisitedRoute')
        if (lastVisitedRoute) {
          history.push(lastVisitedRoute)
          localStorage.removeItem('lastVisitedRoute')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        // toast.error('Opss! Problema ao autenticar usuário! 😥')
      })
  }

  function request(user) {
    return { type: 'LOGIN_REQUEST', user }
  }

  function success(user) {
    return {
      type: 'LOGIN_SUCCESS',
      auth: user.auth,
      token: user.token,
      expires: user.expires,
      user: user.usuario
    }
  }

  function failure(error) {
    return { type: 'LOGIN_FAILURE', error }
  }
}

function refresh() {
  return (dispatch) => {
    const token = localStorage.getItem('token')
    dispatch(request(token))
    if (token !== null && token !== undefined) {
      let apiEndpoint = 'authentication/refresh/?admin=false'
      crudService
        .post(apiEndpoint)
        .then((response) => {
          dispatch(success(response.data))
          dispatch(usuarioActions.initUsuarioDetails(response.data.usuario))
          localStorage.setItem('user', JSON.stringify(response.data.usuario))
        })
        .catch((err) => {
          dispatch(failure(err))
          dispatch(logout())
        })
    } else {
      dispatch(logout())
    }
  }

  function request(token) {
    return { type: 'AUTHENTICATION_REFRESH_REQUEST', token }
  }

  function success(user) {
    return {
      type: 'AUTHENTICATION_REFRESH_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'AUTHENTICATION_REFRESH_FAILURE', error }
  }
}

function facebookLogin(facebookToken, createUser) {
  return (dispatch) => {
    dispatch(request({ createUser }))
    let apiEndpoint = 'authentication/facebook'
    let payload = {
      facebookToken: facebookToken,
      createUser: createUser
    }
    authServices
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.data.token) {
          dispatch(success(response.data))
          dispatch(usuarioActions.initUsuarioDetails(response.data.usuario))
          const lastVisitedRoute = localStorage.getItem('lastVisitedRoute')
          if (lastVisitedRoute) {
            history.push(lastVisitedRoute)
            localStorage.removeItem('lastVisitedRoute')
          }
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        //toast.error('Opss! Problema ao autenticar usuário! 😥')
      })
  }

  function request(user) {
    return { type: 'LOGIN_REQUEST', user }
  }

  function success(user) {
    return {
      type: 'LOGIN_SUCCESS',
      auth: user.auth,
      token: user.token,
      expires: user.expires,
      user: user.usuario
    }
  }

  function failure(error) {
    return { type: 'LOGIN_FAILURE', error }
  }
}
function openModalLogin() {
  return { type: 'OPEN_MODAL_LOGIN' }
}
function setIsRegister() {
  return { type: 'SET_IS_REGISTER' }
}
function logout() {
  return (dispatch) => {
    authServices.logout()
    dispatch(logoutUser())
  }
}
export function setAuthDetails(user) {
  return {
    type: 'UPDATE_USER_DETAILS',
    user: user.user
  }
}
export function logoutUser() {
  return {
    type: 'LOGOUT_SUCCESS'
  }
}
