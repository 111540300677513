import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  Box
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import {
  PurchaseApplicationFormItem,
  areAllRequiredItemsFilled,
  getFirstIncompleteItem
} from '../../purchaseApplicationFormUtils'
import PurchaseApplicationFormTicketItem from '../PurchaseApplicationFormTicketItem'
import { setActiveStep } from '_actions'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.5)
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}))

const CardInformation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // @ts-ignore
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm
        ?.purchaseApplicationFormItens as PurchaseApplicationFormItem[]
  )

  const totalRequiredItemsApplicationForm = purchaseApplicationFormItens?.length
  const descriptionMessage = `Você tem ${
    totalRequiredItemsApplicationForm || '...'
  } ${pluralize('ingresso', totalRequiredItemsApplicationForm)} que ${
    totalRequiredItemsApplicationForm === 1 ? 'precisa' : 'precisam'
  } de informações adicionais antes de concluir a compra.

Preencha os dados adicionais para prosseguir.`

  // Check if all required items are filled
  const allRequiredItemsFilled = areAllRequiredItemsFilled(
    purchaseApplicationFormItens
  )

  const handlePressButton = () => {
    // Function to get the first item that doesn't have all fields filled
    const firstIncompleteItem = getFirstIncompleteItem(
      purchaseApplicationFormItens
    )
    const currentIngressoIndex = purchaseApplicationFormItens?.findIndex(
      (item) => item?.id === firstIncompleteItem?.id
    )
    if (firstIncompleteItem) {
      dispatch(setActiveStep(currentIngressoIndex + 1))
    } else {
      if (allRequiredItemsFilled) {
        dispatch(setActiveStep(purchaseApplicationFormItens?.length + 1))
      }
    }
  }

  return (
    <div className={classes.container}>
      <Typography gutterBottom={isMobile} variant={'body1'} align="left">
        {!allRequiredItemsFilled
          ? descriptionMessage
          : 'Você finalizou o formulário de inscrição. Revise os dados abaixo:'}
      </Typography>
      <Box marginTop={2}>
        <Grid container spacing={2} alignItems="stretch">
          {purchaseApplicationFormItens?.map(
            (purchaseApplicationFormItem, index) => (
              <Grid item key={index.toString()} xs={12} sm={12}>
                <PurchaseApplicationFormTicketItem
                  purchaseApplicationFormItem={purchaseApplicationFormItem}
                />
              </Grid>
            )
          )}
        </Grid>
      </Box>
      {!allRequiredItemsFilled ? (
        <Box marginTop={2}>
          <Typography variant="body1" align="center">
            Clique no botão abaixo para iniciar o preenchimento dos dados
          </Typography>

          <Box paddingTop={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePressButton}
            >
              PREENCHER FORMULÁRIO
            </Button>
          </Box>
        </Box>
      ) : null}
    </div>
  )
}

export default CardInformation
