import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  IconButton,
  Paper,
  InputBase,
  Divider,
  Tooltip
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { connect, useDispatch } from 'react-redux'
import { searchActions } from '../../_actions/search.actions'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}))

const InputSearchDown = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleChange = (prop) => (event) => {
    dispatch(searchActions.onChangeProps(prop, event))
  }

  const buttonCloseOnClick = (prop) => (event) => {
    dispatch(searchActions.clearText(prop))
  }

  const handleSubmited = (event) => {
    if (event.which == 13) {
      event.preventDefault()
      if (props.search.texto.length > 0) {
        let payload = {
          Texto: props.search.texto,
          Categorias: props.search.categorias
        }
        dispatch(searchActions.searchEventos(payload))
      }
    }
  }
  const buttonClick = (event) => {
    if (props.search.texto.length > 0) {
      event.preventDefault()
      let payload = {
        Texto: props.search.texto,
        Categorias: props.search.categorias
      }
      dispatch(searchActions.searchEventos(payload))
    }
  }
  return (
    <div className={classes.root}>
      <Paper elevation={1} component="form" className={classes.root}>
        <InputBase
          disabled={props.search.searching}
          className={classes.input}
          onKeyPress={handleSubmited}
          value={props.search.texto}
          onChange={handleChange('texto')}
          placeholder="Busque eventos e locais"
          inputProps={{ 'aria-label': 'Busque eventos e locais' }}
        />
        {props.search.searching ? (
          <CircularProgress
            color="primary"
            size={24}
            className={classes.buttonProgress}
          />
        ) : (
          props.search.texto.length >= 1 && (
            <IconButton
              onClick={buttonCloseOnClick('texto')}
              color="primary"
              className={classes.iconButton}
            >
              <CloseIcon opacity="0.8" />
            </IconButton>
          )
        )}
        <Divider className={classes.divider} orientation="vertical" />
        <Tooltip placement="left" title="Procurar">
          <IconButton
            disabled={props.search.searching}
            color="primary"
            onClick={buttonClick}
            className={classes.iconButton}
            aria-label="menu"
          >
            <SearchIcon />
          </IconButton>
        </Tooltip>
        {/* <Tooltip placement="right" title="Mais opções de busca">
          <IconButton disabled={props.search.searching} color="primary" className={classes.iconButton}>
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    search: state.search
  }
}
export default connect(mapStateToProps)(InputSearchDown)
