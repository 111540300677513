import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  withStyles,
  Tooltip
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import { authActions } from '../../../../_actions'
import { history } from '../../../../_helpers'
import { withRouter } from 'react-router-dom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

const styles = () => ({
  root: {},
  flexGrow: {
    flexGrow: 1
  }
})

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props
  const { classes } = props
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(authActions.logout())
  }

  return (
    <AppBar
      color="secondary"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            width={'140px'}
            alt="Logo"
            src={require('../../../../img/img-login.svg')}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton color="inherit"></IconButton>

        <Hidden mdDown>
          <Tooltip title="Sair">
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={logout}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    usuario: state.usuario
  }
}
const TopbarPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Topbar))
)
export default TopbarPage
