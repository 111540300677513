import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { Estabelecimento } from 'types/api'
import { history } from '_helpers'
import { useEstablishmentAssociationPlansList } from 'hooks-querys'
import { getImageUrls, getInitials } from 'utils'
import theme from 'theme'
import Lottie from 'react-lottie'

type ModalMemberProps = {
  establishment: Estabelecimento
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 4)
    },
    closeButton: {
      position: 'absolute',
      color: theme.palette.grey[500],
      right: theme.spacing(1)
    },
    title: {
      flex: 1,
      textAlign: 'center'
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(MuiDialogActions)

const ModalMember = ({ establishment }: ModalMemberProps) => {
  const { data } = useEstablishmentAssociationPlansList({
    establishmentId: establishment?.id
  })
  const havePlanAvailable = data?.length && data?.length > 0
  //@ts-ignore
  const userData = useSelector((state) => state.authentication.user)
  //! TODO: add support for is member
  // const userIsMember = establishment?.usuarioMembro
  const userIsMember = false

  const [showModal, setShowModal] = useState(false)
  const title = userIsMember
    ? `Canal Exclusivo de Membros`
    : `Associação Disponível`
  const description = userIsMember
    ? `Parabéns! Você já é um membro do nosso canal exclusivo em ${establishment?.nome}. Acesse agora mesmo e aproveite os benefícios!`
    : `Descubra os benefícios de se tornar um membro de ${establishment?.nome} e aproveite vantagens exclusivas.`
  const buttonTitle = userIsMember
    ? 'Acessar Canal de Membros (Em breve)'
    : 'Ver Planos Disponíveis'

  const navigateToMemberChannel = () => {}
  const navigateToEstablishmentPlans = () => {
    history.push(`/establishment-association-plans/${establishment?.id}`)
  }
  const handleButtonPress = () => {
    setShowModal(false)
    if (userIsMember) {
      navigateToMemberChannel()
    } else {
      navigateToEstablishmentPlans()
    }
  }
  const handleClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (havePlanAvailable) {
      setShowModal(true)
    }
  }, [data])

  return (
    <Dialog
      maxWidth="xs"
      // onClose={handleClose}
      aria-labelledby="dialog-member"
      open={showModal}
      //@ts-ignore
      TransitionComponent={Transition}
    >
      <DialogTitle id="dialog-member" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            padding: theme?.spacing(4, 2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Avatar
            style={{
              height: 110,
              width: 110
            }}
            src={getImageUrls(userData?.imagem)?.thumbnailUrl}
            alt={getInitials(userData?.nome)}
          >
            {getInitials(userData?.nome)}
          </Avatar>
          <Box
            sx={{
              position: 'absolute',
              top: 50
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: require('assets/animations/crown.json'),
                rendererSettings: {}
              }}
              width={100}
            />
          </Box>
        </Box>
        <Typography gutterBottom>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          autoFocus
          onClick={handleButtonPress}
          color="primary"
          variant="contained"
        >
          {buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalMember
