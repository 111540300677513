import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Tabs,
  Tab,
  colors,
  withStyles,
  useTheme,
  useMediaQuery,
  Divider
} from '@material-ui/core'

import { Page, SearchBar, TicketsSkeleton } from 'components'
import { Header, MyTicketsDetails } from './components'
import { history } from '_helpers'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ingressoAction } from '_actions'
import { useDidMountEffect } from 'components/Utils/useDidMountEffect'
import { paymentFeedback } from 'utils'
import { addDays, isFuture, isPast } from 'date-fns'
import { useUserTickets } from 'hooks-querys'

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 50,
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }
  }
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  }
}))

const MyTickets = (props) => {
  const { match } = props
  const classes = useStyles()
  const { tab } = match.params
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const usuarioId = useSelector((state) => state.authentication.user.id)
  const { data: ingressos, isLoading } = useUserTickets(usuarioId)

  const ticketsListFutureEvents =
    ingressos &&
    ingressos.length > 0 &&
    ingressos.filter((ticket) =>
      isFuture(addDays(new Date(ticket.lote.evento.dataHoraInicio), 1))
    )
  const ticketsListPastEvents =
    ingressos &&
    ingressos.length > 0 &&
    ingressos.filter((ticket) =>
      isPast(new Date(ticket.lote.evento.dataHoraFim))
    )

  const status = new URL(window.location.href).searchParams.get('status')

  useEffect(() => {
    paymentFeedback(status)
  }, [])

  const handleTabsChange = (event, value) => {
    history.push(value)
  }

  const tabs = [
    { value: 'ativos', label: 'Ativos', icon: <EventAvailableTwoToneIcon /> },
    {
      value: 'encerrados',
      label: 'Encerrados',
      icon: <EventBusyTwoToneIcon />
    }
  ]
  if (!tab) {
    return <Redirect to="/approot/perfil/tickets/ativos" />
  }

  if (!tabs.find((t) => t.value === tab)) {
    return <Redirect to="/errors/error-404" />
  }

  return (
    <Page className={classes.root} title="Minha conta - Ingressos">
      <Header />
      <StyledTabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant={isMobile ? 'fullWidth' : 'scrollable'}
      >
        {tabs.map((tab) => (
          <StyledTab
            icon={tab.icon}
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </StyledTabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {isLoading ? (
          <TicketsSkeleton />
        ) : (
          <MyTicketsDetails
            ingressos={
              tab === 'ativos' ? ticketsListFutureEvents : ticketsListPastEvents
            }
          />
        )}
      </div>
    </Page>
  )
}

MyTickets.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default MyTickets
