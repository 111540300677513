const BASE_URL = 'https://corujasimg.s3.amazonaws.com/images'
const COMPRESSED_BASE_URL =
  'https://corujasimg-compressed.s3.amazonaws.com/compressed-images'
const THUMBNAIL_BASE_URL =
  'https://corujasimg-thumbnail.s3.amazonaws.com/thumb-compressed-images'

export default function getImageUrls(imageUrl: string | undefined) {
  if (!imageUrl) return undefined

  const isCorujasImg =
    imageUrl.includes('corujas.s3') || imageUrl.includes('corujasimg')

  if (isCorujasImg) {
    const imageName = imageUrl.split('/').pop()
    const environmentMatch = imageUrl.match(/\/(\w+)\/[^/]+$/)

    const environment = environmentMatch ? environmentMatch[1] : 'development'

    const originalUrl = `${BASE_URL}/${environment}/${imageName}`
    const compressedUrl = `${COMPRESSED_BASE_URL}/${environment}/${imageName}`
    const thumbnailUrl = `${THUMBNAIL_BASE_URL}/${environment}/${imageName}`

    return { originalUrl, compressedUrl, thumbnailUrl }
  } else {
    return {
      originalUrl: imageUrl,
      compressedUrl: imageUrl,
      thumbnailUrl: imageUrl
    }
  }
}
