import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper, Container, Grid } from '@material-ui/core'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { searchActions } from '../../_actions'
import 'moment-timezone'
import EventosList from '../../components/EventosList'
import { Carrossel } from './carrossel.component'
import { SearchInput } from '../../components'
import { isEmpty } from 'lodash'
import { useDidMountEffect } from '../../components/Utils'
import { CategoriasList } from 'components/CategoriasList'
const drawerWidth = 0

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  rootSearch: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(18)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(9)
    },
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 'bold'
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(7),
      paddingBottom: theme.spacing(4)
    }
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    paddingBottom: theme.spacing(4)
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  }
})

const Home = (props) => {
  const dispatch = useDispatch()
  const { classes } = props
  const eventosCarrossel = props.eventos.eventoCarrossel
  const eventosLocalizacao = props.eventos.eventoLocalizacao
  const eventosProximos = props.eventos.evento
  const [latLon, setLatLon] = useState({
    LatLon: {
      x: 0,
      y: 0
    }
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        setLatLon({
          LatLon: {
            x: latitude,
            y: longitude
          }
        })
      },
      (error) => {
        dispatch(searchActions.searchHome(latLon))
      }
    )
  }, [])

  useDidMountEffect(() => {
    dispatch(searchActions.searchHome(latLon))
  }, [latLon])

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.content}>
          {!isEmpty(eventosCarrossel) && (
            <Carrossel evento={eventosCarrossel} />
          )}
          <Container className={classes.cardGrid}>
            <div className={classes.rootSearch}>
              <SearchInput></SearchInput>
            </div>
          </Container>
        </main>
      </div>
      {!isEmpty(eventosLocalizacao) && (
        <EventosList eventos={eventosLocalizacao} title={'Perto de mim'} />
      )}
      <CategoriasList />
      {!isEmpty(eventosProximos) && (
        <EventosList eventos={eventosProximos} title={'Próximos eventos'} />
      )}
    </div>
  )
}
Home.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    searching: state.search.searching,
    eventos: state.search.result
  }
}
const connectedHomePage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Home))
)
export { connectedHomePage as Home }
