import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded'
import { Pix as PixIcon, Boleto as BoletoIcon } from 'icons'
import { CreditCard, Pix, Boleto, CardPaymentSuccess } from './components'
import {
  Button,
  colors,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { differenceInBusinessDays, format } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { orderPaymentActions } from '_actions'
import { useEffect } from 'react'
import brLocale from 'date-fns/locale/pt-BR'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { history } from '_helpers'
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import CardMembershipIcon from '@material-ui/icons/CardMembership'

import { Free } from './components'
import { useDidMountEffect } from 'components/Utils'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    }
  },
  tabs: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .Mui-selected': {
      fontWeight: 'bold'
    }
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}))

export default function PaymentMethods(props) {
  const { evento, isOrderGenerated, order, totalValue, isCheckIn } = props
  const eventoId = evento?.id
  const classes = useStyles()
  const paymentMethods = [
    {
      id: null,
      label: 'Gratuito',
      icon: isCheckIn ? (
        <CardMembershipIcon className={classes.icon} />
      ) : (
        <CardGiftcardIcon className={classes.icon} />
      ),
      component: Free
    },
    {
      id: 3,
      label: 'Pix',
      icon: <PixIcon className={classes.icon} />,
      component: Pix
    },
    {
      id: 1,
      label: 'Cartão de Crédito',
      icon: <CreditCardRoundedIcon className={classes.icon} />,
      component: CreditCard
    },
    {
      id: 2,
      label: 'Boleto',
      icon: <BoletoIcon className={classes.icon} />,
      component: null
    }
  ]

  const { paymentMethodId, creditCardHash } = useSelector(
    (state) => state.orderPayment
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const handleChange = (event, newValue) => {
    dispatch(orderPaymentActions.changePaymentMethod(newValue))
  }
  const allowBoleto =
    evento &&
    totalValue >= 79 &&
    differenceInBusinessDays(new Date(evento.dataHoraInicio), new Date()) > 2
  const refCreditCard = useRef(null)
  const scrollToCreditCardView = () =>
    refCreditCard.current.scrollIntoView(true)

  useEffect(() => {
    if (isOrderGenerated && order && order.formaPagamentoId) {
      dispatch(
        orderPaymentActions.changePaymentMethod(
          order.formaPagamentoId === 3 ? 0 : order.formaPagamentoId
        )
      )
    }
  }, [isOrderGenerated, order])

  useEffect(() => {
    return () => {
      dispatch(orderPaymentActions.changePaymentMethod(0))
    }
  }, [])

  useEffect(() => {
    totalValue === 0 && !isOrderGenerated
      ? dispatch(orderPaymentActions.changePaymentMethod(null))
      : paymentMethodId === null &&
        dispatch(orderPaymentActions.changePaymentMethod(0))
  }, [totalValue])

  useDidMountEffect(() => {
    if (creditCardHash !== '') {
      isMobile
        ? scrollToCreditCardView()
        : window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [creditCardHash])

  return (
    <div className={classes.root} ref={refCreditCard}>
      <Grid container spacing={1}>
        {paymentMethodId === null && totalValue === 0 ? (
          <Grid item xs={12} md={4}>
            <Tabs
              orientation={!isMobile ? 'vertical' : 'horizontal'}
              variant={!isMobile ? 'scrollable' : 'standard'}
              centered={isMobile}
              value={paymentMethodId}
              className={classes.tabs}
              indicatorColor="primary"
              textColor="secondary"
            >
              <Tab
                value={null}
                id={null}
                selected={true}
                classes={
                  !isMobile
                    ? {
                        wrapper: classes.iconLabelWrapper,
                        labelContainer: classes.labelContainer
                      }
                    : {}
                }
                icon={
                  isCheckIn ? (
                    <CardMembershipIcon className={classes.icon} />
                  ) : (
                    <CardGiftcardIcon className={classes.icon} />
                  )
                }
                label={'Gratuito'}
                {...a11yProps(null)}
              />
            </Tabs>
          </Grid>
        ) : (
          <Grid item xs={12} md={4}>
            {!isOrderGenerated ? (
              <Tabs
                orientation={!isMobile ? 'vertical' : 'horizontal'}
                variant={!isMobile ? 'scrollable' : 'standard'}
                centered={isMobile}
                value={paymentMethodId}
                onChange={handleChange}
                className={classes.tabs}
                indicatorColor="primary"
                textColor="secondary"
              >
                <Tab
                  classes={
                    !isMobile
                      ? {
                          wrapper: classes.iconLabelWrapper,
                          labelContainer: classes.labelContainer
                        }
                      : {}
                  }
                  icon={
                    <PixIcon
                      outlined={paymentMethodId !== 0}
                      className={classes.icon}
                    />
                  }
                  label="PIX"
                  {...a11yProps(1)}
                />
                <Tab
                  classes={
                    !isMobile
                      ? {
                          wrapper: classes.iconLabelWrapper,
                          labelContainer: classes.labelContainer
                        }
                      : {}
                  }
                  icon={<CreditCardRoundedIcon className={classes.icon} />}
                  label="Cartão de credito"
                  {...a11yProps(0)}
                />
                {allowBoleto && (
                  <Tab
                    classes={
                      !isMobile
                        ? {
                            wrapper: classes.iconLabelWrapper,
                            labelContainer: classes.labelContainer
                          }
                        : {}
                    }
                    icon={<BoletoIcon className={classes.icon} />}
                    label="Boleto"
                    {...a11yProps(2)}
                  />
                )}
              </Tabs>
            ) : (
              <Tabs
                orientation={!isMobile ? 'vertical' : 'horizontal'}
                variant={!isMobile ? 'scrollable' : 'standard'}
                centered={isMobile}
                value={0}
                className={classes.tabs}
                indicatorColor="primary"
                textColor="secondary"
              >
                {paymentMethods.map((method) =>
                  method && method.id === (order && order.formaPagamentoId) ? (
                    <Tab
                      key={method.id}
                      classes={
                        !isMobile
                          ? {
                              wrapper: classes.iconLabelWrapper,
                              labelContainer: classes.labelContainer
                            }
                          : {}
                      }
                      icon={method.icon}
                      label={method.label}
                      {...a11yProps(method.id)}
                    />
                  ) : null
                )}
              </Tabs>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          {isOrderGenerated &&
            order &&
            order.statusPedido &&
            order.statusPedido.pendente &&
            order.formaPagamentoId !== 1 && (
              <Box marginTop={2}>
                <Box
                  justifyContent={'center'}
                  display="flex"
                  alignItems={'center'}
                >
                  <Box marginRight={2}>
                    <CheckCircleIcon
                      style={{ color: theme.palette.success.dark }}
                      fontSize="large"
                    />
                  </Box>
                  <Box>
                    <Typography variant="h6">
                      Pedido realizado com <strong>sucesso!</strong>{' '}
                    </Typography>
                    <Typography variant="h6">
                      Nº {order && order.id ? order.id : ''} -{' '}
                      {format(
                        new Date(order.dataHora),
                        `dd'/'MM'/'yyyy 'às' kk':'mm`,
                        {
                          locale: brLocale
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          {(isOrderGenerated && order && order.statusPedido.pendente) ||
          !isOrderGenerated ? (
            <>
              <TabPanel value={paymentMethodId} index={0}>
                <Pix eventoId={evento.id} isCheckIn={isCheckIn} />
              </TabPanel>
              <TabPanel value={paymentMethodId} index={null}>
                <Free eventoId={evento.id} isCheckIn={isCheckIn} />
              </TabPanel>
              <TabPanel
                style={{ width: '100%' }}
                value={paymentMethodId}
                index={1}
              >
                <CreditCard
                  eventoId={evento && evento.id ? evento.id : 0}
                  isCheckIn={isCheckIn}
                />
              </TabPanel>
              {(allowBoleto || isOrderGenerated) && (
                <TabPanel value={paymentMethodId} index={2}>
                  <Boleto eventoId={evento.id} isCheckIn={isCheckIn} />
                </TabPanel>
              )}
            </>
          ) : null}
          {isOrderGenerated && order && order.statusPedido.cancelado && (
            <Box
              minHeight={300}
              marginTop={2}
              alignItems="center"
              justifyContent={'space-between'}
              display="flex"
              flexDirection={'column'}
              padding={2}
            >
              <Typography variant="h6">
                Pedido nº {order.id} -{' '}
                {format(
                  new Date(order.dataHora),
                  `dd'/'MM'/'yyyy 'às' kk':'mm`,
                  {
                    locale: brLocale
                  }
                )}
              </Typography>
              <Box>
                <CancelIcon
                  style={{ fontSize: 55, color: theme.palette.error.dark }}
                />
                <Typography gutterBottom variant="h5">
                  {' '}
                  <strong>O pedido foi cancelado.</strong>
                </Typography>
                <Typography variant="body2">
                  Caso queira realizar um novo pedido, clique no botão abaixo.
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/event-details/${evento.slug}`)}
              >
                Novo pedido
              </Button>
            </Box>
          )}
          {isOrderGenerated && order && order.statusPedido.finalizado && (
            <CardPaymentSuccess order={order} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
