import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '_actions'
import { FormLogin, RegisterForm } from 'views/login/components'
import { useQueryClient } from 'react-query'
import { useDebouncedEffect } from 'components/Utils'

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  backButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, isRegister, onBackRegister, ...other } =
    props
  return (
    <MuiDialogTitle
      style={isRegister ? { height: 50 } : {}}
      disableTypography
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          onClick={onClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {isRegister ? (
        <IconButton
          onClick={onBackRegister}
          aria-label="back"
          className={classes.backButton}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

export default function DialogLogin() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { openModalLogin, isRegister, token } = useSelector(
    (state) => state.authentication
  )
  const queryClient = useQueryClient()
  const handleClose = () => {
    dispatch(authActions.openModalLogin())
  }

  useDebouncedEffect(() => {
    queryClient.invalidateQueries()
  }, [token])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModalLogin}
      maxWidth={isRegister ? 'sm' : 'xs'}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        isRegister={isRegister}
        onBackRegister={() => dispatch(authActions.setIsRegister())}
      />
      <DialogContent>
        {isRegister ? <RegisterForm /> : <FormLogin />}
      </DialogContent>
    </Dialog>
  )
}
