import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { isPast } from 'date-fns'
import { useIsMutating } from 'react-query'

//? Own imports
import { useStyles } from './styles'
import { CarrinhoItemDetails, Evento } from 'types/api'
import { getGenderFull } from 'utils'
import RowCarrinhoItem from '../RowCarrinhoItem'
import CheckInFinalDate from '../CheckInFinalDate'

type CardTicketsProps = {
  eventDetails: Evento
  title: string
  carrinhoItens: CarrinhoItemDetails[]
  isLoading: boolean
  submitButtonTitle: string
  onSubmit: () => void
}

const CardTickets = ({
  eventDetails,
  title,
  carrinhoItens,
  isLoading,
  onSubmit,
  submitButtonTitle = 'COMPRAR INGRESSOS'
}: CardTicketsProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  const isMutatingCarrinhoItem = useIsMutating({
    mutationKey: 'mutationEditCarrinhoItem',
    exact: true
  })
  const isPastEvent = isPast(new Date(new Date(eventDetails?.dataHoraFim)))

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h5" align="left" className={classes.textHeader}>
            {title}
          </Typography>
        }
      />
      <CardContent>
        {isPastEvent ? (
          <Typography variant="h5" align="center">
            Este evento está encerrado
          </Typography>
        ) : (
          <>
            {isLoading && (
              <>
                <Typography gutterBottom variant="body1">
                  Carregando lotes do evento...
                </Typography>
                <CircularProgress size={24} />
              </>
            )}
            {carrinhoItens && carrinhoItens.length > 0 ? (
              <>
                <Table size="small" aria-label="spanning table">
                  <TableBody>
                    {carrinhoItens.map((n) => {
                      return (
                        <TableRow key={n.carrinhoItem.lote.id}>
                          <TableCell align="left" component="th" scope="row">
                            {n.carrinhoItem.lote.tipoIngresso.descricao} -{' '}
                            {n.carrinhoItem.lote.descricao} (
                            {getGenderFull(n.carrinhoItem.lote.genero)})
                            {n?.carrinhoItem?.lote?.checkIn ? (
                              <CheckInFinalDate
                                carrinhoItem={n?.carrinhoItem}
                              />
                            ) : null}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.carrinhoItem.lote.valor === 0
                              ? 'Grátis'
                              : Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                }).format(n.carrinhoItem.lote.valor)}
                          </TableCell>
                          <TableCell align="right">
                            <RowCarrinhoItem carrinhoItem={n.carrinhoItem} />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <CardActions className={classes.buttonCheckout}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    fullWidth={isMobile}
                    disabled={
                      (carrinhoItens &&
                        carrinhoItens.length &&
                        !carrinhoItens.some(
                          (carrinhoItem) =>
                            carrinhoItem.carrinhoItem.quantidade > 0
                        )) ||
                      isMutatingCarrinhoItem > 0
                    }
                  >
                    {submitButtonTitle}
                  </Button>
                </CardActions>
              </>
            ) : (
              !isLoading && (
                <Typography variant="h5" align="center">
                  Nenhum ingresso disponível no momento.
                </Typography>
              )
            )}
          </>
        )}
      </CardContent>
      {carrinhoItens && carrinhoItens.length > 0 && (
        <Grid
          style={{ padding: 5, paddingLeft: 15 }}
          justify="flex-start"
          container
          spacing={1}
        >
          <Typography
            align="left"
            variant="caption"
            color="initial"
            gutterBottom
          >
            Em caso de taxa de serviço, o valor aparecerá na proxima etapa.
          </Typography>
        </Grid>
      )}
    </Card>
  )
}

export default CardTickets
