const initialState = {
  searching: false,
  params: {},
  result: {}
}

export function searchTopBar(state = initialState, action) {
  switch (action.type) {
    case 'SEARCH_TOPBAR_REQUEST':
      return {
        ...state,
        searching: true,
        params: action.params
      }
    case 'SEARCH_TOPBAR_SUCCESS':
      return {
        ...state,
        searching: false,
        params: action.params,
        result: action.result
      }
    case 'SEARCH_TOPBAR_FAILURE':
      return {
        ...state,
        searching: false,
        error: action.error
      }
    default:
      return state
  }
}
