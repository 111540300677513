import React, { useEffect } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

//? Own imports
import { formatCurrency } from 'utils'
import { useParams } from 'react-router-dom'
import { usePlanValue } from 'hooks-querys'

const SelectInputInstallments = () => {
  const { planValueId } = useParams<{ planValueId: string }>()

  const {
    data,
    isError,
    isLoading: isLoadingPlanValue,
    refetch
  } = usePlanValue({
    planValueId: Number(planValueId)
  })
  const { installmentsCreditCard, selectedCreditCard } = useSelector(
    // @ts-ignore
    (state) => state.orderPayment
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'SET_INSTALLMENTS_CREDIT_CARD',
      payload: {
        parcelas: 1,
        valorParcela: data?.valor,
        valorTotal: data?.valor
      }
    })
    return () => {
      dispatch({
        type: 'SET_INSTALLMENTS_CREDIT_CARD',
        payload: {
          parcelas: 1,
          valorParcela: null,
          valorTotal: null
        }
      })
    }
  }, [selectedCreditCard])

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="total-label">Valor total</InputLabel>
      <Select
        labelId="total-label"
        variant="outlined"
        label="Valor total"
        id="total"
        value={installmentsCreditCard.parcelas}
        disabled
        IconComponent={() => <></>}
        // onChange={handleChangeInstallments}
        fullWidth
      >
        <MenuItem disabled value="">
          <em>Parcelamento indisponível</em>
        </MenuItem>
        <MenuItem disabled={true} key={1} value={1}>
          <Typography>
            {/* 1x de {formatCurrency(data?.valor)} */}
            <strong>
              {`Valor total`} {formatCurrency(data?.valor)}
            </strong>
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  )
}

export default SelectInputInstallments
