import React from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

//? Own imports
import { useStyles } from './styles'
import {
  PurchaseApplicationFormItem,
  getCampoInscricaoAnswer,
  isRequiredFieldsFilledForItem
} from '../../purchaseApplicationFormUtils'
import { capitalizeFirstLetter } from 'utils'
import { setActiveStep } from '_actions'

type PurchaseApplicationFormTicketItemProps = {
  purchaseApplicationFormItem: PurchaseApplicationFormItem
}
const PurchaseApplicationFormTicketItem = ({
  purchaseApplicationFormItem
}: PurchaseApplicationFormTicketItemProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { carrinhoItemDetails: carrinhoItemDetails } =
    purchaseApplicationFormItem
  const { camposInscricao, carrinhoItem } = carrinhoItemDetails
  const respostasCamposInscricao =
    purchaseApplicationFormItem.respostasCamposInscricao || []
  const allFieldsAnswered = checkCamposInscricaoAnswered()
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm
        ?.purchaseApplicationFormItens as PurchaseApplicationFormItem[]
  )
  const currentIngressoIndex = purchaseApplicationFormItens?.findIndex(
    (item) => item?.id === purchaseApplicationFormItem?.id
  )
  const totalIngressos = purchaseApplicationFormItens?.length
  const allRequiredItemsFilled = isRequiredFieldsFilledForItem(
    purchaseApplicationFormItem
  )
  const statusMessage = allFieldsAnswered
    ? 'Concluído'
    : allRequiredItemsFilled
    ? 'Opcional'
    : 'Pendente'

  // Helper function to check if there is an answer for a specific field
  function respostaCampoInscricaoExists(campoInscricaoId: number): boolean {
    return respostasCamposInscricao.some(
      (resposta) =>
        resposta.campoInscricaoId === campoInscricaoId && resposta.texto
    )
  }

  function checkCamposInscricaoAnswered(): boolean {
    // Iterate over dynamic fields
    for (const campo of camposInscricao) {
      // Check if the field doesn't have a corresponding answer
      if (!respostaCampoInscricaoExists(campo.id)) {
        return false // If any  field is unanswered, return false
      }
    }

    return true // All fields have been answered
  }

  const hasRequiredFields = camposInscricao.some(
    (campo) => campo.obrigatorio === true
  )

  const handlePress = () => {
    dispatch(setActiveStep(currentIngressoIndex + 1))
  }

  const getIconComponent = () => {
    if (allFieldsAnswered) {
      return (
        <CheckCircleOutlineIcon
          fontSize="large"
          style={{ color: theme.palette?.success.main }}
        />
      )
    } else if (allRequiredItemsFilled) {
      return (
        <HelpOutlineIcon
          fontSize="large"
          style={{ color: theme.palette?.primary.main }}
        />
      )
    } else {
      return (
        <ErrorOutlineIcon
          fontSize="large"
          style={{ color: theme.palette?.error.main }}
        />
      )
    }
  }

  const iconComponent = getIconComponent()

  return (
    <Box className={classes.container} onClick={handlePress}>
      <Box className={classes.content}>
        {carrinhoItem?.lote && (
          <Box className={classes.loteDescriptionContainer}>
            <Typography align="left" variant="body2">
              {carrinhoItem.lote?.tipoIngresso &&
                carrinhoItem.lote?.tipoIngresso.descricao}{' '}
              {''}({carrinhoItem.lote?.descricao})
            </Typography>
          </Box>
        )}
        <Box className={classes.formResponses}>
          <Typography align="left" variant="body2">
            Dados adicionais:
          </Typography>
          {camposInscricao?.map((campo, index) => {
            const respostaCampo = getCampoInscricaoAnswer(
              campo?.id,
              respostasCamposInscricao
            )

            return (
              <Typography
                key={index.toString()}
                variant="body2"
                align="left"
                className={`${classes.formResponsesItemText} ${
                  respostaCampoInscricaoExists(campo?.id)
                    ? classes.boldText
                    : ''
                }`}
              >
                {capitalizeFirstLetter(campo.nome)}
                {campo.obrigatorio ? '*' : ''}: {respostaCampo || ''}
              </Typography>
            )
          })}
        </Box>
        {hasRequiredFields ? (
          <Typography align="left" variant="caption">
            * campos obrigatórios
          </Typography>
        ) : null}
      </Box>
      <Box className={classes.iconContainer}>
        <Box sx={{ right: 0, top: 0 }}>
          <Typography variant="caption">
            {(currentIngressoIndex || 0) + 1}/{totalIngressos || 0}
          </Typography>
        </Box>
        <Typography variant="caption">{statusMessage}</Typography>
        <div>{iconComponent}</div>
      </Box>
    </Box>
  )
}

export default PurchaseApplicationFormTicketItem
