import React, { useState } from 'react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'

//? Own imports
import { MembroPlano } from 'types/api'
import { getImageUrls, getInitials } from 'utils'
import { useStyles } from './styles'
import { DialogQRCodeProfile } from 'components'
import { history } from '_helpers'

const formatDate = (dateString: string) => {
  if (!dateString) return null
  const date = new Date(dateString)
  const formattedDate = format(date, "d 'de' MMMM 'de' yyyy", {
    locale: brLocale
  })
  return formattedDate
}

type MemberListItemProps = {
  item: MembroPlano
  showButtonActions: boolean
  showQRCodeButton?: boolean
}

const MemberListItem = ({
  item,
  showButtonActions = true,
  showQRCodeButton = false
}: MemberListItemProps) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const theme = useTheme()
  const image =
    getImageUrls(item?.plano?.imagem)?.thumbnailUrl ||
    getImageUrls(item?.plano?.estabelecimento?.imagem)?.thumbnailUrl
  const title =
    item?.plano?.estabelecimento?.nome?.toUpperCase() ||
    item?.plano?.nome?.toUpperCase()
  const initialDate = formatDate(item?.dataHoraInicio)
  const isActivePlan = item?.statusMembroPlano?.ativo

  const handlePressAvatar = () => {
    setOpenDialog(true)
  }
  return (
    <Box className={classes.container}>
      <DialogQRCodeProfile
        membroPlanoId={item?.id?.toString()}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
      <Box className={classes.content}>
        <Box className={classes.header}>
          <div>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              badgeContent={
                <IconButton
                  onClick={handlePressAvatar}
                  color="inherit"
                  size="small"
                  style={{
                    backgroundColor: theme.palette.primary.main
                  }}
                >
                  <ConfirmationNumberIcon
                    fontSize="inherit"
                    style={{ color: 'white' }}
                  />
                </IconButton>
              }
            >
              <Avatar
                onClick={handlePressAvatar}
                className={classes.avatar}
                src={image}
                alt={getInitials(item?.plano?.estabelecimento?.nome)}
              >
                {getInitials(item?.plano?.estabelecimento?.nome)}
              </Avatar>
            </Badge>
          </div>
          <Box className={classes.info}>
            <Box>
              <Typography align="left" variant="subtitle1">
                {title}
              </Typography>
              {item?.plano?.nome ? (
                <Typography align="left" variant="body2">
                  {item?.plano?.nome?.toUpperCase()}
                </Typography>
              ) : null}
            </Box>
            <Chip
              size="small"
              variant={isActivePlan ? 'outlined' : 'default'}
              color={isActivePlan ? 'primary' : 'default'}
              label={item?.statusMembroPlano?.descricao}
            />
            {initialDate ? (
              <Typography align="left" variant="body2">
                Membro desde {initialDate}
              </Typography>
            ) : null}
          </Box>
        </Box>
        {showQRCodeButton && (
          <>
            <Divider />
            <Box>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={handlePressAvatar}
              >
                Ver QRCode
              </Button>
            </Box>
          </>
        )}
        {showButtonActions ? (
          <>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexShrink: 1,
                flexDirection: 'row',
                gridGap: theme.spacing(2)
              }}
            >
              <Button
                onClick={() =>
                  history.push(`/membership-settings/general/${item?.id}`)
                }
                fullWidth
                variant="contained"
                color="primary"
              >
                Gerenciar
              </Button>
              {/* //!TODO: Add function */}
              {/* <Button fullWidth color="primary" variant="contained">
                Área de membros
              </Button> */}
            </Box>
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default MemberListItem
