import axios from 'axios'
import { toast } from 'react-toastify'
import config from '../config/config'
import { errorHandler } from './errorHandler'

export const authServices = {
  post,
  logout
}

function post(apiEndpoint, payload) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload)
    .then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.usuario))
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('expires', response.data.expires)
      return response
    })
    .catch((error) => {
      if (error.response.data === '') {
        toast.error('Opss! Senha inválida! 😥')
      } else {
        return errorHandler(error)
      }
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('expires')
}
