import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  content: {
    marginBottom: theme.spacing(3)
  },
  loadingContainer: {
    flexGrow: 1,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',
    display: 'flex',
    padding: theme.spacing(0, 2, 2, 2),
    [theme.breakpoints.up('md')]: { padding: theme.spacing(4, 0, 10, 0) }
  }
}))
