import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = (props) =>
  makeStyles((theme) => ({
    div: {
      backgroundImage: `url("${props.src}")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      [theme.breakpoints.up('lg')]: {
        height: '60%',
        maxHeight: 400
      },
      [theme.breakpoints.down('md')]: {
        height: '35%'
      },
      width: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      webkitFilter: 'blur(15px)',
      mozFilter: 'blur(15px)',
      oFilter: 'blur(15px)',
      msFilter: 'blur(15px)',
      filter: 'blur(15px)',
      opacity: 0.7,
      userSelect: 'none'
    }
  }))

export default function BackgroundImage({ children, ...props }) {
  const { div } = useStyles(props)()
  return <div className={div}>{children}</div>
}
