import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import {
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Divider
} from '@material-ui/core'

import { Page } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  imageGooglePlay: {
    width: '160px',
    height: 'auto'
  },
  imageAppStore: {
    width: '145px',
    height: 'auto'
  }
}))

const PhotoAlbum = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Page className={classes.root} title="Álbum">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        Os álbuns de fotos do Corujas estão disponíveis apenas no nosso app!
      </Typography>
      {/* <Typography align="center" variant="subtitle2">
        Baixe em sua loja de aplicativos!
      </Typography> */}
      {/* <div className={classes.imageContainer}>
        <img
          alt="Não encontrado"
          className={classes.image}
          src="/images/coruja-error.svg"
        />
      </div> */}
      <Divider></Divider>
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        align="center"
        className={classes.imageContainer}
      >
        {/* <Grid item xs={12}>
          <Typography align="center" gutterBottom>
            Para ler os ingressos no dia do evento baixe o nosso app validador
            de ingressos!
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            align="center"
          >
            <Grid item sm={12} md={6} lg={5} xl={4}>
              <a
                href="https://play.google.com/store/apps/details?id=com.quintos.corujas"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={classes.imageGooglePlay}
                  src="https://corujas.s3.us-east-2.amazonaws.com/images/general/google-play-badge+(1).svg"
                />
              </a>
            </Grid>
            <Grid item sm={12} md={6} lg={5} xl={4}>
              <a
                href="https://apps.apple.com/us/app/corujas/id1593667736"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  className={classes.imageAppStore}
                  src="https://corujas.s3.us-east-2.amazonaws.com/images/general/Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          component={RouterLink}
          to="/home"
          variant="outlined"
        >
          Voltar para home
        </Button>
      </div>
    </Page>
  )
}

export default PhotoAlbum
