import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import { history } from '../../_helpers'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { withStyles } from '@material-ui/core/styles'
import { usuarioActions } from '../../_actions'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff'
  },
  backButton: {
    marginTop: theme.spacing(1)
  }
})

class RequestPasswordReset extends Component {
  state = {
    email: '',
    submitted: false
  }

  handleChange = (prop) => (event) => {
    this.setState({ email: event.target.value.toLowerCase() })
  }

  handleSubmit = (event) => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000)
    })
    const { dispatch } = this.props
    let payload = {
      email: this.state.email
    }
    dispatch(usuarioActions.requestPasswordReset(payload))
  }

  render() {
    const { submitted, email } = this.state
    const { classes } = this.props

    return (
      <Container component={Paper} maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container justify="flex-start">
            <IconButton
              className={classes.backButton}
              onClick={() => history.goBack()}
              aria-label="Voltar"
              color="primary"
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Grid>
          <Typography component="h2" variant="h6">
            Informe o endereço de e-mail utilizado no cadastro, para recuperar a
            senha.
          </Typography>
          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  type="email"
                  id="email"
                  label="Endereço de e-mail *"
                  name="email"
                  value={email}
                  onChange={this.handleChange('email')}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'Você precisa informar o e-mail',
                    'E-mail inválido'
                  ]}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitted}
              className={classes.submit}
            >
              {(submitted && 'Enviando informações...') ||
                (!submitted && 'ENVIAR E-MAIL DE RECUPERAÇÃO DE SENHA')}
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    )
  }
}
RequestPasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return state
}
const connectedRequestPasswordResetPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(RequestPasswordReset))
)
export { connectedRequestPasswordResetPage as RequestPasswordReset }
