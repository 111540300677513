import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from '@material-ui/core'
import { useCarrinhoItensEvento, useEvento } from 'hooks-querys'

//? Own imports
import EventoHeader from '../eventos/eventoHeader'
import { filterItemsWithCheckIn } from 'utils/cartItemsFilters'
import { useStyles } from './styles'
import {
  CardEstablishmentDetails,
  CardEventDetails,
  CardEventLocation,
  CardTickets
} from 'views/eventos/components'
import { history } from '_helpers'
import { Page } from 'components'

const HEADER_HEIGHT = 64

const CheckIn = () => {
  const classes = useStyles()
  const { eventId } = useParams<{ eventId: string }>()

  const { data: eventDetails, isLoading: isLoadingEvent } = useEvento(eventId)

  const { data, isLoading: isLoadingCarrinhoItens } = useCarrinhoItensEvento(
    eventDetails?.id || 0
  )

  const cartItemsWithCheckIn = filterItemsWithCheckIn(data || [])
  const refCardTickets = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (eventDetails && refCardTickets.current) {
      const offset = HEADER_HEIGHT + 10
      const elementPosition =
        refCardTickets.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      })
    }
  }, [eventDetails])

  if (isLoadingEvent) {
    return (
      <Container maxWidth="lg" className={classes.loadingContainer}>
        <Box marginY={4}>
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    )
  }
  return (
    //@ts-ignore
    <Page className={classes.root} title={`Check-in - ${eventDetails?.nome}`}>
      {eventDetails && (
        <>
          <EventoHeader evento={eventDetails}></EventoHeader>
          <div>
            <main className={classes.content}>
              <Container>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={7}>
                    <CardEventDetails eventDetails={eventDetails} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div ref={refCardTickets}>
                          <CardTickets
                            eventDetails={eventDetails}
                            carrinhoItens={cartItemsWithCheckIn}
                            isLoading={isLoadingCarrinhoItens}
                            title="Check-in"
                            submitButtonTitle="REALIZAR CHECK-IN"
                            onSubmit={() =>
                              history.push(
                                `/check-in-details/${eventDetails?.id}`
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <CardEventLocation eventDetails={eventDetails} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <CardEstablishmentDetails
                          establishmentDetails={eventDetails?.estabelecimento}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </main>
          </div>
        </>
      )}
    </Page>
  )
}
export default CheckIn
