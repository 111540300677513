import { cnpj, cpf } from 'cpf-cnpj-validator'
import { toast } from 'react-toastify'
import { usuarioActions } from '_actions'

export const userHasCompleteAddress = (user) => {
  if (user !== undefined && user !== null) {
    //Nova variável "cep" somente com dígitos.
    const cepOnlyNumbers = user?.cep && user?.cep.replace(/\D/g, '')
    const validateCep = /^[0-9]{8}$/
    //Valida o formato do CEP.
    if (cepOnlyNumbers && validateCep.test(cepOnlyNumbers)) {
      if (
        user?.endereco &&
        user?.cidadeId &&
        user?.cidadeId > 0 &&
        user?.bairro &&
        user?.numero
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  return false
}

export const userHasValidDocument = (cpfCnpj) => {
  if (cpfCnpj !== undefined && cpfCnpj !== null) {
    if (cnpj.isValid(cpfCnpj.toString()) || cpf.isValid(cpfCnpj.toString())) {
      return true
    }
  }
  return false
}

export const userHasValidPhone = (phone) => {
  if (phone !== undefined && phone !== null) {
    const phoneOnlyNumbers = phone.replace(/\D/g, '')
    const validatePhone = /^[0-9]{8,15}$/
    if (phoneOnlyNumbers && validatePhone.test(phoneOnlyNumbers)) {
      return true
    } else {
      return false
    }
  }
  return false
}

//mapear campos não preenchidos e criar mensagem de erro
export const validateUserNecessaryData = (dispatch, user) => {
  const errors = {}
  if (user) {
    if (!userHasValidDocument(user?.cpfCnpj)) {
      errors.cpfCnpj = 'CPF/CNPJ'
    }
    if (!userHasValidPhone(user?.telefone)) {
      errors.telefone = 'telefone'
    }
    if (!userHasCompleteAddress(user)) {
      errors.endereco = 'endereço'
    }
    if (Object.keys(errors).length > 0) {
      toast.error(
        'Você precisa preencher o ' + Object.values(errors).join(', ') + ' para continuar.'
        , { toastId: 'userNecessaryData', position: "top-center", autoClose: 1000 * 8 })
      dispatch(usuarioActions.openModalUserNecessaryData())
      return false
    }
  }
  return true
}
