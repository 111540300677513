import React from 'react'
import { aplicaDescontos, calculaTaxa } from 'utils/purchaseCalculate'
import { makeStyles, Typography } from '@material-ui/core'
import { formatCurrency, getGenderFull } from 'utils'
import { useDiscountCoupon } from 'hooks-querys'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start'
  }
}))

export default function CarrinhoItemDetailsOrderGenerated({ carrinhoItem }) {
  const classes = useStyles()
  const isFree = carrinhoItem.valor == 0 ? true : false
  const haveDiscount = !!carrinhoItem.valorDesconto
  const haveTax = carrinhoItem.lote.repassarTaxa

  return (
    <div key={carrinhoItem.id} className={classes.content}>
      {carrinhoItem && (
        <Typography display="inline" align="left">
          <Typography
            display="inline"
            align="left"
            color="textPrimary"
            variant="body2"
          >
            {carrinhoItem.quantidade} {carrinhoItem.lote.tipoIngresso.descricao}{' '}
            - {carrinhoItem.lote.descricao} (
            {getGenderFull(carrinhoItem.lote.genero)}) -{' '}
          </Typography>
          <Typography
            display="inline"
            align="left"
            color="textPrimary"
            variant="body2"
          >
            {isFree ? (
              <strong>Gratuito</strong>
            ) : (
              <Typography display="inline" align="left">
                <Typography
                  display="inline"
                  align="left"
                  color="textPrimary"
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    textDecoration: haveDiscount ? 'line-through' : 'none',
                    color: haveDiscount ? '#9e9e9e' : 'inherit'
                  }}
                >
                  {`${formatCurrency(
                    haveTax
                      ? carrinhoItem.quantidade * carrinhoItem.valor -
                          carrinhoItem.quantidade * carrinhoItem.valorTaxa
                      : carrinhoItem.quantidade * carrinhoItem.valor
                  )}`}
                </Typography>
                {haveDiscount && (
                  <Typography
                    display="inline"
                    align="left"
                    color="textPrimary"
                    variant="body2"
                  >
                    {' '}
                    <strong>
                      {formatCurrency(
                        haveTax
                          ? carrinhoItem.quantidade * carrinhoItem.valor -
                              carrinhoItem.quantidade * carrinhoItem.valorTaxa -
                              carrinhoItem.quantidade *
                                carrinhoItem.valorDesconto
                          : carrinhoItem.quantidade * carrinhoItem.valor -
                              carrinhoItem.quantidade *
                                carrinhoItem.valorDesconto
                      )}
                    </strong>
                  </Typography>
                )}
                {haveTax && (
                  <Typography
                    display="inline"
                    align="left"
                    color="textPrimary"
                    variant="body2"
                  >
                    <strong>
                      {' (+ '}
                      {formatCurrency(
                        carrinhoItem.quantidade * carrinhoItem.valorTaxa
                      ) || ''}{' '}
                      taxa)
                    </strong>
                  </Typography>
                )}
              </Typography>
            )}
          </Typography>
        </Typography>
      )}
    </div>
  )
}
