import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { crudService } from '_services/'

const getDiscountCoupon = async ({ carrinhoItens, textoCupom }) => {
  const payload = {
    carrinhoItens: carrinhoItens,
    textoCupom: textoCupom
  }
  const { data } = await crudService.post(`cuponsDesconto/validar`, payload)
  return data
}

export default function useDiscountCoupon(
  discountCouponCode,
  carrinhoItens,
  enabled
) {
  return useQuery(
    ['discountCoupon'],
    () =>
      getDiscountCoupon({
        textoCupom: discountCouponCode,
        carrinhoItens: carrinhoItens ? carrinhoItens : []
      }),
    {
      enabled: enabled,
      retry: false,
      onError: (error) => {
        if (error.status === 404) {
          toast.error('Opss! Esse cupom não existe 😪', {
            toastId: 'discountCouponInvalid'
          })
        } else if (error && error.data) {
          toast.error(
            typeof error.data === 'string' || error.data instanceof String
              ? error.data
              : 'Opss! Não foi possível aplicar o cupom!',
            { toastId: 'discountCouponInvalid' }
          )
        } else {
          toast.error('Opss! Não foi possível aplicar o cupom!', {
            toastId: 'discountCouponQueryError'
          })
        }
      },

      onSuccess: (data) => {
        if (data.carrinhoItens.length === 0) {
          toast.error('Eii! O cupom não é aplicável a essa compra 😪', {
            toastId: 'discountCouponNotApplicable'
          })
        }
      }
    }
  )
}
