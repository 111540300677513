import { useMutation } from 'react-query'
import axios from 'axios'
import { Cidade } from './../types/api'
import config from 'config/config'

type CreditCardData = {
  number: string
  expiration: string // MM/YY
  holderName: string
  securityCode: string
  holderDocument: string
  billingAddress: {
    endereco: string
    numero: string
    complemento?: string
    bairro: string
    cep: string
    cidade: Cidade
  }
}
type TokenResponse = {
  card: {
    brand: string
    exp_month: number
    exp_year: number
    first_six_digits: string
    holder_name: string
    last_four_digits: string
  }
  created_at: Date
  expires_at: Date
  id: string
  type: 'card'
}

const generateCreditCardToken = async (creditCard: CreditCardData) => {
  const tokenResponse = await axios.post<TokenResponse>(
    'https://api.pagar.me/core/v5/tokens',
    {
      type: 'card',
      card: {
        number: creditCard.number.replace(/ /g, ''),
        holder_name: creditCard.holderName.trim().toUpperCase(),
        exp_month: creditCard.expiration.split('/')[0],
        exp_year: creditCard.expiration.split('/')[1],
        cvv: creditCard.securityCode,
        ...(creditCard &&
          creditCard.holderDocument && {
            holder_document: creditCard.holderDocument
              .split('.')
              .join('')
              .replace(/[-/.]/g, '')
          }),
        billing_address: {
          line_1:
            `${creditCard?.billingAddress?.numero.trim()}, ${creditCard?.billingAddress?.endereco.trim()}, ${creditCard?.billingAddress?.bairro.trim()}` ||
            '',
          line_2: creditCard?.billingAddress?.complemento?.trim() || '',
          zip_code: creditCard?.billingAddress.cep.replace(/-/g, ''),
          city: creditCard?.billingAddress?.cidade?.nome || '',
          state: creditCard?.billingAddress?.cidade?.estado?.uf || '',
          country: creditCard?.billingAddress?.cidade?.estado?.pais?.iso2 || ''
        }
      }
    },
    {
      params: {
        appId: config.pagarmePublicKey
      }
    }
  )
  return tokenResponse
}

export default function useMutationGenerateCreditCardToken() {
  return useMutation(
    'mutationGenerateCreditCardToken',
    (creditCard: CreditCardData) => generateCreditCardToken(creditCard),
    {}
  )
}
