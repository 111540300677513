/*eslint-disable*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
import { history } from '../_helpers'
// material-ui core components
import { List, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite'
import styles from './footerStyle'

const useStyles = makeStyles(styles)

export default function Footer(props) {
  const classes = useStyles()
  const { whiteFont } = props
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  })
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  })
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link
                className={classes.block}
                variant="body2"
                href="mailto:falecom@corujatickets.com?Subject=Pergunta%20para%20a%20coruja."
              >
                CONTATO
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                className={classes.block}
                target="_blank"
                component="button"
                variant="body2"
                onClick={() => {
                  history.push('/termos')
                }}
              >
                TERMOS DE USO
              </Link>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
                            <a
                                href="http://blog.creative-tim.com/?ref=mkr-footer"
                                className={classes.block}
                                target="_blank"
                            >
                                Blog
              </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.creative-tim.com/license?ref=mkr-footer"
                                className={classes.block}
                                target="_blank"
                            >
                                Licenses
              </a>
                        </ListItem> */}
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}, feito com{' '}
          <Favorite className={classes.icon} /> por{' '}
          <a
            href="https://corujatickets.com"
            className={aClasses}
            target="_blank"
          >
            Corujas
          </a>{' '}
          para você.
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
}
