import React from 'react'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { formatCurrency } from 'utils'

import { useCarrinhoItensEvento, useDiscountCoupon } from 'hooks-querys'
import {
  CarrinhoItemDetails,
  CarrinhoItemDetailsOrderGenerated,
  DiscountCoupon,
  EventDetails
} from './components'
import {
  calculaValorAPagar,
  calculaValorDesconto,
  calculaValorTotal,
  calculaValorTotalTaxa
} from 'utils/purchaseCalculate'
import { useSelector } from 'react-redux'
import {
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

const OrderSummary = (props) => {
  const classes = useStyles()
  const { evento, order, isOrderGenerated, isCheckIn } = props
  const { installmentsCreditCard, paymentMethodId } = useSelector(
    // @ts-ignore
    (state) => state.orderPayment
  )

  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(isOrderGenerated ? undefined : evento.id)
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)

  const filteredCartItems = filterValidCartItems(data)
  const carrinhoItensWithoutCheckIn = filterItemsWithoutCheckIn(
    filteredCartItems || []
  )
  const carrinhoItensWithCheckIn = filterItemsWithCheckIn(
    filteredCartItems || []
  )
  const carrinhoItens = isCheckIn
    ? carrinhoItensWithCheckIn
    : carrinhoItensWithoutCheckIn

  return (
    <div className={classes.root}>
      <EventDetails event={evento} />
      {carrinhoItens?.length > 0 ? (
        <>
          <Typography
            color="textPrimary"
            align="left"
            gutterBottom
            variant="body2"
          >
            <strong>Ingressos</strong>
          </Typography>
          {carrinhoItens.map((carrinhoItensDetails) => (
            <CarrinhoItemDetails
              key={carrinhoItensDetails.carrinhoItem.id}
              carrinhoItem={carrinhoItensDetails.carrinhoItem}
              parametroTaxaPadrao={carrinhoItensDetails.parametroTaxaPadrao}
              taxa={carrinhoItensDetails.taxa}
            />
          ))}
          <DiscountCoupon
            disabled={isCheckIn}
            carrinhoItens={
              carrinhoItens &&
              carrinhoItens.map(
                (carrinhoItemDetails) => carrinhoItemDetails.carrinhoItem
              )
            }
          />
          <Divider className={classes.divider} />
          {calculaValorTotal(carrinhoItens || []) > 0 && (
            <Typography variant="body1" color="textPrimary" align="left">
              Valor total:{' '}
              {formatCurrency(calculaValorTotal(carrinhoItens || []))}
            </Typography>
          )}
          {calculaValorDesconto({
            carrinhoItens:
              (discountCoupon && discountCoupon.carrinhoItens) || [],
            cupomDesconto: discountCoupon && discountCoupon.cupomDesconto
          }) > 0 && (
            <Typography variant="body1" color="textPrimary" align="left">
              Descontos:{' '}
              {formatCurrency(
                calculaValorDesconto({
                  carrinhoItens:
                    (discountCoupon && discountCoupon.carrinhoItens) || [],
                  cupomDesconto: discountCoupon && discountCoupon.cupomDesconto
                })
              )}
            </Typography>
          )}
          {calculaValorTotalTaxa(
            carrinhoItens || [],
            (discountCoupon && discountCoupon.carrinhoItens) || [],
            discountCoupon && discountCoupon.cupomDesconto
          ) > 0 && (
            <Typography variant="body1" color="textPrimary" align="left">
              Taxa de serviço:{' '}
              {formatCurrency(
                calculaValorTotalTaxa(
                  carrinhoItens || [],

                  (discountCoupon && discountCoupon.carrinhoItens) || [],
                  discountCoupon && discountCoupon.cupomDesconto
                )
              )}
            </Typography>
          )}
          <Grid
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            container
          >
            <Grid item>
              <Typography variant="h4" color="textPrimary" align="left">
                <strong>Valor à pagar:</strong>&nbsp;
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h4" color="textPrimary" align="left">
                    <strong>
                      {paymentMethodId === 1 &&
                      installmentsCreditCard &&
                      installmentsCreditCard.parcelas
                        ? formatCurrency(installmentsCreditCard.valorTotal)
                        : calculaValorAPagar(
                            carrinhoItens || [],
                            (discountCoupon && discountCoupon.carrinhoItens) ||
                              [],
                            discountCoupon && discountCoupon.cupomDesconto
                          ) === 0
                        ? 'R$ 0,00'
                        : formatCurrency(
                            calculaValorAPagar(
                              carrinhoItens || [],
                              (discountCoupon &&
                                discountCoupon.carrinhoItens) ||
                                [],
                              discountCoupon && discountCoupon.cupomDesconto
                            )
                          )}
                    </strong>
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: '-0.5rem'
                  }}
                >
                  {paymentMethodId === 1 &&
                  installmentsCreditCard &&
                  installmentsCreditCard.parcelas ? (
                    <Typography variant="caption" color="textSecondary">
                      {installmentsCreditCard.parcelas > 1
                        ? ` em ${
                            installmentsCreditCard.parcelas
                          }x de ${formatCurrency(
                            installmentsCreditCard.valorParcela
                          )}`
                        : null}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        !isOrderGenerated &&
        carrinhoItens &&
        carrinhoItens.length === 0 && (
          <Typography variant="body2" color="textSecondary" align="left">
            Nenhum ingresso adicionado
          </Typography>
        )
      )}
      {isOrderGenerated && order && (
        <>
          <Typography
            color="textPrimary"
            align="left"
            gutterBottom
            variant="body2"
          >
            <strong>Ingressos</strong>
          </Typography>
          {order.carrinhoItem.map((carrinhoItensDetails) => (
            <CarrinhoItemDetailsOrderGenerated
              key={carrinhoItensDetails.id}
              carrinhoItem={carrinhoItensDetails}
            />
          ))}
          <Divider className={classes.divider} />
          <Typography align="left">
            <Typography
              variant="h4"
              color="textPrimary"
              align="left"
              style={{
                marginTop: '0.5rem'
              }}
              gutterBottom
              display="inline"
            >
              <strong>
                Valor total:{' '}
                {order.valorTotal === 0
                  ? 'R$ 0,00'
                  : formatCurrency(order.valorTotal)}
              </strong>
              {order && order.parcelas ? (
                <Typography
                  variant="caption"
                  color="textSecondary"
                  display="inline"
                >
                  {order.parcelas > 1 ? ` em ${order.parcelas}x` : null}
                </Typography>
              ) : null}
            </Typography>
          </Typography>
        </>
      )}
    </div>
  )
}

export default OrderSummary
