import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  withStyles
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'

//? Own imports
import { Evento } from 'types/api'
import { history } from '_helpers'
import { getImageUrls, getInitials } from 'utils'
import theme from 'theme'
import { useCartItemsWithCheckIn } from 'utils/hooks'
import { useParams } from 'react-router'

type CheckInModalProps = {
  event: Evento
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 4)
    },
    closeButton: {
      position: 'absolute',
      color: theme.palette.grey[500],
      right: theme.spacing(1)
    },
    title: {
      flex: 1,
      textAlign: 'center'
    }
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.title} variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center'
  }
}))(MuiDialogActions)

const CheckInModal = ({ event }: CheckInModalProps) => {
  const [showModal, setShowModal] = useState(false)
  const { hasItemsWithCheckIn } = useCartItemsWithCheckIn(
    Number(event?.id) || 0
  )
  const { id } = useParams<{ id: string }>()
  const title = 'Check-in disponível'
  const description = `O check-in para ${event?.nome} está disponível! Faça o check-in agora mesmo para garantir sua participação.`
  const buttonTitle = 'Realizar Check-in'

  const navigateToCheckInTicket = () => {
    history.push(`/check-in/${id || event?.id}`)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleButtonPress = () => {
    setShowModal(false)
    navigateToCheckInTicket()
  }

  useEffect(() => {
    if (hasItemsWithCheckIn) {
      setShowModal(true)
    }
  }, [hasItemsWithCheckIn])

  return (
    <Dialog
      maxWidth="xs"
      // onClose={handleClose}
      aria-labelledby="dialog-member"
      open={showModal}
      //@ts-ignore
      TransitionComponent={Transition}
    >
      <DialogTitle id="dialog-member" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            padding: theme?.spacing(4, 2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Avatar
            style={{
              height: 100,
              width: 160,
              borderWidth: 1,
              borderRadius: 8
            }}
            variant="square"
            src={getImageUrls(event?.imagem)?.thumbnailUrl}
            alt={getInitials(event?.nome)}
          >
            {getInitials(event?.nome)}
          </Avatar>
        </Box>
        <Typography gutterBottom>{description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          autoFocus
          onClick={handleButtonPress}
          color="primary"
          variant="contained"
        >
          {buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckInModal
