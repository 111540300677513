const initialState = {
  paymentMethodId: 0,
  selectedCreditCard: {
    id: null,
    usuarioId: 0,
    identificacao: '',
    anoVencimento: 0,
    mesVencimento: 0,
    excluido: 0,
    bandeiraCartaoCreditoId: 0,
    hashTokenizado: '',
    padrao: false,
    ultimos4Digitos: 0,
    bandeiraCartaoCredito: {
      id: 0,
      nome: '',
      excluido: false
    }
  },
  billingAddress: null,
  creditCardHash: '',
  installmentsCreditCard: {
    parcelas: 1,
    valorParcela: null,
    valorTotal: null
  }
}

export function orderPayment(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethodId: action.payload,
      }
    case 'SET_SELECTED_CREDIT_CARD':
      return {
        ...state,
        selectedCreditCard: action.payload,
      }
    case 'SET_CREDIT_CARD_HASH':
      return {
        ...state,
        billingAddress: action.payload.billingAddress,
        creditCardHash: action.payload.creditCardHash
      }
    case 'SET_INSTALLMENTS_CREDIT_CARD':
      return {
        ...state,
        installmentsCreditCard: action.payload
      }
    default:
      return state
  }
}
