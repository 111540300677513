import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import {
  Box,
  Grid,
  useMediaQuery,
  useTheme,
  withStyles
} from '@material-ui/core'

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <div className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          onClick={onClose}
          aria-label="close"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  )
})

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  imageGooglePlay: {
    [theme.breakpoints.up('sm')]: {
      width: '24vw'
    },
    width: '34vw',
    height: 'auto'
  },
  imageAppStore: {
    [theme.breakpoints.up('sm')]: {
      width: '22vw'
    },
    width: '32vw',
    height: 'auto'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogDownloadApp = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    isMobile &&
      setTimeout(() => {
        setOpen(true)
      }, 3000)
  }, [isMobile])

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose} />
      <Box padding={3} bgcolor={theme.palette.secondary.main}>
        <Box mt={3} display="flex" justifyContent={'center'}>
          <img width={'140px'} alt="Logo" src={require('img/img-login.svg')} />
        </Box>
        <Typography
          variant="h2"
          align="center"
          style={{ fontFamily: 'Noir', color: '#F4F6F8', marginTop: 30 }}
        >
          diversão
        </Typography>
        <Typography
          variant="h2"
          align="center"
          style={{ fontFamily: 'Noir', color: '#F4F6F8' }}
        >
          conexão
        </Typography>
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          style={{ fontFamily: 'Noir', color: '#F4F6F8' }}
        >
          <span style={{ color: theme.palette.primary.main }}>&</span>
          muito +
        </Typography>
        <Box mt={4} mb={3}>
          <Typography align="center" gutterBottom style={{ color: '#F4F6F8' }}>
            Tenha uma experiência única e completa no app.
          </Typography>
        </Box>
        <Grid
          container
          alignContent="space-between"
          justifyContent="space-between"
          alignItems="space-between"
          direction="row"
          spacing={1}
        >
          <Grid item xs={6}>
            <a
              href="https://play.google.com/store/apps/details?id=com.quintos.corujas"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className={classes.imageGooglePlay}
                src="https://corujas.s3.us-east-2.amazonaws.com/images/general/google-play-badge+(1).svg"
              />
            </a>
          </Grid>
          <Grid item xs={6}>
            <a
              style={{
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }}
              href="https://apps.apple.com/us/app/corujas/id1593667736"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className={classes.imageAppStore}
                src="https://corujas.s3.us-east-2.amazonaws.com/images/general/Download_on_the_App_Store_Badge_PTBR_RGB_blk_092917.svg"
              />
            </a>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DialogDownloadApp
