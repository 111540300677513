import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useSearchQuery } from 'utils'
import { useMemberSubscription } from 'hooks-querys'
import { SubscriptionItem } from './components'

const buttonList = [
  { filter: 'PENDENTES', label: 'PENDENTES', pagoValue: 'false' },
  { filter: 'PAGOS', label: 'PAGOS', pagoValue: 'true' },
  { filter: 'TODOS', label: 'TODOS', pagoValue: '' }
]

const MembershipFinancial = () => {
  const history = useHistory()
  const querySearch = useSearchQuery()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { membershipId, tab } =
    useParams<{ tab: 'general' | 'financial'; membershipId: string }>()
  const [enableRefetchInterval, setEnableRefetchInterval] = useState(false)
  const isANewPurchase = querySearch.get('new')
  const filterPago = querySearch.get('Pago')

  const {
    data: memberSubscriptions,
    isLoading,
    isFetching,
    isError
  } = useMemberSubscription(
    {
      membroPlanoId: Number(membershipId),
      pago: filterPago,
      page: 1,
      limit: 9999
    },
    {
      config: {
        refetchInterval: enableRefetchInterval ? 1000 : undefined
      }
    }
  )
  const totalItems = memberSubscriptions?.totalRows
  useEffect(() => {
    if (
      memberSubscriptions?.totalRows === 0 &&
      isANewPurchase &&
      filterPago === 'false'
    ) {
      setEnableRefetchInterval(true)
    } else {
      setEnableRefetchInterval(false)
    }
  }, [memberSubscriptions, isANewPurchase, filterPago])

  useEffect(() => {
    const pagoParam = filterPago

    const validPagoValue = buttonList.some(
      (item) => item.pagoValue === pagoParam
    )

    if (!validPagoValue) {
      // Redirecionar para 'TODOS' se o valor de 'Pago' não for válido
      history.push('?Pago=false')
    }
  }, [querySearch, history])

  const handleButtonClick = (pagoValue: string | undefined) => {
    if (pagoValue === undefined) {
      // Se o valor de Pago for undefined, remover o parâmetro 'Pago' da URL
      history.push('')
    } else {
      history.push(`?Pago=${pagoValue}`)
    }
  }

  const title = 'LISTA DE PAGAMENTO'

  return (
    <Box mt={2}>
      <Typography align="center" variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      <ButtonGroup fullWidth variant="outlined" color="primary">
        {buttonList.map((item) => (
          <Button
            key={item.filter}
            onClick={() => handleButtonClick(item.pagoValue)}
            style={{ borderRadius: 3 }}
            variant={filterPago === item.pagoValue ? 'contained' : 'outlined'}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
      {!isLoading &&
      !isFetching &&
      !isError &&
      totalItems &&
      totalItems <= 0 ? (
        <Box mt={4}>
          <Typography variant="h5" align="center">
            Nenhuma fatura encontrada!
          </Typography>
        </Box>
      ) : null}
      {!isLoading && !isFetching && isError ? (
        <Box mt={4}>
          <Typography variant="h5" align="center">
            Não foi possível carregar as faturas. Tente novamente.
          </Typography>
        </Box>
      ) : null}
      {isLoading && (
        <Box
          marginY={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Typography gutterBottom variant="h4" align="center">
            Carregando...
          </Typography>
          <Box marginTop={2}>
            <CircularProgress />
          </Box>
        </Box>
      )}
      <Box paddingY={4}>
        <Grid container spacing={isMobile ? 1 : 2} alignItems="stretch">
          {totalItems && totalItems > 0
            ? memberSubscriptions?.items?.map((item) => (
                <Grid item key={item?.id} xs={12} sm={6} md={4}>
                  <SubscriptionItem key={item?.id} item={item} />
                </Grid>
              ))
            : null}
        </Grid>
      </Box>
    </Box>
  )
}

export default MembershipFinancial
