import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import {
  Tabs,
  Tab,
  makeStyles,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded'

//? Own imports
import { Pix as PixIcon, Boleto as BoletoIcon } from 'icons'
import { CreditCard, Pix, Boleto } from './components'
import { orderPaymentActions } from '_actions'
import { useDidMountEffect } from 'components/Utils'
import { PlanoValor } from 'types/api'
import { useMutationCreateMember } from 'hooks-querys'
import { validateUserNecessaryData } from 'utils'
import { history } from '_helpers'

//@ts-ignore
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    }
  },
  tabs: {
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.divider}`
    },
    '& .Mui-selected': {
      fontWeight: 'bold'
    }
  },
  labelContainer: {
    width: 'auto',
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}))

export default function PaymentMethods({
  planValue
}: {
  planValue: PlanoValor
}) {
  const classes = useStyles()
  const plan = planValue?.plano
  const { paymentMethodId, creditCardHash, selectedCreditCard } = useSelector(
    //@ts-ignore
    (state) => state.orderPayment
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  //@ts-ignore
  const user = useSelector((state) => state.authentication.user)
  //@ts-ignore
  const handleChange = (event, newValue) => {
    dispatch(orderPaymentActions.changePaymentMethod(newValue))
  }
  const totalValue = planValue?.valor
  const refCreditCard = useRef(null)

  const scrollToCreditCardView = () =>
    //@ts-ignore
    refCreditCard.current.scrollIntoView(true)

  useEffect(() => {
    dispatch(orderPaymentActions.changePaymentMethod(2))
    return () => {
      dispatch(orderPaymentActions.changePaymentMethod(0))
    }
  }, [])

  useDidMountEffect(() => {
    if (creditCardHash !== '') {
      isMobile
        ? scrollToCreditCardView()
        : window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [creditCardHash])

  const payload = {
    id: 0,
    nome: user?.nome,
    email: user?.email,
    cpfCnpj: user?.cpfCnpj
      ? user?.cpfCnpj.split('.').join('').replace(/[-/.]/g, '')
      : null,
    imagem: user?.imagem,
    telefone: user?.telefone,
    dataNascimento: user?.dataNascimento,
    usuarioId: user?.id,
    cidadeId: user?.cidadeId,
    genero: user?.genero,
    endereco: user?.endereco,
    bairro: user?.bairro,
    numero: user?.numero,
    complemento: user?.complemento,
    cep: user?.cep,
    membroPlano: [
      {
        id: 0,
        planoId: plan?.id,
        planoValorId: planValue?.id,
        dataHoraInicio: new Date(),
        formaPagamentoId: creditCardHash ? 1 : paymentMethodId,
        statusMembroPlanoId: 1,
        hashCartaoCreditoUnico: creditCardHash || null,
        cartaoCreditoId: selectedCreditCard?.id
      }
    ]
  }
  //@ts-ignore
  const mutationCreateMembership = useMutationCreateMember(payload)
  const mutationResult = mutationCreateMembership?.data

  const handleSubmit = () => {
    if (validateUserNecessaryData(dispatch, user)) {
      mutationCreateMembership
        .mutateAsync()
        .then((data) => {
          if (data?.membroPlano && data?.membroPlano.length > 0) {
            if (data?.membroPlano[0]?.formaPagamentoId === 1) {
              history.push(
                `/membership-settings/financial/${data?.membroPlano[0]?.id}?Pago=true&new=true`
              )
            } else {
              history.push(
                `/membership-settings/financial/${data?.membroPlano[0]?.id}?Pago=false&new=true`
              )
            }
          }
        })
        .catch((error) => console.log(`error`, error))
    }
  }

  return (
    <div className={classes.root} ref={refCreditCard}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <Tabs
            orientation={!isMobile ? 'vertical' : 'horizontal'}
            variant={!isMobile ? 'scrollable' : 'standard'}
            centered={isMobile}
            value={paymentMethodId}
            onChange={handleChange}
            className={classes.tabs}
            indicatorColor="primary"
            textColor="secondary"
          >
            {/* //!TODO: Waiting backend */}
            {/* <Tab
              classes={
                !isMobile
                  ? {
                      wrapper: classes.iconLabelWrapper,
                      //@ts-ignore
                      labelContainer: classes.labelContainer
                    }
                  : {}
              }
              icon={
                <PixIcon
                  outlined={paymentMethodId !== 0}
                  className={classes.icon}
                />
              }
              label="PIX"
              {...a11yProps(1)}
            /> */}

            <Tab
              classes={
                !isMobile
                  ? {
                      wrapper: classes.iconLabelWrapper,
                      //@ts-ignore
                      labelContainer: classes.labelContainer
                    }
                  : {}
              }
              value={2}
              icon={<BoletoIcon className={classes.icon} />}
              label="Boleto"
              {...a11yProps(2)}
            />
            <Tab
              classes={
                !isMobile
                  ? {
                      wrapper: classes.iconLabelWrapper,
                      //@ts-ignore
                      labelContainer: classes.labelContainer
                    }
                  : {}
              }
              value={1}
              icon={<CreditCardRoundedIcon className={classes.icon} />}
              label="Cartão de credito"
              {...a11yProps(0)}
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} md={8}>
          {/* //!TODO: Waiting backend */}
          {/* <TabPanel value={paymentMethodId} index={2}>
            <Pix handleSubmit={handleSubmit} />
          </TabPanel> */}

          <TabPanel value={paymentMethodId} index={2}>
            <Boleto handleSubmit={handleSubmit} />
          </TabPanel>

          {/*@ts-ignore */}
          <TabPanel style={{ width: '100%' }} value={paymentMethodId} index={1}>
            <CreditCard handleSubmit={handleSubmit} />
          </TabPanel>
        </Grid>
      </Grid>
    </div>
  )
}
