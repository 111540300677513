import { useQuery } from 'react-query'

//? Own imports
import { crudService } from '_services/'
import { MemberListParams, MemberListReturn, UseQueryOptions } from './member'

const getMemberList = async (
  params?: MemberListParams
): Promise<MemberListReturn> => {
  try {
    const { data } = await crudService.get(
      `membrosplanos/usuario/${params?.userId}`
    )
    if (data) {
      return data
    } else {
      throw new Error('Invalid response')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default function useMemberList(
  params?: MemberListParams,
  options?: UseQueryOptions<MemberListReturn>
) {
  return useQuery<MemberListReturn>(
    ['memberList', params],
    () => getMemberList(params),
    {
      enabled: !!params?.userId,
      cacheTime: Infinity,
      staleTime: 600000, // 10 minutes
      ...options?.config
    }
  )
}
