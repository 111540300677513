import { useQuery } from 'react-query'
import { crudService } from '_services'
import { useMemo } from 'react'

const getEstabelecimento = async (estabelecimentoId) => {
  const { data } = await crudService.get(
    `estabelecimentos/web/${estabelecimentoId}`
  )
  return data
}

export default function useEstabelecimento(
  estabelecimentoId,
  estabelecimentoItem
) {
  const initialData = useMemo(() => estabelecimentoItem, [estabelecimentoItem])
  return useQuery(
    ['estabelecimentoDetails', estabelecimentoId],
    () => getEstabelecimento(estabelecimentoId),
    { initialData }
  )
}
