import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Grid } from '@material-ui/core'
import TabContext from '@material-ui/lab/TabContext'
import TabPanel from '@material-ui/lab/TabPanel'
import Typography from '@material-ui/core/Typography'
import EventAvailableTwoToneIcon from '@material-ui/icons/EventAvailableTwoTone'
import EventBusyTwoToneIcon from '@material-ui/icons/EventBusyTwoTone'
import EventosListColumn from 'components/EventosList/EventosListColumn'
import { useEventosEstabelecimento } from 'hooks-querys'

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 50,
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }
  }
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  demo2: {
    backgroundColor: 'transparent'
  },
  cardGrid: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(15),
      paddingLeft: theme.spacing(15)
    },
    [theme.breakpoints.down('sm')]: {}
  }
}))
export default function CustomizedTabs(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState('1')
  const { data, refetch, isLoading, isFetching, isError } =
    useEventosEstabelecimento(
      props.establishmentId,
      value === '1' ? true : false
    )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <div className={classes.demo2}>
          <StyledTabs
            centered
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab
              value="1"
              icon={<EventAvailableTwoToneIcon />}
              label="PRÓXIMOS EVENTOS"
            />
            <StyledTab
              value="2"
              icon={<EventBusyTwoToneIcon />}
              label="EVENTOS PASSADOS"
            />
          </StyledTabs>
          <Typography className={classes.padding} />
        </div>
        <TabPanel value="1">
          <Grid className={classes.cardGrid}>
            {data && data.length > 0 ? (
              <EventosListColumn eventos={data} />
            ) : (
              <>
                <Typography gutterBottom variant="h2" color="primary">
                  <strong>Opssss! 😥</strong>{' '}
                </Typography>
                <Typography gutterBottom variant="h4" color="textPrimary">
                  Nenhum evento foi encontrado...
                </Typography>
              </>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <Grid className={classes.cardGrid}>
            {data && data.length > 0 ? (
              <EventosListColumn eventos={data} />
            ) : (
              <>
                <Typography gutterBottom variant="h2" color="primary">
                  <strong>Opssss! 😥</strong>{' '}
                </Typography>
                <Typography gutterBottom variant="h4" color="textPrimary">
                  Nenhum evento foi encontrado...
                </Typography>
              </>
            )}
          </Grid>
        </TabPanel>
      </TabContext>
    </div>
  )
}
