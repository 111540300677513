import { useEffect, useState } from 'react'

//? Own imports
import { hasItemsWithCheckIn as hasItemsWithCheckInFunction } from '../../utils/cartItemsFilters'
import { useCarrinhoItensEvento } from 'hooks-querys'

const useCartItemsWithCheckIn = (eventId: number) => {
  const { data: cartItems, ...rest } = useCarrinhoItensEvento(eventId)

  const [hasItemsWithCheckIn, setHasItemsWithCheckIn] = useState<boolean>(false)

  useEffect(() => {
    if (cartItems) {
      setHasItemsWithCheckIn(hasItemsWithCheckInFunction(cartItems))
    }
  }, [cartItems])

  return {
    hasItemsWithCheckIn,
    ...rest
  }
}

export default useCartItemsWithCheckIn
