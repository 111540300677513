import { useQuery } from 'react-query'
import { Cidade } from 'types/api'
import { crudService } from '_services'

const getCitiesByState = async (stateId: number) => {
  const endPoint = 'cidades/estado/' + stateId
  const { data } = await crudService.get(endPoint)
  return data
}

export default function useCitiesByState(stateId: number) {
  return useQuery<Cidade[]>(
    ['citiesByState', stateId],
    () => getCitiesByState(stateId),
    {
      cacheTime: Infinity,
      enabled: stateId && stateId > 0 ? true : false
    }
  )
}
