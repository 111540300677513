import axios from 'axios'
import { useQuery } from 'react-query'
import { errorHandler } from '_services/errorHandler'
import { ViaCepReturn } from '../types/api'

const getAddressByZipCode = async (zipCode: number | string) => {
  if (zipCode) {
    const { data } = await axios
      .get(`https://viacep.com.br/ws/${zipCode}/json`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return errorHandler(error)
      })
    return data
  } else {
    return undefined
  }
}

export default function useAddressByZipCode(
  zipCode: number | string,
  enabled: boolean
) {
  return useQuery<ViaCepReturn>(
    ['addressByZipCode', zipCode],
    () => getAddressByZipCode(zipCode),
    { enabled: enabled, cacheTime: Infinity, staleTime: Infinity }
  )
}
