const initialState = {
  searching: false,
  texto: '',
  categorias: [],
  result: {},
  params: {}
}

export function search(state = initialState, action) {
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return {
        ...state,
        searching: true,
        params: action.params
      }
    case 'SEARCH_SUCCESS':
      return {
        ...state,
        searching: false,
        result: action.result
      }
    case 'SEARCH_FAILURE':
      return {
        ...state,
        searching: false,
        error: action.error
      }
    case 'SEARCH_HOME_REQUEST':
      return {
        ...state,
        searching: true,
        params: action.params
      }
    case 'SEARCH_HOME_SUCCESS':
      return {
        ...state,
        searching: false,
        result: action.result
      }
    case 'SEARCH_HOME_FAILURE':
      return {
        ...state,
        searching: false,
        error: action.error
      }
    case 'SEARCH_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'SEARCH_HANDLE_ON_CHANGE_CATEGORIA':
      return {
        ...state,
        categorias: action.value
      }
    case 'SEARCH_CLEAR_PROP':
      return {
        ...state,
        [action.props]: ''
      }
    case 'SEARCH_CLEAR_CATEGORIAS':
      return {
        ...state,
        categorias: []
      }
    case 'SEARCH_CLEAR_ALL_PARAMS':
      return {
        ...state,
        texto: '',
        categorias: []
      }
    default:
      return state
  }
}
