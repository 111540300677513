import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

const TicketsSkeleton = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const size = isMobile ? '100%' : '90%'
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid xs={12} sm={6} md={4}>
        <Skeleton animation="wave" variant="rect" width={size} height={130} />
        <Skeleton animation="wave" width={size} />
        <Skeleton animation="wave" width={size} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <Skeleton animation="wave" variant="rect" width={size} height={130} />
        <Skeleton animation="wave" width={size} />
        <Skeleton animation="wave" width={size} />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <Skeleton animation="wave" variant="rect" width={size} height={130} />
        <Skeleton animation="wave" width={size} />
        <Skeleton animation="wave" width={size} />
      </Grid>
    </Grid>
  )
}

export default TicketsSkeleton
