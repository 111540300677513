import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { LinearProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { PurchaseApplicationFormItem } from '../../purchaseApplicationFormUtils'
import { setActiveStep } from '_actions'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

export default function DotsMobileStepper() {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm
        ?.purchaseApplicationFormItens as PurchaseApplicationFormItem[]
  )
  const activeStep = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.activeStep as number
  )

  const steps = purchaseApplicationFormItens?.length + 2
  const handleNext = () => {
    handleStepChange(activeStep + 1)
  }

  const handleBack = () => {
    handleStepChange(activeStep - 1)
  }

  const calculateProgress = () => {
    return (activeStep / (steps - 1)) * 100
  }
  const handleStepChange = (step: number) => {
    dispatch(setActiveStep(step))
  }

  if (!steps || steps <= 0) return null
  return (
    <>
      <MobileStepper
        style={{ borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        title="Ingressos"
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === 5}>
            Próximo
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
      <LinearProgress variant="determinate" value={calculateProgress()} />
    </>
  )
}
