import { connect, useDispatch } from 'react-redux'
import { ingressoAction, authActions, checkoutActions } from '../_actions'
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { withRouter } from 'react-router-dom'
import { history } from '../_helpers'
import clsx from 'clsx'
import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SaveIcon from '@material-ui/icons/Save'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Container } from '@material-ui/core'
import Footer from '../_components/footer2'

const drawerWidth = 0

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  appFrame: {
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  imagem: {
    width: '100%',
    display: 'block',
    maxWidth: '500px'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  'appBar-left': {
    marginLeft: drawerWidth
  },
  'appBar-right': {
    marginRight: drawerWidth
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  icon: {
    padding: theme.spacing(3)
  },
  paddingDown: {
    padding: theme.spacing(0, 0, 3, 0)
  },
  paddingUp: {
    padding: theme.spacing(4, 0, 0, 0)
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6)
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  card: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardImage: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6)
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  text: {
    textAlign: 'justify'
  }
})

const TermosDeUso = (props) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth="md">
            <Grid container spacing={24}>
              <Grid item xs={6} container justify="flex-start">
                <Typography className={classes.paddingUp} variant="h6">
                  <strong>{'TERMOS DE USO'}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
            <br />
            <br />
            <Grid container paper spacing={24}>
              <Typography className={classes.text} variant="body1">
                CONTRATO DE PRESTAÇÃO DE SERVIÇOS
                <br />
                <br />
                CONDIÇÕES GERAIS DE UTILIZAÇÃO DO SITE CORUJA TICKETS
                <br />
                <br />
                1 - CONDIÇÕES GERAIS E SUA ACEITAÇÃO
                <br />
                Estas condições gerais (doravante denominadas "Condições
                Gerais") regulamentam a utilização dos serviços de compra de
                vouchers e ingressos (doravante denominadas "Serviço")
                existentes no Site Corujas, registrado sob o domínio
                www.corujatickets.com (doravante "Site") que a Q5S Tecnologia
                LTDA., localizada em Lajeado, Estado do Rio Grande do Sul, CEP
                95.914-010, inscrita no CNPJ/MF sob nº 29.228.835/0001-85
                (Doravante denominada "Corujas") disponibiliza aos usuários de
                Internet.
                <br />
                A utilização do Serviço atribui a condição de usuário do Serviço
                (doravante, o "Usuário") e expressa a aceitação plena e sem
                reservas do Usuário a todas e a cada uma das Condições Gerais na
                versão publicada pela Corujas no exato momento em que utilizar o
                Serviço. Em consequência, o Usuário deve ler atentamente as
                Condições Gerais em cada uma das ocasiões em que se propuser a
                utilizá-lo.
                <br />
                Ao concordar com as presentes Condições Gerais, o Usuário
                declara: a) Ser maior, na forma da legislação civil, e estar no
                pleno gozo de sua capacidade civil plena; ou b) ter relativa
                capacidade civil, mas ter sido assistido por seus responsáveis
                no momento de acesso ao Site e seu consentimento para o uso; c)
                que não permitirá e nem fornecerá meios para que incapazes
                utilizem os serviços do Site; e d) que acessará o conteúdo do
                Site em locais cuja legislação defina-o como ilegal.
                <br />
                Todos os avisos, regulamentos e instruções do Site complementam
                as presentes Condições Gerais, no que não divergirem do presente
                instrumento.
                <br />
                <br />
                2 - OBJETO
                <br />
                As presentes Condições Gerais regulamentam a prestação do
                Serviço por parte da Corujas e a utilização do Serviço por parte
                dos Usuários.
                <br />
                A Corujas se reserva o direito a modificar unilateralmente, em
                qualquer momento e sem prévio aviso, a apresentação, a
                configuração do Serviço, programas e plataformas disponíveis,
                assim como também as condições ora requeridas para utilização do
                mesmos.
                <br />
                O Usuário se compromete a verificar constantemente as presentes
                Condições Gerais para garantir que está ciente de quaisquer
                alterações que, porventura, venham a ser realizadas nas mesmas.
                <br />
                <br />
                3 - SERVIÇO
                <br />
                Através do Serviço, os Usuários podem adquirir de forma online,
                ingressos para festas e eventos divulgados no Site.
                <br />
                A compra dos ingressos pelo Usuário poderá ser realizada até o
                limite máximo indicado por Usuário, especificado no site.
                <br />
                Os ingressos permanecerão disponíveis para a venda até a data
                divulgada para encerramento das vendas, ressalvado, porém o
                direito da Corujas, proceder o encerramento antecipado das
                vendas a qualquer tempo, caso todos os ingressos estejam
                esgotados ou em decorrência de orientação dos Organizadores do
                evento.
                <br />
                <br />
                4 - CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SERVIÇO
                <br />
                4.1 O Usuário reconhece, desde já, que é condição essencial para
                utilização do Serviço a obediência às regras das presentes
                Condições Gerais, obrigando-se a preencher seu cadastro de
                usuário com dados verdadeiros e isentando a Corujas de qualquer
                responsabilidade quanto a dados falsos que sejam inseridos no
                referido cadastro. Outrossim, o Usuário reconhece que as normas
                pertinentes aos Termos de Uso integram as presentes Condições
                Gerais e que deverão ser rigorosamente observadas pelo Usuário
                enquanto estiver utilizando o Serviço.
                <br />
                4.2 Uso e custódia
                <br />
                O Usuário se compromete a fazer um uso diligente das Senhas de
                Acesso, assim como a mantê-las em segredo, conforme demais
                avisos, regulamentos e instruções do Site. O Usuário se
                compromete a comunicar a Corujas, imediatamente, a perda ou
                roubo das Senhas de Acesso assim como qualquer risco de acesso
                às mesmas por um terceiro, de acordo com o estabelecido na
                Política de Privacidade do Site.
                <br />
                4.3. Responsabilidade do Usuário
                <br />
                O Usuário se compromete, em geral, a utilizar o Serviço em
                conformidade com as presentes Condições Gerais e demais normas e
                regulamentos da Corujas aplicáveis ao serviço, bem como a lei, a
                moral e os bons costumes aceitos e a ordem pública.
                <br />
                O Usuário se compromete a utilizar o Serviço de forma adequada e
                diligente, assim como a se abster de utilizá-lo com objetivos ou
                meio para a prática de atos ilícitos, proibidos pela lei e pelas
                presentes Condições Gerais, lesivos aos direitos e interesses de
                terceiros, ou que, de qualquer forma, possa danificar,
                inutilizar, sobrecarregar ou deteriorar o Serviço, os
                equipamentos informáticos de outros Usuários ou de outros
                internautas (hardware e software) assim como os documentos,
                arquivos e toda classe de conteúdos armazenados nos seus
                equipamentos informáticos (hacking) ou impedir a normal
                utilização ou goze do referido Serviço, equipamentos
                informáticos e documentos, arquivos e conteúdos por parte dos
                demais Usuários e de outros internautas.
                <br />
                A Corujas se reserva o direito de permitir ou negar o acesso,
                temporário ou definitivo, ao "Site", assim como, cancelar o
                registro do Usuário a qualquer momento e sem prévio aviso, caso
                seja constatado que este praticou algum ato ou mantenha conduta
                que (i) viole as leis e regulamentos federais, estaduais e/ou
                municipais, (ii) contrarie estas Condições Gerais, (iii) viole
                os princípios da moral e dos bons costumes. Os Usuários cujos
                registros forem cancelados não poderão continuar a utilizar o
                Serviço.
                <br />
                O USUÁRIO É CONSCIENTE, E ACEITA VOLUNTARIAMENTE QUE O USO DO
                SERVIÇO OCORRA, EM TODO CASO, SOB A SUA ÚNICA E EXCLUSIVA
                RESPONSABILIDADE.
                <br />
                <br />
                5 – DESISTÊNCIA DE COMPRA
                <br />
                O Usuário poderá exercer o direito de arrependimento da compra
                no prazo máximo de 7 (sete) dias contados da compra, desde que o
                evento ainda não tenha sido realizado.
                <br />
                O pedido de cancelamento da compra deverá ser realizado pelo
                e-mail cancelamento@corujatickets.com.
                <br />
                Não cabe o pedido de desistência e reembolso do valor pago caso
                o Usuário adquira o ingresso com menos de 48 (quarenta e oito)
                horas do início o evento.
                <br />
                <br />
                6 - DADOS DE CARÁTER PESSOAL
                <br />
                Para utilizar o Serviço, os Usuários devem realizar previamente
                o Cadastro de suas informações pessoais no Portal (doravante, os
                "Dados Pessoais").
                <br />
                Mediante o preenchimento e envio do correspondente Cadastro, o
                Usuário aceita e autoriza que os seus Dados Pessoais sejam
                objeto de tratamento automatizado por parte da Corujas, nos
                termos indicados na Política de Privacidade da Coruja Tickets,
                da qual declara ter plena ciência e compreensão de suas
                disposições.
                <br />
                Os Usuários garantem a veracidade, exatidão, vigência e
                autenticidade dos Dados Pessoais fornecidos, e se comprometem a
                mantê-los devidamente atualizados.
                <br />
                <br />
                7 - DURAÇÃO E FINALIZAÇÃO
                <br />
                A prestação do Serviço tem, a princípio, uma duração por prazo
                indeterminado. A Corujas, não obstante, reserva-se o direito de
                dar por terminada ou suspender a prestação do Serviço a qualquer
                tempo, a seu exclusivo critério e sem prévio aviso ao Usuário.
                <br />
                <br />
                8 - FORO
                <br />
                Fica eleito o foro da Comarca de Lajeado – Rio Grande do Sul
                para solucionar quaisquer controvérsias oriundas do presente
                instrumento ou da utilização do Serviço.
                <br />
                <br />
                Para qualquer sugestão ou colaboração, entre em contanto conosco
                através do email contato@corujatickets.com.
              </Typography>
            </Grid>
            <br />
            <br />
            <Grid className={classes.paddingDown} container spacing={24}></Grid>
          </Container>
        </main>
      </div>
    </div>
  )
}
TermosDeUso.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    state
  }
}
const connectedTermosDeUsoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(TermosDeUso))
)
export { connectedTermosDeUsoPage as TermosDeUso }
