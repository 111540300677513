import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background?.paper,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(2)
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  leftContainer: {
    marginRight: theme.spacing(2)
  },
  leftContentItem: {
    marginBottom: theme.spacing(1)
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
}))
