import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'

//? Own imports
import { Evento } from 'types/api'
import { useStyles } from './styles'

type EventDetailsProps = {
  event: Evento
}

const EventDetails = ({ event }: EventDetailsProps) => {
  const classes = useStyles()
  return (
    <div>
      <Grid container spacing={1} justifyContent="flex-start">
        <Grid item xs={12} md={5}>
          <img className={classes.imageEvent} src={event?.imagem} />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h5" align="left" gutterBottom>
            <strong> {event?.nome}</strong>
          </Typography>
          {event?.dataHoraInicio && (
            <Typography variant="body2" color="textSecondary" align="left">
              {'Começa: '}
              {format(
                new Date(event?.dataHoraInicio),
                `eeeeee, d 'de' MMM 'às' kk':'mm`,
                {
                  locale: brLocale
                }
              )}{' '}
            </Typography>
          )}
          {event?.dataHoraFim && (
            <Typography
              variant="body2"
              color="textSecondary"
              align="left"
              gutterBottom
            >
              {'Termina: '}
              {format(
                new Date(event?.dataHoraFim),
                `eeeeee, d 'de' MMM 'às' kk':'mm`,
                {
                  locale: brLocale
                }
              )}{' '}
            </Typography>
          )}
          {event?.nomeLocal ? (
            <Typography variant="body2" color="textSecondary" align="left">
              Local: {event?.nomeLocal}
            </Typography>
          ) : null}
          {event?.endereco ? (
            <Typography variant="body2" color="textSecondary" align="left">
              {event?.endereco}
              {event?.numero ? `, ${event?.numero}` : ''}
            </Typography>
          ) : null}
          {event?.bairro ? (
            <Typography variant="body2" color="textSecondary" align="left">
              {event?.bairro}
              {event?.cidade ? ` - ${event?.cidade.nome}` : ''}
              {event?.cidade && event?.cidade.estado
                ? ` - ${event?.cidade.estado.uf}`
                : ''}
            </Typography>
          ) : null}
          {event?.cep ? (
            <Typography variant="body2" color="textSecondary" align="left">
              {event?.cep ? `${event?.cep}` : ''}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  )
}

export default EventDetails
