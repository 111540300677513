import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import ShareIcon from '@material-ui/icons/Share'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import LinkIcon from '@material-ui/icons/Link'
import { toast } from 'react-toastify'
import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core'
import { onShare, useStateCallback } from 'utils'

const useStyles = makeStyles((theme) => ({
  shareIcons: {
    '&:hover': {
      color: theme.palette.primary.main,
      transform: 'rotate(-6deg)'
    }
  }
}))

function SocialShare({ evento }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isGeneratingDynamicLink, setIsGeneratingDynamicLink] = useState(false)
  const [open, setOpen] = useState(false)
  const [link, setLink] = useStateCallback('')
  const linkWhatsApp = isMobile
    ? 'whatsapp://send?text='
    : 'https://wa.me/?text='

  const actions = [
    {
      icon: <FacebookIcon className={classes.shareIcons} color="action" />,
      name: 'Facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=@{link}`
    },
    {
      icon: <TwitterIcon className={classes.shareIcons} color="action" />,
      name: 'Twitter',
      href: `https://twitter.com/intent/tweet?url=@{link}&text=${evento.nome}`
    },
    {
      icon: <LinkedInIcon className={classes.shareIcons} color="action" />,
      name: 'LinkedIn',
      href: `https://www.linkedin.com/shareArticle?mini=true&url=@{link}&title=${evento.nome}&summary=${evento.nome}&source=`
    },
    {
      icon: <WhatsAppIcon className={classes.shareIcons} color="action" />,
      name: 'WhatsApp',
      href: `${linkWhatsApp}Olá, veja só esta indicação de evento disponível no Corujas! ${evento.nome} - @{link}`
    }
  ]
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <SpeedDial
        FabProps={{ size: 'small' }}
        ariaLabel="Compartilhar"
        className={classes.speedDial}
        icon={
          isGeneratingDynamicLink ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ShareIcon fontSize="small" />
          )
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="rigth"
      >
        {actions.map((action) => (
          <SpeedDialAction
            FabProps={{ size: 'small' }}
            key={action.name}
            icon={
              isGeneratingDynamicLink ? (
                <CircularProgress size={20} />
              ) : (
                action.icon
              )
            }
            tooltipTitle={action.name}
            tooltipPlacement={'bottom'}
            onClick={async () => {
              try {
                if (link === '') {
                  setIsGeneratingDynamicLink(true)
                  const link = await onShare(evento)
                  setLink(link, (newLink) => {
                    setIsGeneratingDynamicLink(false)
                    window.open(
                      action.href.replace('@{link}', newLink),
                      '_blank'
                    )
                  })
                } else {
                  window.open(action.href.replace('@{link}', link), '_blank')
                }
              } catch (error) {}
            }}
          />
        ))}
        <SpeedDialAction
          FabProps={{ size: 'small' }}
          key="Copiar link"
          icon={
            isGeneratingDynamicLink ? (
              <CircularProgress size={20} />
            ) : (
              <LinkIcon className={classes.shareIcons} color="action" />
            )
          }
          tooltipTitle="Copiar link"
          tooltipPlacement={'bottom'}
          onClick={async () => {
            if (link === '') {
              setIsGeneratingDynamicLink(true)
              const link = await onShare(evento)
              setIsGeneratingDynamicLink(false)
              setLink(link, (newLink) => {
                setIsGeneratingDynamicLink(false)
                try {
                  navigator.clipboard.writeText(newLink)
                  toast.success('Link copiado com sucesso!')
                } catch (error) {
                  toast.error('Falha ao copiar link!')
                }
              })
            } else {
              try {
                await navigator.clipboard.writeText(link)
                toast.success('Link copiado com sucesso!')
              } catch (error) {
                toast.error('Falha ao copiar link!')
              }
            }
          }}
        />
      </SpeedDial>
    </div>
  )
}

export default SocialShare
