import { crudService } from '_services'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

const postDeleteCarrinhoItem = async (carrinhoItemId) => {
  const { data } = await crudService.deleteDetail(
    `carrinhoItens/${carrinhoItemId}`
  )
  return data
}

export default function useMutationDeleteCarrinhoItem(
  carrinhoItem
) {
  const queryClient = useQueryClient()
  return useMutation(() => postDeleteCarrinhoItem(carrinhoItem.id), {
    retry: 3,
    onMutate: async () => {
      await queryClient.cancelQueries([
        'carrinhoItens',
        carrinhoItem.lote.eventoId
      ])
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ['carrinhoItens', carrinhoItem.lote.eventoId],
        (oldDate) => {
          const newCarrinhoItens = []
          oldDate.map((item) => {
            item.carrinhoItem.loteId === carrinhoItem.loteId
              ? newCarrinhoItens.push({
                ...item,
                carrinhoItem: {
                  ...item.carrinhoItem,
                  quantidade: 0
                }
              })
              : newCarrinhoItens.push(item)
          })

          return newCarrinhoItens
        }
      )
      queryClient.invalidateQueries([
        'carrinhoItens',
        carrinhoItem.lote.eventoId
      ])
    },
    onError: (_error, _variables, _context) => {
      toast.error('Opss! Não foi possível alterar a quantidade, tente novamente.')
    }
  })
}
