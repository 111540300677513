import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Toolbar,
  Tooltip,
  Avatar,
  Chip,
  Button,
  Hidden
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Link as RouterLink } from 'react-router-dom'
import { history } from '../../../../_helpers'
import { authActions } from '../../../../_actions'
import { InputSearchTopBar } from './inputSearchTopBar'
import { ButtonGroupPortalOrganizador } from 'components'

const styles = (theme) => ({
  root: { width: '100%' },
  grow: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  buttonOrganizeSeuEvento: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3)
    },
    marginRight: theme.spacing(1)
  },
  menuPopper: {
    marginTop: theme.spacing(5)
  },
  chip: {
    marginLeft: theme.spacing(2),
    backgroundColor: '#FFF'
  },
  chipMobile: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#FFF'
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
})

class TopBarLoggedIn extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  }

  logout = (event) => {
    const { dispatch } = this.props
    dispatch(authActions.logout())
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
    this.handleMobileMenuClose()
  }

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget })
  }

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null })
  }

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state
    const { classes } = this.props
    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const renderMenu = (
      <Menu
        className={classes.menuPopper}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItem onClick={() => history.push('/approot/perfil/tickets')}>
          Minha conta
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            this.logout()
          }}
        >
          Sair
        </MenuItem>
      </Menu>
    )

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/* <MenuItem onClick={this.handleProfileMenuOpen}> */}
        <MenuItem onClick={() => history.push('/approot/perfil/tickets')}>
          <IconButton color="inherit">
            <Avatar
              className={classes.avatar}
              alt="avatar_image"
              src={this.props.usuario.imagem}
            />
          </IconButton>
          <p>Minha conta</p>
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            this.logout()
          }}
        >
          <IconButton color="inherit">
            <ExitToAppIcon />
          </IconButton>
          <p>Sair</p>
        </MenuItem>
      </Menu>
    )

    return (
      <div className={classes.root}>
        <AppBar position="fixed" color="secondary">
          <Toolbar>
            <RouterLink to="/">
              <Hidden smDown>
                <img
                  width={'140px'}
                  onClick={() => history.push('/')}
                  src={require('../../../../img/img-login.svg')}
                  alt="logo"
                />
              </Hidden>
              <Hidden mdUp>
                <img
                  width={'20px'}
                  onClick={() => history.push('/')}
                  src={require('../../../../img/img-login-sem-texto.svg')}
                  alt="logo"
                />
              </Hidden>
            </RouterLink>
            <div className={classes.grow} />
            <Hidden smDown>
              <InputSearchTopBar />
              <div className={classes.buttonOrganizeSeuEvento}>
                <ButtonGroupPortalOrganizador />
              </div>

              <Chip
                avatar={
                  <Avatar
                    alt={this.props.usuario.nome}
                    src={this.props.usuario.imagem}
                  />
                }
                label={this.props.usuario.nome}
                clickable
                className={classes.chip}
                onDelete={this.handleProfileMenuOpen}
                deleteIcon={<MoreIcon />}
                onClick={this.handleProfileMenuOpen}
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
              />
            </Hidden>
            <Hidden mdUp>
              {/* <Button
                className={classes.buttonOrganizeSeuEvento}
                size="small"
                variant="outlined"
                href="https://sejaparceiro.corujasapp.com/"
                target="_blank"
                rel="noreferrer"
                color="inherit"
              >
                ORGANIZE SEU EVENTO
              </Button> */}
              <div className={classes.buttonOrganizeSeuEvento}>
                <ButtonGroupPortalOrganizador />
              </div>

              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
              {/* <Chip
                                avatar={<Avatar alt={this.props.usuario.nome} src={this.props.usuario.imagem} />}
                                // label={this.props.usuario.nome}
                                clickable
                                // size="small"
                                className={classes.chipMobile}
                                color="inherit"
                                // variant="outlined"
                                onDelete={this.handleProfileMenuOpen}
                                deleteIcon={<MoreIcon />}
                                aria-haspopup="true"
                                onClick={this.handleMobileMenuOpen}
                            /> */}
            </Hidden>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
      </div>
    )
  }
}

TopBarLoggedIn.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication
  return {
    usuario: state.authentication.user,
    loggingIn
  }
}
export default connect(mapStateToProps)(withStyles(styles)(TopBarLoggedIn))
