import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { TicketsList } from './components'
import { useSelector } from 'react-redux'
import { useUserTickets } from 'hooks-querys'
import { isFuture, isPast } from 'date-fns'
var pluralize = require('pluralize')

const useStyles = makeStyles((theme) => ({
  root: {},
  header: { paddingLeft: theme.spacing(2), paddingBottom: theme.spacing(2) }
}))

const MyTicketsDetails = ({ ingressos }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {ingressos && ingressos.length !== 0 && (
        <Typography
          className={classes.header}
          align="left"
          variant="h6"
          color="textPrimary"
        >
          {`${pluralize('ingresso', ingressos.length, true)} ${pluralize(
            'encontrados',
            ingressos.length
          )}:`}
        </Typography>
      )}
      <TicketsList tickets={ingressos} />
    </div>
  )
}

MyTicketsDetails.propTypes = {
  className: PropTypes.string
}

export default MyTicketsDetails
