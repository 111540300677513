import React, { useState } from 'react'
import {
  CircularProgress,
  Avatar,
  FormControlLabel,
  withStyles,
  Paper,
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  Link,
  IconButton,
  makeStyles,
  Divider,
  Container
} from '@material-ui/core'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { authActions } from '_actions'
import { history } from '_helpers'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookIcon from '@material-ui/icons/Facebook'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone'
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone'
import { useDispatch, useSelector } from 'react-redux'
import useRouter from 'utils/useRouter'

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    border: `0.5px dashed  ${theme.palette.black}`
  },
  submit: {
    margin: theme.spacing(0, 0, 2)
  },
  facebook: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: '#3b5998',
    '&:hover': {
      backgroundColor: '#3b5998',
      opacity: 0.8
    }
  }
}))
const FormLogin = () => {
  const classes = useStyles()
  const router = useRouter()
  const isLoginPath =
    router &&
    router.location &&
    router.location.pathname &&
    router.location.pathname === '/login'
  const [form, setForm] = useState({
    username: '',
    password: '',
    showPassword: false,
    submitted: false
  })
  const dispatch = useDispatch()
  const { loggingIn } = useSelector((state) => state.authentication)
  const handleCloseModalLogin = () => {
    dispatch(authActions.openModalLogin())
  }
  const handleChange = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value })
  }
  const handleChangeEmail = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value.toLowerCase() })
  }

  const handleChangeBox = (name) => (event) => {
    setForm({ ...form, [name]: event.target.checked })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setForm({ ...form, submitted: true })
    const { username, password } = form
    if (username && password) {
      dispatch(authActions.login(username, password))
    }
  }

  const responseFacebook = (response) => {
    if (response.accessToken) {
      //dispatch(authActions.facebookLogin(response.accessToken, false));
      dispatch(authActions.facebookLogin(response.accessToken, true))
    }
  }

  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera
    var retorno =
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    return retorno
  }
  return (
    <Container
      padding={1}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Avatar className={classes.avatar}>
        <LockTwoToneIcon color="primary" />
      </Avatar>
      <Typography component="h1" variant="h5">
        Entrar
      </Typography>
      <ValidatorForm onSubmit={handleSubmit}>
        <TextValidator
          variant="outlined"
          margin="normal"
          fullWidth
          label="E-mail *"
          value={form.username}
          onChange={handleChangeEmail('username')}
          id="email"
          name="email"
          autoComplete="email"
          validators={['required', 'isEmail']}
          errorMessages={['Você precisa informar o e-mail', 'E-mail inválido']}
        />
        <TextValidator
          variant="outlined"
          margin="normal"
          fullWidth
          value={form.password}
          onChange={handleChange('password')}
          name="password"
          label="Senha *"
          type={form.showPassword ? 'text' : 'password'}
          id="password"
          autoComplete="current-password"
          validators={['required']}
          errorMessages={['Opss! Você esqueceu da senha.']}
        />
        <Grid container justify="flex-start">
          <FormControlLabel
            control={
              <Checkbox
                icon={<VisibilityOffTwoToneIcon />}
                checkedIcon={<VisibilityTwoToneIcon />}
                onChange={handleChangeBox('showPassword')}
                checked={form.showPassword}
                color="primary"
              />
            }
            label="Mostrar senha"
          />
        </Grid>
        <Button
          fullWidth
          variant="contained"
          const
          color="primary"
          disabled={loggingIn}
          type="submit"
          className={classes.submit}
          startIcon={<LockOpenIcon />}
          endIcon={loggingIn && <CircularProgress size={24} />}
        >
          {loggingIn ? 'Validando informações... ' : 'Entrar'}
        </Button>
        {!isFacebookApp() ? (
          <FacebookLogin
            appId="648984027274250"
            isMobile={false}
            disableMobileRedirect={true}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={loggingIn}
                onClick={renderProps.onClick}
                className={classes.facebook}
                startIcon={<FacebookIcon />}
                endIcon={loggingIn && <CircularProgress size={24} />}
              >
                {loggingIn
                  ? 'Validando informações... '
                  : 'Entrar com o Facebook'}
              </Button>
            )}
          />
        ) : null}
        <Grid item>
          <Button
            onClick={() => {
              isLoginPath
                ? history.push('/cadastrar')
                : dispatch(authActions.setIsRegister())
            }}
            fullWidth
            variant="text"
            color="primary"
            variant="outlined"
          >
            <strong>Criar conta</strong>
          </Button>
        </Grid>
        <Box textAlign={'center'} mt={1}>
          <Link
            align="center"
            style={{
              textDecoration: 'none',
              alignSelf: 'center',
              textAlign: 'center'
            }}
            href="javascript:;"
            onClick={() => {
              !isLoginPath && handleCloseModalLogin()
              history.push('/request-password-reset')
            }}
            variant="body2"
            color="textPrimary"
          >
            {'Esqueceu a senha?'}
          </Link>
        </Box>
      </ValidatorForm>
    </Container>
  )
}

export default FormLogin
