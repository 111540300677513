import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducer from './_reducers'
//import { registerServiceWorker } from './serviceWorker'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//Define a store do redux (agrupa todos reducers da aplicacao, atravez do arquivo reducer from './_reducers';)
const store = applyMiddleware(thunk)(createStore)(reducer, devTools)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// sem cache
unregisterServiceWorker()
//com cache
//registerServiceWorker()
