const initialState = {
  loading: false,
  pedidos: [],
  id: '',
  dataHora: '',
  statusPedidoId: 0,
  cupomDescontoId: 0,
  formaPagamentoId: 0,
  transacao: '',
  valorTotal: '',
  cupomDesconto: {},
  formaPagamento: {}
}

export function pedido(state = initialState, action) {
  switch (action.type) {
    case 'PEDIDO_DETAIL':
      return {
        ...state,
        id: action.id,
        dataHora: action.dataHora,
        statusPedidoId: action.statusPedidoId,
        cupomDescontoId: action.cupomDescontoId,
        formaPagamentoId: action.formaPagamentoId,
        transacao: action.transacao,
        valorTotal: action.valorTotal,
        cupomDesconto: action.cupomDesconto,
        formaPagamento: action.formaPagamento
      }
    case 'PEDIDO_CLEAR':
      return initialState
    case 'PEDIDOS_BY_USUARIO_REQUEST':
      return {
        ...state,
        loading: true,
        id: action.usuarioId
      }
    case 'PEDIDOS_BY_USUARIO_SUCCESS':
      return {
        ...state,
        loading: false,
        pedidos: action.pedidos
      }
    case 'PEDIDOS_BY_USUARIO_FAILURE':
      return {
        ...state,
        loading: false,
        pedidos: [],
        error: action.error
      }
    default:
      return state
  }
}
