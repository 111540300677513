import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMutating } from 'react-query'
import { Typography, Button, Box, CircularProgress } from '@material-ui/core'

//? Own imports
import { validateUserNecessaryData } from 'utils'
import { Boleto as BoletoIcon } from 'icons'

type BoletoProps = {
  handleSubmit: () => void
}
const Boleto = ({ handleSubmit }: BoletoProps) => {
  const dispatch = useDispatch()
  //@ts-ignore
  const { user } = useSelector((state) => state.authentication)
  const totalMutationsCreateMember = useIsMutating({
    mutationKey: ['mutationCreateMember']
  })

  const isMutating = totalMutationsCreateMember > 0

  const handleClickSubmit = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      handleSubmit()
    }
  }

  return (
    <div>
      {<BoletoIcon style={{ fontSize: 65 }} />}
      <Typography variant={'h5'} gutterBottom>
        <strong>{'Guia de pagamento por boleto'}</strong>
      </Typography>

      <Box paddingTop={4}>
        <Typography variant="body2" align="left" gutterBottom>
          Clique no botão abaixo para finalizar a associação e gerar o boleto de
          cobrança.
        </Typography>
        <Box paddingTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickSubmit}
            disabled={isMutating}
          >
            {isMutating ? 'Finalizando associação...' : 'Pagar com boleto'}
            {isMutating && (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: 5 }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Boleto
