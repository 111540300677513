import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  FormControlLabel,
  Checkbox,
  Paper,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  makeStyles,
  Tooltip,
  InputAdornment
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '../../_helpers'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {
  usuarioActions,
  authActions,
  estadoAction,
  cidadeAction
} from '../../_actions'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Footer from '../../components/Footer'
import { Google } from '../../icons'
import FacebookIcon from '@material-ui/icons/Facebook'
import { cnpj, cpf } from 'cpf-cnpj-validator'
import { useDidMountEffect } from 'components/Utils/useDidMountEffect'
import { cpfCnpjMask, phoneMask, cepMask, toISODateFormat } from 'utils'
import YoutubeSearchedForRoundedIcon from '@material-ui/icons/YoutubeSearchedForRounded'
import { parse } from 'date-fns'

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3)
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(1, 0, 2)
  },
  facebook: {
    textTransform: 'none',
    backgroundColor: '#3b5998',
    '&:hover': {
      backgroundColor: '#3b5998',
      opacity: 0.8
    }
  },
  google: {
    backgroundColor: '#fff',
    textTransform: 'none',
    color: theme.palette.primary.dark,
    '&:hover': {
      color: 'white'
    }
  },
  backButton: {
    marginTop: theme.spacing(2)
  },
  ou: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  paddingBottomDesktop: {
    paddingTop: theme.spacing(2)
  }
}))

const CadastroForm = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const estados = useSelector((state) => state.estado.estado)
  const cidades = useSelector((state) => state.cidade.cidade)
  const usuario = useSelector((state) => state.usuario)
  const [repeatPassword, setRepeatPassword] = useState('')

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== usuario.senha) {
        return false
      }
      return true
    })

    ValidatorForm.addValidationRule('isValidCpf', (value) => {
      if (value && value.length <= 14) {
        if (cpf.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })

    ValidatorForm.addValidationRule('isValidCnpj', (value) => {
      if (value && value.length >= 15) {
        if (cnpj.isValid(value)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    })
    ValidatorForm.addValidationRule('isValidCep', (value) => {
      if (value.length === 9) {
        return true
      } else {
        return false
      }
    })
    ValidatorForm.addValidationRule('isValidPhone', (value) => {
      if (value != null) {
        if (value.length > 0 && value.length < 14) {
          return false
        }
        return true
      }
      return true
    })
    if (estados.length === 0) {
      dispatch(estadoAction.getEstado())
    }
    return () => {
      ValidatorForm.removeValidationRule('isValidCpf')
      ValidatorForm.removeValidationRule('isValidPhone')
      ValidatorForm.removeValidationRule('isValidCep')
      ValidatorForm.removeValidationRule('isValidCnpj')
      ValidatorForm.removeValidationRule('isPasswordMatch')
    }
  }, [dispatch, estados.length, usuario.senha])

  useDidMountEffect(() => {
    if (usuario.cidade.estadoId > 0) {
      dispatch(cidadeAction.getCidadeByUf(usuario.cidade.estadoId))
    }
  }, [usuario.cidade.estadoId])

  const handleChange = (prop) => (event) => {
    dispatch(usuarioActions.onChangeProps(prop, event))
  }

  const handleChangeData = (newValue) => {
    dispatch(usuarioActions.onChangeData(newValue))
  }

  const handleChangeEstado = (event) => {
    dispatch(usuarioActions.onChangeEstado(event))
  }

  const handleChangeRepeatPassword = (event) => {
    setRepeatPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let payload = {
      aceiteEmails: usuario.aceiteEmails,
      aceiteTermosUso: true,
      bairro: usuario.bairro,
      cep: usuario.cep.replace(/-/g, ''),
      imagem: usuario.imagem,
      nome: usuario.nome,
      email: usuario.email,
      numero: usuario.numero,
      complemento: usuario.complemento,
      senha: usuario.senha,
      genero: usuario.genero,
      cidadeId: usuario.cidadeId,
      dataNascimento:
        usuario.dataNascimento !== null
          ? parse(
              usuario.dataNascimento,
              'dd/MM/yyyy',
              new Date()
            ).toISOString()
          : null,
      endereco: usuario.endereco,
      apelido: usuario.apelido,
      username: usuario.username,
      cpfCnpj: usuario.cpfCnpj.split('.').join('').replace(/[-/.]/g, ''),
      telefone: usuario.telefone
        .split(' ')
        .join('')
        .replace(/[().-]/g, '')
    }
    dispatch(usuarioActions.createUsuario(payload))
  }

  const responseFacebook = (response) => {
    if (response.accessToken) {
      dispatch(authActions.facebookLogin(response.accessToken, true))
    }
  }

  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera
    var retorno =
      ua.indexOf('FBAN') > -1 ||
      ua.indexOf('FBAV') > -1 ||
      ua.indexOf('Instagram') > -1
    return retorno
  }

  return (
    <>
      <Container className={classes.paper} component={Paper} maxWidth="sm">
        <Grid container justify="flex-start">
          <IconButton
            className={classes.backButton}
            onClick={() => history.goBack()}
            aria-label="Voltar"
            color="primary"
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Grid>
        <Avatar
          alt="avatar_image"
          src={require('../../img/corujas-black-without-text.svg')}
          imgProps={{ style: { width: 'auto', height: '75%' } }}
          className={classes.avatar}
        />
        <Typography gutterBottom component="h2" variant="h6">
          Venha fazer parte do Corujas! Informe seus dados abaixo para se
          cadastrar.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {!isFacebookApp() ? (
              <FacebookLogin
                appId="648984027274250"
                isMobile={false}
                disableMobileRedirect={true}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                render={(renderProps) => (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={usuario.loading}
                    onClick={renderProps.onClick}
                    className={classes.facebook}
                    startIcon={<FacebookIcon />}
                  >
                    {usuario.loading ? (
                      'Validando informações...'
                    ) : (
                      <>
                        Continuar com
                        <strong>&nbsp;Facebook</strong>
                      </>
                    )}
                  </Button>
                )}
              />
            ) : null}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={usuario.loading}
                            // onClick={renderProps.onClick}
                            className={classes.google}
                            startIcon={<Google />}
                        >
                            {usuario.loading ?
                                'Validando informações...'
                                :
                                <>
                                    Continuar com
                                    <strong>&nbsp;Google</strong>
                                </>

                            }
                        </Button>
                    </Grid> */}
        </Grid>

        <Typography className={classes.ou} gutterBottom variant="h5">
          ou
        </Typography>

        <ValidatorForm
          className={classes.form}
          debounceTime={400}
          onSubmit={handleSubmit}
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextValidator
                autoComplete="nome"
                name="nome"
                variant="outlined"
                fullWidth
                value={usuario.nome}
                onChange={handleChange('nome')}
                id="nome"
                label="Nome completo *"
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="cpfCnpj"
                label="CPF/CNPJ *"
                name="cpfCnpj"
                type="text"
                inputProps={{
                  maxLength: '18'
                }}
                value={cpfCnpjMask(usuario.cpfCnpj)}
                onChange={handleChange('cpfCnpj')}
                autoComplete="cpf"
                validators={['required', 'isValidCpf', 'isValidCnpj']}
                errorMessages={[
                  'Campo obrigatório!',
                  'CPF inválido!',
                  'CNPJ inválido!'
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                autoComplete="apelido"
                name="apelido"
                variant="outlined"
                fullWidth
                value={usuario.apelido}
                onChange={handleChange('apelido')}
                id="apelido"
                label="Apelido"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                id="username"
                label="Nome de usuário *"
                value={usuario.username}
                onChange={handleChange('username')}
                fullWidth
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                InputProps={{
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Tooltip title="Gerar apelido">
                  //       <IconButton
                  //         aria-label="gerar apelido automaticamente"
                  //         onClick={() => generateUsername()}
                  //       >
                  //         <YoutubeSearchedForRoundedIcon />
                  //       </IconButton>
                  //     </Tooltip>
                  //   </InputAdornment>
                  // ),
                  startAdornment: <Typography>@</Typography>
                }}
                // onFocus={() =>
                //   (usuario.username === '' ||
                //     usuario.username === null) &&
                //   generateUsername()
                // }
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                type="email"
                id="email"
                label="Endereço de e-mail *"
                name="email"
                value={usuario.email}
                onChange={handleChange('email')}
                validators={['required', 'isEmail']}
                errorMessages={[
                  'Você precisa informar o e-mail',
                  'E-mail inválido'
                ]}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="senha"
                label="Senha *"
                type="password"
                value={usuario.senha}
                onChange={handleChange('senha')}
                id="senha"
                validators={['required']}
                errorMessages={['Opss! Você esqueceu da senha.']}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextValidator
                variant="outlined"
                fullWidth
                name="repeatPassword"
                label="Repita a senha *"
                type="password"
                value={repeatPassword}
                onChange={handleChangeRepeatPassword}
                id="repeatPassword"
                validators={['required', 'isPasswordMatch']}
                errorMessages={[
                  'Opss! Você esqueceu da senha.',
                  'As senhas não são iguais!'
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <KeyboardDatePicker
                autoOk
                fullWidth
                disableFuture
                inputVariant="outlined"
                invalidDateMessage="Data inválida"
                invalidLabel="dd/mm/aaaa"
                maxDateMessage="A data não deve ser posterior à data máxima"
                minDateMessage="A data não deve ser anterior à data mínima"
                cancelLabel="Cancelar"
                okLabel="Definir"
                clearable
                clearLabel="Limpar"
                placeholder="dd/mm/aaaa"
                todayLabel="Hoje"
                label="Data de Nascimento"
                format="dd/MM/yyyy"
                openTo="year"
                KeyboardButtonProps={{ size: 'small' }}
                views={['year', 'month', 'date']}
                inputValue={usuario.dataNascimento}
                value={usuario.dataNascimento}
                onChange={(_, newValue) => handleChangeData(newValue)}
                InputAdornmentProps={{ position: 'start' }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="telefone"
                label="Telefone *"
                name="telefone"
                value={phoneMask(usuario.telefone)}
                onChange={handleChange('telefone')}
                autoComplete="telefone"
                validators={['required', 'isValidPhone']}
                errorMessages={['Campo obrigatório!', 'Telefone inválido!']}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="sexo-label">Sexo</InputLabel>
                <Select
                  labelId="sexo-label"
                  variant="outlined"
                  id="Sexo"
                  label="Sexo"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={usuario.genero}
                  onChange={handleChange('genero')}
                  fullWidth
                >
                  <MenuItem disabled value="">
                    <em>Sexo</em>
                  </MenuItem>
                  <MenuItem value="M">Masculino</MenuItem>
                  <MenuItem value="F">Feminino</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={12}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="cep"
                label="CEP *"
                name="cep"
                type="text"
                inputProps={{
                  maxlength: '9',
                  minlength: '9'
                }}
                value={cepMask(usuario.cep)}
                onChange={handleChange('cep')}
                autoComplete="cep"
                validators={['required', 'isValidCep']}
                errorMessages={['Campo obrigatório!', 'O CEP não é válido']}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <SelectValidator
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                variant="outlined"
                id="estadoId"
                label="Estado *"
                value={usuario.cidade.estadoId}
                onChange={handleChangeEstado}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                displayEmpty
                labelId="estadoId"
              >
                <MenuItem disabled value="">
                  <em>Estado *</em>
                </MenuItem>
                {undefined !== estados && estados.length
                  ? estados.map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.nome}
                      </MenuItem>
                    ))
                  : null}
              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={7}>
              <SelectValidator
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                disabled={!usuario.cidade.estadoId}
                variant="outlined"
                id="cidadeId"
                label="Cidade *"
                value={usuario.cidadeId}
                onChange={handleChange('cidadeId')}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
                displayEmpty
                labelId="cidadeId"
              >
                <MenuItem disabled value="">
                  <em>Cidade *</em>
                </MenuItem>
                {cidades.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nome}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="endereco"
                label="Endereço *"
                name="endereco"
                value={usuario.endereco}
                onChange={handleChange('endereco')}
                autoComplete="endereco"
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="numero"
                label="Numero *"
                name="numero"
                inputProps={{
                  maxlength: '10',
                  minlength: '1'
                }}
                value={usuario.numero}
                onChange={handleChange('numero')}
                autoComplete="numero"
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="bairro"
                label="Bairro *"
                name="bairro"
                value={usuario.bairro}
                onChange={handleChange('bairro')}
                autoComplete="bairro"
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextValidator
                variant="outlined"
                fullWidth
                id="complemento"
                label="Complemento"
                name="complemento"
                value={usuario.complemento}
                onChange={handleChange('complemento')}
                autoComplete="complemento"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="initial">
                Ao me cadastrar, concordo com os
                <Link
                  color="secondary"
                  variant="body2"
                  underline="always"
                  href="https://corujastermos.s3.us-east-2.amazonaws.com/termos_uso.pdf"
                  target="_blank"
                  rel="noopener"
                >
                  &nbsp;termos de uso&nbsp;
                </Link>
                e{' '}
                <Link
                  color="secondary"
                  href="https://corujastermos.s3.us-east-2.amazonaws.com/politica_privacidade.pdf"
                  underline="always"
                  target="_blank"
                  rel="noopener"
                  variant="body2"
                >
                  políticas de privacidade.
                </Link>
              </Typography>
              <FormControlLabel
                onChange={handleChange('aceiteEmails')}
                control={
                  <Checkbox checked={usuario.aceiteEmails} color="primary" />
                }
                label={
                  <Typography variant="body2">
                    Quero receber diversão, promoções de marketing e
                    atualizações por e-mail!
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={usuario.loading}
                className={classes.submit}
              >
                {(usuario.loading && 'Enviando informações...') ||
                  (!usuario.loading && 'Cadastrar')}
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                href="javascript:;"
                onClick={() => history.push('/login')}
                variant="body2"
              >
                Já tem uma conta? 🤔 Entre já!
              </Link>
            </Grid>
          </Grid>
        </ValidatorForm>
        <Box mt={5}>
          <Footer />
        </Box>
      </Container>
      <div className={classes.paddingBottomDesktop}></div>
    </>
  )
}

export default CadastroForm
