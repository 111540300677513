import React from 'react'

import { Box, Container, Grid, Typography, makeStyles } from '@material-ui/core'
import { AssociationPlanItem, AssociationPlanListSkeleton } from './components'
import { useEstablishmentAssociationPlansList } from 'hooks-querys'
import { useParams } from 'react-router'
import { Page } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(1) },
    textAlign: 'initial',
    minHeight: '100vh'
  },
  container: {
    padding: theme.spacing(2)
  },
  subtitle: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold'
  }
}))

const EstablishmentAssociationPlans = () => {
  const classes = useStyles()
  const { establishmentId } = useParams<{ establishmentId: string }>()
  const { data, isLoading } = useEstablishmentAssociationPlansList({
    establishmentId: Number(establishmentId)
  })
  return (
    //@ts-ignore
    <Page className={classes.root} title="Associações disponíveis">
      <Container maxWidth="lg" className={classes.container}>
        <Typography
          align="left"
          className={classes.subtitle}
          gutterBottom
          variant="h5"
        >
          <b>Selecione uma opção de plano</b>
        </Typography>
        {isLoading ? (
          <Box mt={3}>
            <AssociationPlanListSkeleton />
          </Box>
        ) : (
          <Box mt={3}>
            <Grid container spacing={2}>
              {data?.length && data?.length > 0 ? (
                data?.map((item) => (
                  <Grid key={item.id} item xs={12} md={4}>
                    <AssociationPlanItem item={item} />
                  </Grid>
                ))
              ) : (
                <Typography align="left" gutterBottom variant="h5">
                  Não há planos disponíveis
                </Typography>
              )}
            </Grid>
          </Box>
        )}
      </Container>
    </Page>
  )
}
export default EstablishmentAssociationPlans
