import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  Fade,
  Button,
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import QRCode from 'qrcode.react'
import { format } from 'date-fns'
import brLocale from 'date-fns/locale/pt-BR'
import {
  useCarrinhoItensEvento,
  useDiscountCoupon,
  useMutationCreateOrder,
  useOrder
} from 'hooks-querys'
import { calculaValorAPagar } from 'utils/purchaseCalculate'
import { useRouter, validateUserNecessaryData } from 'utils'
import FileCopy from '@material-ui/icons/FileCopy'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { extractRespostasCamposInscricao } from 'views/OrderPayment/components/PurchaseApplicationForm/purchaseApplicationFormUtils'
import {
  extractCartItems,
  filterItemsWithCheckIn,
  filterItemsWithoutCheckIn,
  filterValidCartItems
} from 'utils/cartItemsFilters'

const useStyles = makeStyles((theme) => ({
  containerQRCode: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  logoPix: {
    marginBottom: theme.spacing(2)
  }
}))

const Pix = ({ eventoId, isCheckIn }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.authentication)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const { data: discountCoupon } = useDiscountCoupon('', undefined, false)
  const router = useRouter()
  const purchaseApplicationFormItens = useSelector(
    (state) =>
      // @ts-ignore
      state.purchaseApplicationForm?.purchaseApplicationFormItens
  )
  const pedidoId = router.match.params.pedidoId
  const {
    data,
    isError,
    isLoading: isLoadingCarrinhoItens
  } = useCarrinhoItensEvento(eventoId)
  const filteredCartItems = filterValidCartItems(data)
  const carrinhoItensWithoutCheckIn = filterItemsWithoutCheckIn(
    filteredCartItems || []
  )
  const carrinhoItensWithCheckIn = filterItemsWithCheckIn(
    filteredCartItems || []
  )
  const carrinhoItens = isCheckIn
    ? carrinhoItensWithCheckIn
    : carrinhoItensWithoutCheckIn

  const totalValue = calculaValorAPagar(
    carrinhoItens || [],
    (discountCoupon && discountCoupon.carrinhoItens) || [],
    discountCoupon && discountCoupon.cupomDesconto
  )
  const mutationCreateOrder = useMutationCreateOrder(
    {
      textoCupom:
        discountCoupon && discountCoupon?.cupomDesconto
          ? discountCoupon?.cupomDesconto.codigo
          : '',
      carrinhoItens: extractCartItems(carrinhoItens),
      formaPagamentoId: 3,
      respostasCamposInscricao: extractRespostasCamposInscricao(
        purchaseApplicationFormItens
      )
    },
    totalValue
  )
  const {
    data: order,
    isLoading,
    isFetching,
    refetch
  } = useOrder(pedidoId && Number(pedidoId), undefined)

  const getPixCode = () => {
    if (order && order?.pixBase64) {
      const base64regex =
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
      if (base64regex.test(order?.pixBase64)) {
        return Buffer.from(order?.pixBase64, 'base64').toString('utf-8')
      } else {
        return order?.pixBase64
      }
    } else {
      return ''
    }
  }
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(getPixCode())
    toast.success('Feito! O código do PIX foi copiado.')
  }
  const handleClickCreateOrder = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      mutationCreateOrder.mutate()
    }
  }
  return (
    <div>
      {!pedidoId && (
        <img
          width={'100px'}
          src={require('img/pix-banco-central-logo.svg')}
          alt="logo-pix"
          className={classes.logoPix}
        />
      )}
      <Typography variant={'h5'} gutterBottom>
        <strong>
          {pedidoId && order
            ? 'Agora é só pagar com PIX '
            : 'Guia de pagamento via PIX'}
        </strong>
      </Typography>
      <Typography gutterBottom={isMobile} variant={'body2'}>
        Pagar com PIX é prático e rápido. O pagamento é instantâneo e confirmado
        em poucos segundos. Funciona assim:
      </Typography>
      {pedidoId ? (
        <>
          {isMobile ? (
            <>
              <Typography color="textSecondary" variant="body2" align="left">
                <strong> 1 -</strong> Copie o código de pagamento no botão
                abaixo:
              </Typography>
              {order && order?.pixBase64 && (
                <Box marginY={2}>
                  <TextField
                    variant="outlined"
                    size="small"
                    contentEditable={false}
                    value={getPixCode()}
                    color="primary"
                    fullWidth
                    style={{ marginBottom: 5 }}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: '#f5f5f5'
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleCopyToClipboard}
                            size="small"
                            edge="end"
                          >
                            <FileCopy fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="center"
                    gutterBottom
                  >
                    Código valido até{' '}
                    {format(
                      new Date(order.dataHoraValidade),
                      `dd'/'MM'/'yyyy 'às' kk':'mm`,
                      {
                        locale: brLocale
                      }
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopyToClipboard}
                    size="small"
                    fullWidth
                  >
                    Copiar código
                  </Button>
                </Box>
              )}
              <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom
                align="left"
              >
                <strong>2 -</strong> Abra o app ou banco de sua preferência.
                Escolha a opção pagar com código PIX
                <strong> copia e cola</strong>
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom
                align="left"
              >
                <strong>3 -</strong> Insira o código com a função colar, confira
                todas as informações e autorize o pagamento
              </Typography>
            </>
          ) : (
            <Grid
              className={classes.containerQRCode}
              container
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  alignContent="center"
                  wrap="nowrap"
                >
                  <Grid item xs={12}>
                    <Fade in={true}>
                      <QRCode
                        value={getPixCode()}
                        size={'75%'}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        level={'H'}
                        includeMargin={false}
                        renderAs={'svg'}
                      />
                    </Fade>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      style={{ fontSize: '10px' }}
                      variant="caption"
                      color="textPrimary"
                    >
                      O código é valido até{' '}
                      {format(
                        new Date(order.dataHoraValidade),
                        `dd'/'MM'/'yyyy 'às' kk':'mm`,
                        {
                          locale: brLocale
                        }
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  align="left"
                  color="textSecondary"
                  variant="body2"
                  gutterBottom
                >
                  <strong> 1 -</strong> Abra o app ou banco de sua preferência e
                  entre no ambiente PIX
                </Typography>
                <Typography
                  align="left"
                  color="textSecondary"
                  variant="body2"
                  gutterBottom
                >
                  <strong> 2 -</strong> Escolha a opção
                  <strong> pagar com qr code</strong> e escaneie o código ao
                  lado
                </Typography>
                <Typography
                  align="left"
                  color="textSecondary"
                  variant="body2"
                  gutterBottom
                >
                  <strong> 3 -</strong> Confira todas as informações e autorize
                  o pagamento
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  gutterBottom
                  align="left"
                >
                  <strong> 4 -</strong> Logo em seguida, você vai receber a
                  confirmação do pagamento no seu e-mail e através de nossos
                  canais.
                </Typography>
                <Typography gutterBottom align="left">
                  E pronto! 😎
                </Typography>
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <>
              <Typography
                color="textSecondary"
                variant="body2"
                gutterBottom
                align="left"
              >
                <strong> 4 -</strong> Logo em seguida, você vai receber a
                confirmação do pagamento no seu e-mail e através de nossos
                canais.
              </Typography>
              <Typography gutterBottom align="left">
                E pronto! 😎
              </Typography>
            </>
          )}
          <Typography variant="body2" align="left">
            Os dados do recebedor são:
          </Typography>
          <Typography variant="body2" align="left">
            Q5S TECNOLOGIA LTDA
          </Typography>
          <Typography variant="body2" align="left" gutterBottom>
            CNPJ: 29.228.835/0001-85
          </Typography>
          <Typography align="left" variant="caption" color="textPrimary">
            Ahh, se o pagamento não for realizado dentro do prazo, não se
            preocupe. O pedido será cancelado automaticamente.
          </Typography>
        </>
      ) : (
        <Box paddingTop={4}>
          <Typography variant="body2" align="left" gutterBottom>
            Clique no botão abaixo para finalizar o pedido e gerar o código de
            pagamento
          </Typography>
          <Box paddingTop={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickCreateOrder}
              disabled={mutationCreateOrder.isLoading}
            >
              {mutationCreateOrder.isLoading
                ? 'Finalizando pedido...'
                : 'Pagar com PIX'}
              {mutationCreateOrder.isLoading && (
                <CircularProgress
                  color="inherit"
                  size={20}
                  style={{ marginLeft: 5 }}
                />
              )}
            </Button>
          </Box>
        </Box>
      )}
    </div>
  )
}

export default Pix
