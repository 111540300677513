import React from 'react'
import {
  AppBar,
  Toolbar,
  Hidden,
  useTheme,
  useMediaQuery,
  makeStyles,
  Button
} from '@material-ui/core'
import { history } from '../../../../_helpers'
import { InputSearchTopBar } from './inputSearchTopBar'
import { Link as RouterLink } from 'react-router-dom'
import { authActions } from '_actions'
import { useDispatch } from 'react-redux'
import { ButtonGroupPortalOrganizador } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {},
  flexGrow: {
    flexGrow: 1
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3)
    }
  },
  buttonOrganizeSeuEvento: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3)
    },
    marginRight: theme.spacing(1)
  }
}))

export default function TopBarNotLoggedIn() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          <RouterLink to="/">
            <img
              onClick={() => history.push('/')}
              width={isMobile ? '20px' : '140px'}
              src={
                isMobile
                  ? require('../../../../img/img-login-sem-texto.svg')
                  : require('../../../../img/img-login.svg')
              }
              alt="Corujas App"
            />
          </RouterLink>
          <div className={classes.flexGrow} />
          <Hidden smDown>
            <InputSearchTopBar />
          </Hidden>
          <div className={classes.buttonOrganizeSeuEvento}>
            <ButtonGroupPortalOrganizador />
          </div>
          {/* <Button
            size={isMobile ? 'small' : 'medium'}
            className={classes.buttonOrganizeSeuEvento}
            variant="outlined"
            href="https://sejaparceiro.corujasapp.com/"
            target="_blank"
            rel="noreferrer"
            color="inherit"
          >
            ORGANIZE SEU EVENTO
          </Button> */}

          <Button
            className={classes.button}
            onClick={() => dispatch(authActions.openModalLogin())}
            color="inherit"
          >
            ENTRAR
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}
