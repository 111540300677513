import React from 'react'
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import InputSearch from './inputSearchDown'
import CategoriasChips from './categoriasChips'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  rootSearch: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      padding: theme.spacing(0.5)
    },
    [theme.breakpoints.down('sm')]: {
      width: 300
    }
  }
}))

const SearchInput = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        {/* <Grid item xs={12} sm={12}>
            <Typography variant="h2" color="initial">Hoje eu vou...</Typography>
        </Grid> */}
        <Grid className={classes.rootSearch} item xs={12} sm={12}>
          <InputSearch />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CategoriasChips />
        </Grid>
      </Grid>
    </div>
  )
}
export default SearchInput
