import { useQuery } from 'react-query'
import { crudService } from '_services'

const getUserOrders = async (userId) => {
  const { data } = await crudService.get(`pedidos/usuario/${userId}`)
  return data
}

export default function useUserOrders(userId) {
  return useQuery(['orders', userId], () => getUserOrders(userId), {
    enabled: !!userId
  })
}
