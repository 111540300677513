import React from 'react'

import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core'
import { Header, MemberListSkeleton, Page } from 'components'
import { useMemberList } from 'hooks-querys'
import { useSelector } from 'react-redux'
import { MemberListItem } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
    textAlign: 'initial'
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  loadingContainer: {
    marginTop: theme.spacing(3)
  }
}))

const MemberList = () => {
  const classes = useStyles()
  //@ts-ignore
  const userId = useSelector((state) => state.authentication.user.id)
  const {
    data: memberList,
    isLoading,
    isFetching,
    refetch
  } = useMemberList({ userId: userId })
  return (
    <>
      {isFetching && (
        <LinearProgress
          style={{
            width: '100%',
            position: 'absolute'
          }}
        />
      )}
      {/* @ts-ignore */}
      <Page className={classes.root} title="Área de Membros">
        <Header
          title="Minhas Associações"
          subtitle="Área de membros"
          isRefreshing={isFetching}
          onRefresh={refetch}
        />
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <MemberListSkeleton />
          </div>
        ) : (
          <div className={classes.content}>
            <Grid container spacing={2} alignItems="stretch">
              {memberList &&
                memberList?.length > 0 &&
                memberList.map((item) => (
                  <Grid item key={item?.id} xs={12} sm={6} md={4}>
                    <MemberListItem
                      showButtonActions={true}
                      item={item}
                      key={item?.id}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
      </Page>
    </>
  )
}
export default MemberList
