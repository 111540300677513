import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) => ({
  textHeader: {
    '&::after': {
      width: '1rem'
    },
    padding: theme.spacing(0.5),
    borderLeft: `0.3rem solid ${theme.palette.primary.main}`,
    textAlign: 'left',
    userSelect: 'none',
    fontWeight: 'bold'
  },
  avatar: {
    width: theme.spacing(11),
    height: theme.spacing(11),
    marginBottom: theme.spacing(2)
  },
  iconWithText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4)
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))
