import React from 'react'
import { useParams } from 'react-router'

//? Own components
import { useMembership } from 'hooks-querys'
import { Box, Grid } from '@material-ui/core'
import { MemberListItem } from 'views/MemberList/components'
import { MemberListSkeleton } from 'components'

const MembershipGeneral = () => {
  const { membershipId, tab } =
    useParams<{ tab: 'general' | 'financial'; membershipId: string }>()

  const { data, isLoading } = useMembership(
    {
      membershipId: Number(membershipId)
    },
    {
      config: {
        enabled: tab === 'general'
      }
    }
  )
  if (isLoading) {
    return (
      <Box mt={3}>
        <MemberListSkeleton />
      </Box>
    )
  }
  if (data) {
    return (
      <Box mt={3}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <MemberListItem
              item={data}
              showButtonActions={false}
              showQRCodeButton
            />
          </Grid>
        </Grid>
      </Box>
    )
  }
  return null
}

export default MembershipGeneral
