import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIsMutating } from 'react-query'
import {
  makeStyles,
  Typography,
  useMediaQuery,
  Button,
  Box,
  CircularProgress,
  Theme
} from '@material-ui/core'

//? Own imports
import { validateUserNecessaryData } from 'utils'

const useStyles = makeStyles((theme) => ({
  containerQRCode: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  logoPix: {
    marginBottom: theme.spacing(2)
  }
}))

type PixProps = {
  handleSubmit: () => void
}
const Pix = ({ handleSubmit }: PixProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  //@ts-ignore
  const { user } = useSelector((state) => state.authentication)
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const totalMutationsCreateMember = useIsMutating({
    mutationKey: ['mutationCreateMember']
  })

  const isMutating = totalMutationsCreateMember > 0

  const handleClickCreateOrder = async () => {
    if (validateUserNecessaryData(dispatch, user)) {
      handleSubmit()
    }
  }
  return (
    <div>
      <img
        width={'100px'}
        src={require('img/pix-banco-central-logo.svg')}
        alt="logo-pix"
        className={classes.logoPix}
      />
      <Typography variant={'h5'} gutterBottom>
        <strong>Guia de pagamento via PIX</strong>
      </Typography>
      <Typography gutterBottom={isMobile} variant={'body2'}>
        Pagar com PIX é prático e rápido. O pagamento é instantâneo e confirmado
        em poucos segundos. Funciona assim:
      </Typography>
      <Box paddingTop={4}>
        <Typography variant="body2" align="left" gutterBottom>
          Clique no botão abaixo para finalizar a associação e gerar o código de
          pagamento.
        </Typography>
        <Box paddingTop={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCreateOrder}
            disabled={isMutating}
          >
            {isMutating ? 'Finalizando associação...' : 'Pagar com PIX'}
            {isMutating && (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginLeft: 5 }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </div>
  )
}

export default Pix
