import { toast } from 'react-toastify'

export default function paymentFeedback(status) {
  var message = ''

  switch (status) {
    case 'approved':
      message = toast.success(
        'Feito! Compra finalizada! Logo logo você vai receber os detalhes por e-mail 😉',
        { position: 'top-center', autoClose: 7000 }
      )
      break
    case 'in_process':
      message = toast(
        'Estamos processando seu pagamento... Fique tranquilo, vamos te avisar quando for concluído 😉 ',
        { position: 'top-center', autoClose: 7000 }
      )
      break
    case 'rejected':
      message = toast.error(
        'Opss! Houve algum erro ao realizar o pagamento! 😥',
        { position: 'top-center', autoClose: 7000 }
      )
      break
    case null:
      return
    default:
      break
  }
  return message
}
